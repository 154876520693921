/* global appConfig */
import React from 'react';
import { connect } from 'react-redux';

import { userActions, alertActions } from '@actions/appuser';
//import { Button } from '@common';
import { Button } from '@common/appuser';
import { AuthCode } from './LoginAuthCode'
import { ForgotPage } from '../registerpage/ForgotPageApp';
import { RegisterPage } from '../registerpage/RegisterPageApp';

import { FaTimes as ExitIcon, FaExternalLinkAlt as LinkIcon } from 'react-icons/fa';

import { getVersion } from '@lib/url';
import { checkForUpdate, getSupportURL, detectIE, setCookie, DeviceFingerprint } from '@lib/simpletools';

import { FormattedMessage, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux'
import globalTranslations from '../../translation/global.js';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

import logo from '@image/athena/AthenaBoardLogo_small_white.png';
import './loginapp.css';

import { keysStorage, SettingStorage } from '@lib/indexeddb';

import { commonErrorMessages } from '@constants';
import { DateFormat } from '@constants/common.constants';
import { LoadingOverlay } from '@common/MUI';
import { popoverAction } from '../../actions/admin';
import { Stack } from '@mui/material';
import { MuiButton } from '../common/MUI';
import WhiteTextField from '../common/MUI/WhiteTextField.js';

export class LoginAppClass extends React.Component {
  constructor(props) {
    super(props);
    // reset login status
    var borwserSupport = true;
    if (navigator.userAgent.indexOf('MSIE') !== -1
      || navigator.appVersion.indexOf('Trident/') > -1) {
      borwserSupport = false;
    }

    this.state = {
      deviceHash: "",//"abd39432bba362ced69c38c15f47d937d70ea1da82d573cc67a1227231621a02",
      deviceId: "",//"6ab33eb2-ffc1-47df-8ecb-ebd5f579535c",
      athenname: '',
      phash: '',
      phashconfirm: '',
      phashfocus: false,
      submitted: false,
      forgot: false,
      //browserPrivateMode: false,
      showPrivateModeMsg: false,
      lastRateUpdate: null,
      borwserSupport: borwserSupport,
      ieBrowser: false,
      update: false,
      updateCheck: new Date(),
      editUsername: true,
      showAuth0: false,
      iframeLoaded: false,
      showFrameExit: true,
      hasDevice: false,

      disableLogin: true,

      currentPDF: null,
      maintenance: null,

      checkInitial: false,
    };

    var iFrameComms = {
      completeAuth0: function (auth) {
        if (auth.user !== undefined && auth.user['https://platform.ansarada.com/claims/identity/profile/id'] !== undefined) {
          this.props.dispatch(userActions.Auth0Complete(auth))
          this.setState({ showAuth0: false })
        }
        this.setState({ showFrameExit: false })
      },
      getUsername: function () {
        return this.state.athenname
      }
    };

    window.iFrameComms = Object.create(iFrameComms);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateRate = this.updateRate.bind(this);
    this.startRate = this.startRate.bind(this);
    this.checkUpdate = this.checkUpdate.bind(this);
    iFrameComms.completeAuth0 = iFrameComms.completeAuth0.bind(this);
    iFrameComms.getUsername = iFrameComms.getUsername.bind(this);
    this.dropzoneRef = null;
    this.initialTimeout = null;
    this.refSecPass = null;
  }

  componentDidMount() {
    this.initialTimeout = setTimeout(this.initialRun.bind(this), 200); //wait 200ms for DB to initialise
  }

  componentWillUnmount() {
    clearTimeout(this.initialTimeout);
    if (this.state.lastRateUpdate !== null)
      clearInterval(this.state.lastRateUpdate);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.userChecked === true) {
      if((nextProps.mode === 2 || nextProps.mode === 5) && nextProps.universalLogin && nextProps.universalRedirect){

      }else{
        state.editUsername = false
      }
    }
    if (nextProps.mode !== undefined && nextProps.mode === 2 && nextProps.universalLogin && !state.showAuth0 && !nextProps.universalRedirect) { //auth0Result
      // state.showAuth0 = true
      // state.showFrameExit = true
    }

    if (nextProps.error) {
      state.editUsername = true;
      state.phash = ""
      state.phashconfirm = ""
      state.submitted = false
    }

    if (nextProps.enabled === false) {
      state.maintenance = nextProps.maintenance
      state.phash = ""
      state.phashconfirm = ""
      state.submitted = false
    }

    if(nextProps.hasDevice === true && state.athenname === nextProps.alias){
      state.hasDevice = true
    }else if(nextProps.mode !== undefined && (nextProps.mode === 2 || nextProps.mode === 5) && nextProps.hasDevice === false && nextProps.requiresPassword === false)
      state.hasDevice = true

    return state
  }

  initialRun() {
    DeviceFingerprint('login')
      .then((hash) => {
        //this.setState({ devicehash :"abd39432bba362ced69c38c15f47d937d70ea1da82d573cc67a1227231621a02"})
        this.setState({ deviceHash: hash });
      })
      .catch(function (error) {
      });

    // detectPrivateMode((is_private) => {
    //   this.setState({browserPrivateMode: is_private});
    // });

    const checkIndex = (data) => {
      if (data.key !== undefined && data.key.alias !== undefined && data.key.alias !== "") {
        //this.setState({showPrivateModeMsg: false});
        if (data.key.universalRedirect) {
          this.setState({ athenname: data.key.alias, editUsername: true, hasDevice: data.key.hasDevice });

          if(data.key.ssoRedirect === true){
            this.props.dispatch(userActions.Auth0CompleteDevice(data.key.alias, data.key.auth0id, data.key.auth0token))
            return
          }
        } else
          this.setState({ athenname: data.key.alias, editUsername: true}); // data.key.universalLogin === true ? true : false });

        if(data.key.hasDevice) { this.setState({ hasDevice: true }) }

        if(!data.key.hasDevice){
          if(this.state.deviceHash === ""){
            DeviceFingerprint('login')
              .then((hash) => {
                //this.setState({ devicehash :"abd39432bba362ced69c38c15f47d937d70ea1da82d573cc67a1227231621a02"})
                this.setState({ deviceHash: hash });
                this.props.dispatch(userActions.checkUser({
                  alias: data.key.alias,
                  deviceHash: hash,
                  keys: true,
                }, false)).then((result) => {
                  if (result && result.passwordResetRequired) {
                    this.handleCognitoRequiredPwReset();
                  }
                })
              })
              .catch(function (error) {
              });
          }else
            this.props.dispatch(userActions.checkUser({
              alias: data.key.alias,
              deviceHash: this.state.deviceHash,
              keys: true,
            }, false)).then((result) => {
              if (result && result.passwordResetRequired) {
                this.handleCognitoRequiredPwReset();
              }
            })
        }
      }
    }

    keysStorage.Get('lastUser')
      .then((data) => {
        checkIndex(data)
      })
      .catch((e) => {
        if (e === "pending") {
          this.initialTimeout = setTimeout(() => {
            keysStorage.Get('lastUser')
              .then((data) => {
                checkIndex(data)
              })
              .catch((e) => {
                //this.setState({showPrivateModeMsg: true});
              })
          }, 1000);
        } else {
          //this.setState({showPrivateModeMsg: true});
        }
      })
  }

  handleCognitoRequiredPwReset = () => {
    this.props.dispatch(popoverAction.showDialog({
      dialogId: 'handle-cognito-pw-reset-required-dialog',
      title: 'A password reset is required',
      width: 'sm',
      content: <div>A password reset is required before you can log in.</div>,
      dialogActions: <MuiButton variant='contained' onClick={() => {
        this.handleForgot();
        this.props.dispatch(popoverAction.remove('handle-cognito-pw-reset-required-dialog'))
      }}>Reset password</MuiButton>
    }));
  }

  handleChange(e) {
    const { name, value } = e.target

    if (name === 'phash' && this.props.error === 'Unauthorised') {
      this.props.dispatch(userActions.loginClearError());
    }

    if (this.state.hasDevice) {
      if(name === 'phash'){
        this.setState({disableLogin: value === undefined || value === ""})
      }else if(name === 'phashconfirm'){
        const r = this.state.phash !== value && value !== "" && this.state.phash !== ""
        this.setState({disableLogin: r, passwordMatch: r && value.length})
      }
    } else {
      if(name === 'phash'){
        const r = this.state.phashconfirm !== value && value !== "" && this.state.phashconfirm !== ""
        this.setState({disableLogin: r, passwordMatch: r && value.length})
      }
      else if(name === 'phashconfirm'){
        const r = this.state.phash !== value && value !== "" && this.state.phash !== ""
        this.setState({disableLogin: r, passwordMatch: r && value.length})
      }
    }

    this.setState({ [name]: value });
  }

  checkError() {
    const { athenname, phash, phashconfirm, editUsername, submitted, update } = this.state;
    var msg = '';
    var service = '';

    if (submitted && !athenname && phash) {
      msg = "Username is required"
    } else if (submitted && !phash && athenname && this.props.error !== 'Unauthorised') {
      msg = "Password is required"
    } else if (submitted && !phash && !athenname) {
      msg = "Username and Password are required"
    } else if (this.props.alert.message === undefined && !submitted) {
      /*if (!("Notification" in window) || !('serviceWorker' in navigator) ||
        (!('PushManager' in window) && !('safari' in window))) {
        msg = 'Web Browser Push Notification is not supported.';
        service = 'Some features will not be enabled.';
      } else */
      if (!("crypto" in window) && !("msCrypto" in window)) {
        msg = 'Web Browser Cryptography is not detected.';
        service = 'Please either upgrade or choose different browser.';
      }

      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
      if (indexedDB === undefined || localStorage === undefined) {
        msg = 'Due to browser limitation';
        service = 'some features will not be enabled.';
      } else if (detectIE()) {
        msg = 'Due to Microsoft Edge browser limitations';
        service = 'some features will not be enabled.';
      }
      if (this.state.showPrivateModeMsg) {
        msg = 'If browser is in private mode.';
        service = 'Some features will not be enabled.';
      }
    } else {
      msg = this.getErrorMsg()
    }

    if (!this.props.isOnline) {
      msg = 'No internet connection detected.';
    } else if (!this.props.APIConnectionStatus) {
      msg = 'Unable to connect to Board';
      service = 'https://api.athenaboard.com/ may be blocked on your network.';
    }

    if (msg === "" || msg === undefined) return;
    return (
      <div className="login-error-msgapp">
        <span className="animated fadeInUp">
          <span>{msg}<br />{service}</span>
        </span>
      </div>
    )
  }

  checkUpdate() {
    var now = new Date();
    var pass = this.state.updateCheck;
    var diff = (now - pass) / 1000;

    if (diff > 60 * 5) {
      checkForUpdate()
        .then((updateAvailable) => {
          this.setState({ update: updateAvailable, updateCheck: new Date() });
        })
    }
  }

  async handleContinue () {
    var isUniversalLogin = false;
    try {
      var data = await keysStorage.Get('lastUser')
      if (data && data.key && data.key.universalLogin) {
        isUniversalLogin = true;
      }
    } catch { }
    // if (!isUniversalLogin) {
    //   localStorage.removeItem('AppToLogInto');
    // }
    const { athenname, deviceId, deviceHash } = this.state;
    var deviceid = deviceId;
    if (!athenname) {
      return;
    }
    if (!deviceHash) {
      return;
    }

    var loginReqest = {
      alias: athenname,
      deviceHash: deviceHash,
      keys: true,
    }

    this.props.dispatch(userActions.checkUser(loginReqest, true))
      .then((result) => {
        if (result && result.passwordResetRequired) {
          this.handleCognitoRequiredPwReset();
        }
      })
    this.setState({ phash: '', editUsername: true })
  }

  returnPassowrd(){
    if(this.state.hasDevice !== true){
      if(this.refSecPass !== null)
        this.refSecPass.focus()
      return
    }

    this.handleSubmit()
  }

  onEditUserName() {
    this.props.dispatch(userActions.loginClear())
    this.props.dispatch(alertActions.clear())
    this.setState({ editUsername: true, phash: "", submitted: false })
  }

  handleForgot() {
    if(this.state.athenname === "") return
    const { forgot } = this.state;
    this.setState({forgot: true})
  }

  handleSubmit(e) {
    if (e !== undefined)
      e.preventDefault();

    if (detectIE()) {
      var pass = false;
      if (document.cookie !== undefined) {
        if (document.cookie === "user=iemessage") {
          pass = true;
        }
      }
      if (!pass) {
        setCookie("user", "iemessage", 999);
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Limited Browser Support</h1>
              <p>Due to limitations with Microsoft Edge some features will be disabled.<br />
                <a href="https://www.athenaboard.com/" target="_blank">More Info</a></p>
              <p>Alternatively please use a supported web browser:</p>
              <div className="page">
                <label className="centerVFlex">Google Chrome <a href="https://www.google.com/chrome/" target="_blank"><LinkIcon size={20} color="#4680ff" style={{ marginLeft: 10 }} /></a></label>
                <label className="centerVFlex">Mozilla FireFox <a href="https://www.mozilla.org/" target="_blank"><LinkIcon size={20} color="#4680ff" style={{ marginLeft: 10 }} /></a></label>
              </div>
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <Button onClick={() => { onClose(); this.checkforUpdate() }} style={{ marginRight: 20 }}>OK</Button>
              </div>
            </div>,
        })
        return
      }
    }

    this.checkforUpdate();
  }

  checkforUpdate() {
    const { dispatch } = this.props;
    dispatch(userActions.clearErrorMsg());
    this.setState({ submitted: true });

    const { athenname, phash, deviceId, deviceHash } = this.state;
    var deviceid = deviceId;
    if (!athenname || !phash || !deviceHash) {
      if (this.state.update) {
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Update Available</h1>
              <p>Changes has been made that require browser refresh.</p>
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <Button type="clear" onClick={() => { onClose(); this.refreshPage() }}>Refresh</Button>
              </div>
            </div>,
        })
      }
      return;
    }

    if (this.state.update) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Update Available</h1>
            <p>Changes has been made that require browser refresh.</p>
            <div className="boardpanel flexend" style={{ marginTop: 20 }}>
              <Button type="clear" onClick={() => { onClose(); this.refreshPage() }}>Refresh</Button>
            </div>
          </div>,
      })
    } else
      this.doSubmit();
  }

  refreshPage() {
    window.location.reload(true)
    //window.location.href='/';
  }

  doSubmit() {
    const { dispatch, username, mode, hasDevice, universalLogin, requiresPassword, deviceId } = this.props;
    const { athenname, phash, phashconfirm, deviceHash } = this.state;
    if (!athenname || !phash) {
      return;
    }
    if (!deviceHash) {
      return;
    }

    if (mode === 2 && hasDevice === false && universalLogin === false && requiresPassword === false) {
      dispatch(userActions.Auth0Register({
        alias: athenname,
        username: username,
        password: phash,
        newpassword: '',
        cardSerial: '',
        deviceId: deviceId,
        deviceHash: deviceHash,
      }))
      return
    } else if (mode === 5 && hasDevice === false && universalLogin === false && requiresPassword === false) {
      // dispatch(userActions.CognitoRegister({
      //   alias: athenname,
      //   username: username,
      //   password: phash,
      //   newpassword: '',
      //   cardSerial: '',
      //   deviceId: deviceId,
      //   deviceHash: deviceHash,
      // }))
      // return
    }

    var loginReqest = {
      alias: athenname,
      deviceHash: deviceHash,
      password: phash,
      passwordLength: phash.length,
    }
    if (this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
    dispatch(userActions.login(loginReqest))
      .catch((e) => {
        if(e && e.resetPassword) {
          this.setState({ forgot: true });
        }
      });

    // var _this = this;
    // this.LoadKeys(athenname)
    // .then((id)=>{
    //   deviceid = id;
    //   var loginReqest = {
    //     alias: athenname,
    //     deviceId: deviceid,
    //     deviceHash: deviceHash,
    //     password: phash,
    //     keys: true,
    //   }
    //   if(_this.state.lastRateUpdate) clearInterval(_this.state.lastRateUpdate);
    //   dispatch(userActions.login(loginReqest));
    // })
    // .catch(function(error) {
    //   if(deviceid === ""){
    //     const guiid = uuidv4();
    //     keysStorage.Put({id: athenname+'deviceId', key: guiid}).then(()=>{});
    //     _this.setState({deviceId: guiid});
    //     deviceid = guiid;
    //   }
    //   var loginReqest = {
    //     alias: athenname,
    //     password: phash,
    //     deviceId: deviceid,
    //     deviceHash: deviceHash,
    //     keys: false,
    //   }
    //   if(_this.state.lastRateUpdate) clearInterval(_this.state.lastRateUpdate);
    //   dispatch(userActions.login(loginReqest));
    // });
  }

  authSubmit(authCode) {
    this.setState({ submitted: true });
    const { athenname, phash } = this.state;
    const { dispatch, username, universalLogin, password, mode } = this.props;
    let { deviceId } = this.props;

    if (athenname && (phash || universalLogin === true)) {
      //refresh the device ID just in case it was reset by login
      var _this = this;
      keysStorage.Get(username + 'deviceId')
        .then((data) => {
          if (data.key !== null) {
            deviceId = data.key;
            _this.setState({ deviceId: data.key });
          }

          var regReqest = {
            alias: athenname,
            username: username,
            deviceId: deviceId,
            deviceHash: _this.state.deviceHash,
            password: universalLogin ? password : phash,
            authCode: authCode,
            universalLogin,
            mode,
          }
          if (_this.state.lastRateUpdate) clearInterval(_this.state.lastRateUpdate);
          dispatch(userActions.registerUserDeviceLogin(regReqest));
          _this.setState({ submitted: false });
        })
        .catch((err) => {
          console.log("error", err);
          var regReqest = {
            alias: athenname,
            username: username,
            deviceId: deviceId,
            deviceHash: _this.state.deviceHash,
            password: universalLogin ? password : phash,
            authCode: authCode,
            universalLogin,
            mode,
          }

          if (_this.state.lastRateUpdate) clearInterval(_this.state.lastRateUpdate);
          dispatch(userActions.registerUserDeviceLogin(regReqest));
          _this.setState({ submitted: false });
        })
    }
  }

  registerSubmit() {
    this.setState({ phash: '', submitted: false, showRegister: false, hasDevice: true });
    this.props.dispatch(userActions.registrationCompleted())
  }

  clearForgot() {
    if (this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
    this.props.dispatch(alertActions.clear());
    this.props.dispatch(userActions.clearAuthCode());
    this.setState({ forgot: false });
  }

  clearAuth() {
    if (this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
    this.props.dispatch(alertActions.clear());
    this.props.dispatch(userActions.clearAuthCode());
    this.setState({ showAuth0: false, editUsername: true, showFrameExit: true, showRegister: false, submitted: false })
  }

  doAskforCode() {
    if (this.state.forgot) return false;
    if (!this.props.hasOwnProperty('askforCode')) return false;
    return this.props.askforCode;
  }

  doAskInitialDetails() {
    if (this.state.forgot) return false;
    const { askforUserDetails, mode, universalLogin, auth0Result, universalRedirect } = this.props
    if (askforUserDetails === undefined || mode === 2 && universalLogin === true && auth0Result === undefined && universalRedirect === false) return false;
    return askforUserDetails;
  }

  updateRate() {
    if (this.props.alert.message === undefined || this.props.alert.code === undefined) {
      if (this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      return;
    }
    if (this.props.alert.code !== '201') {
      if (this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      return;
    }
    var cur = new Date();
    var expire = new Date(this.props.alert.now.getTime() + this.props.alert.time * 1000);
    if (cur >= expire) {
      if (this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      this.props.dispatch(alertActions.clear());
      return;
    }

    var timeLeft = (expire.getTime() - cur.getTime()) / 1000;

    var string = "Login is disabled. Try again in ";
    if (timeLeft < 60)
      string += Math.ceil(timeLeft).toString() + " seconds";
    else if (timeLeft < 3600)
      string += (Math.ceil(timeLeft / 60)).toString() + " minutes";
    else if (timeLeft < 86400)
      string += (Math.ceil(timeLeft / 3600)).toString() + " hours";
    else
      string += (Math.floor(timeLeft / 86400)).toString() + " days";

    var obj = Object.assign({}, this.props.alert);
    obj.message = string;
    this.props.dispatch(alertActions.error(obj));
  }

  startRate() {
    var i = setInterval(this.updateRate, 10000);
    this.setState({ lastRateUpdate: i });
  }

  onDismissError() {
    this.props.dispatch(alertActions.clear());
  }

  onDismiss() {
    this.props.dispatch(userActions.loginClear())
    this.setState(({ maintenance: null }))
  }

  getErrorMsg() {
    if (this.state.submitted && !this.state.athenname) return;
    //    if(this.state.submitted && !this.state.phash) return;
    if (this.props.alert.hasOwnProperty('code')) {
      if (this.props.alert.code === '201') {
        if (this.state.lastRateUpdate === null) {
          //cannot set
          setTimeout(this.startRate, 1000);
        }
      }
    }

    if(this.props.alert.message === 'F103')
      return "Invalid password, please try another one"

    return this.props.alert.message;
  }

  serverType() {
    if (appConfig.envLabel !== undefined) {
      return <label style={{ color: 'red' }}> ({appConfig.envLabel})</label>;
    }
    return "";
  }

  onLangauge() {
    const messages = globalTranslations['de'];
    this.props.dispatch(updateIntl({
      locale: 'de',
      messages,
    }))
  }

  onSupport() {
    window.open(getSupportURL(), '_blank').focus();
  }

  onDismissDevice() {
    this.props.dispatch(userActions.loginClear());
    this.setState({ showAuth0: false })
  }

  Auth0Loaded() {
    this.setState({ iframeLoaded: true })
  }

  onCloseFrame() {
    this.props.dispatch(userActions.loginClear())
    this.setState({ showAuth0: false, editUsername: true, showFrameExit: true })
  }

  switchToAdminLogin() {
    localStorage.removeItem('LastType');
    // localStorage.removeItem('AppToLogInto');
    window.location.reload();
  }

  clearCache = () => {
    try {
      this.props.dispatch(popoverAction.showDialog({
        dialogId: 'athena-board-check-clear-cache',
        title: 'Are you sure you want to clear the local cache for Athena Board?',
        content: null,
        width: 'sm',
        dialogActions: <Stack direction='row' spacing={2}>
          <MuiButton variant='outlined' onClick={() => { try { this.props.dispatch(popoverAction.remove('athena-board-check-clear-cache')) } catch { } }}>Cancel</MuiButton>
          <MuiButton variant='contained' onClick={() => {
            try {
              keysStorage.ClearAll();
              SettingStorage.ClearAll();
              localStorage.clear();
              sessionStorage.clear();
              this.props.dispatch(popoverAction.remove('athena-board-check-clear-cache')); location.reload();
              refreshAppBundles(true);
            } catch (e) {
              console.log(e);
            }
          }}>Ok</MuiButton>
        </Stack>
      }));
    } catch (e) { console.log(e); }
  }

  render() {
    const { loggingIn, userType } = this.props;
    const { athenname, phash, phashconfirm, editUsername, submitted, update, showAuth0, hasDevice, disableLogin } = this.state;
    const showAuth = this.doAskforCode() && !this.doAskInitialDetails();
    const showRegister = this.doAskInitialDetails();

    // var appTypeToLogInto = JSON.parse(localStorage.getItem("AppToLogInto"));
    // appTypeToLogInto = appTypeToLogInto ? appTypeToLogInto.type : null;

    return (
      <div className="page spacebetween logContentApp" style={{ minHeight: '100vh' }}>
        <div />
        {this.state.showAuth0 &&
          <div>
            <div className="Auth0OverLay" style={{ left: this.state.iframeLoaded ? 0 : window.innerWidth }}>
              <div className="Auth0Centre">
                {this.state.showFrameExit &&
                  <ExitIcon className="login-iframe-exit" onClick={this.onCloseFrame.bind(this)} />
                }
                <iframe
                  id="frameParent"
                  className="auth0Frame"
                  src="/auth0Sign.html" //"/loginSignIn"
                  title="Single Sign Login"
                  onLoad={this.Auth0Loaded.bind(this)}
                >
                </iframe>
              </div>
            </div>
            {/* <LoadingIndicator /> */}
          </div>
        }
        {!this.state.showAuth0 &&
          <div className="logboxContent">
            {this.state.borwserSupport ?
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', height: '100%', alignItems: 'center', overflow: 'auto', justifyContent: 'center' }}>
                <div className="loginboxApp">
                  <div className="login-lock-header" style={{ maxWidth: '600px', width: '100%' }}>
                    <img className='app-logo' src={logo} />
                    <div style={{ paddingBottom: '30px', fontWeight: 'bold', fontSize: '15px', color: 'white' }}>Formerly Ansarada Board</div>
                    {!showAuth && editUsername &&
                      <h1>Board App Portal {this.serverType()}</h1>
                    }
                    {!showAuth && !editUsername && !showRegister &&
                      <h1>Welcome Back {this.serverType()}</h1>
                    }
                  </div>
                  {this.checkError()}
                  {(showAuth && !showRegister && !this.state.forgot) &&
                    <div>
                      <AuthCode
                        onSubmit={this.authSubmit.bind(this)}
                        onExit={this.clearAuth.bind(this)}
                        {...this.props}
                      />
                    </div>
                  }
                  {showRegister &&
                    <RegisterPage
                      alias={this.state.athenname}
                      initalPass={this.state.phash}
                      deviceId={this.state.deviceId}
                      deviceHash={this.state.deviceHash}
                      onReturn={this.registerSubmit.bind(this)}
                      onExit={this.clearAuth.bind(this)}
                      {...this.props}
                    />
                  }
                  {(editUsername && !showAuth && !showRegister && !this.state.forgot) &&
                    <div className="login-field">
                      <div className="login-field">
                        <div className={'maxWidth form-group' + (submitted && !athenname ? ' has-error' : '')}>
                          <WhiteTextField
                            variant='standard'
                            label='Username'
                            name="athenname"
                            sx={{ colorScheme: 'dark' }}
                            fullWidth={true}
                            type='login'
                            stylenormal="f-controlApp"
                            styleempty="f-controlempty"
                            onChange={this.handleChange}
                            onBlur={() => { this.setState({ athenname: this.state.athenname.trim() }) }}
                            onFocusIn={this.checkUpdate}
                            onReturn={this.handleContinue.bind(this)}
                            value={athenname}
                            initial={this.props.intl.formatMessage({ id: "username", defaultMessage: "Username" })}
                          />
                        </div>
                      </div>
                      <div className="login-forgotApp">
                        <label>Please enter your username<br /><br />
                          <span>First time?</span><br />
                          You'll find this in your welcome email
                        </label>
                      </div>
                      <div className="page login-button-parent">
                        {update &&
                          <MuiButton variant='contained' style={{ backgroundColor: '#9d2408f0' }} onClick={this.handleSubmit}>Update Available</MuiButton>
                        }
                        {!update && editUsername &&
                          <MuiButton variant='contained' className="login-button" loading={showAuth0 || this.props.userChecking === true} onClick={this.handleContinue.bind(this)}>Continue</MuiButton>
                        }
                      </div>
                    </div>
                  }
                  {(!editUsername && !showAuth && !showRegister && !this.state.forgot) && this.state.hasDevice === true &&
                    <div className="loginUserNameApp">
                      <WhiteTextField variant='standard' fullWidth={true} label='Username' disabled={true} value={athenname} />
                    </div>
                  }
                  {((editUsername || showAuth0) && !showAuth && !showRegister) &&
                    <div>
                    </div>
                  }
                  {!editUsername && !showAuth0 && !showAuth && !showRegister && (userType === "User" || userType === undefined) && this.state.hasDevice &&
                    <div className="login-field">
                      <div className="login-field">
                        <div className={'maxWidth form-group' + (submitted && !phash ? ' has-error' : '')}>
                          {/* <input type="hidden" name="password" /> */}
                          <WhiteTextField
                            name="phash"
                            label='Password'
                            variant='standard'
                            fullWidth={true}
                            type='password'
                            stylenormal="f-controlApp"
                            styleempty="f-controlempty"
                            onChange={this.handleChange}
                            onReturn={this.returnPassowrd.bind(this)}
                            onFocusIn={this.checkUpdate}
                            //autoFocus={true}
                            autoComplete="new-password"
                            value={phash}
                            placeholder={this.props.intl.formatMessage({ id: "password", defaultMessage: "Password" })}
                          />
                        </div>
                      </div>
                      {this.props.keys === false &&
                        <div className="login-field confirm-password">
                          <div className={'maxWidth form-group' + (submitted && !phashconfirm ? ' has-error' : '')}>
                            {/* <input type="hidden" name="password-confirm" /> */}
                            <WhiteTextField
                              name="phashconfirm"
                              variant='standard'
                              type='password'
                              label='Confirm password'
                              fullWidth={true}
                              stylenormal="f-controlApp"
                              styleempty="f-controlempty"
                              onChange={this.handleChange}
                              onReturn={this.handleSubmit}
                              onFocusIn={this.checkUpdate}
                              autoComplete="new-password"
                              value={phashconfirm}
                              placeholder={this.props.intl.formatMessage({ id: "password-confirm", defaultMessage: "Confirm password" })}
                              refpoint={(node) => { this.refSecPass = node;}}
                            />
                          </div>
                          <div className="loginpvre">
                            <label>As you are adding a previously registered device, please enter your Athena Board password.</label>
                            <label>You would have chosen this password during the initial registration process.</label>
                          </div>
                        </div>
                      }

                      <div className="loginpassmatch">
                        {this.state.passwordMatch &&
                          <label>{commonErrorMessages.password.mismatchedPasswordError}</label>
                        }
                      </div>

                      <div className="page login-button-parent" style={{ gap: '20px', maxWidth: '260px', margin: 'auto' }}>
                        {update &&
                          <Button variant="Primary" style={{ backgroundColor: '#9d2408f0' }} onClick={this.handleSubmit}>Update Available</Button>
                        }
                        {!update && !editUsername && this.state.hasDevice &&
                          // <Button variant="Primary" className="login-button" disabled={disableLogin} loading={showAuth0} onClick={this.handleSubmit}>Continue</Button>
                          <MuiButton variant="contained" sx={{ '&.Mui-disabled': { background: 'rgb(224 224 224)' } }} fullWidth={true} loading={showAuth0} onClick={() => { if (disableLogin) { return } this.handleSubmit() }}>Continue</MuiButton>
                        }
                        {!update && !editUsername && !this.state.hasDevice &&
                          // <Button variant="Primary" className="login-button" disabled={disableLogin} loading={showAuth0} onClick={this.handleSubmit}>Continue</Button>
                          <MuiButton variant="contained" sx={{ '&.Mui-disabled': { background: 'rgb(224 224 224)' } }} fullWidth={true} loading={showAuth0} onClick={() => { if (disableLogin) { return } this.handleSubmit() }}>Continue</MuiButton>
                        }
                        {!update && !editUsername &&
                          <MuiButton variant='outlined' fullWidth={true} onClick={this.onEditUserName.bind(this)}>Back</MuiButton>
                        }
                        {!update && !editUsername &&
                          <MuiButton variant='outlined' fullWidth={true} loading={showAuth0} onClick={this.handleForgot.bind(this)}>Forgot password</MuiButton>
                        }
                      </div>
                    </div>
                  }

                  {(loggingIn || !this.state.hasDevice && this.props.userChecking === true ) &&
                    <LoadingOverlay />
                  }
                </div>
                
                {/* {appTypeToLogInto == 'directorWebApp' && <span style={{ paddingBottom: '40px' }} className='admin-page-switch-to-director-link' onClick={(e) => { this.switchToAdminLogin() }}>Switch to Admin Portal</span>} */}

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '10px 0 30px 0', color: 'white', cursor: 'pointer' }}>
                  <span onClick={() => { window.location = '/admin.html'; }}>Switch to Admin portal</span>
                </div>

                <p id="privacy-noteApp">Read our <a target="_blank" href={appConfig.athenaTerms}>terms of use</a> and <a target="_blank" href={appConfig.athenaPrivacy}>privacy policy</a>.</p>
              </div>
              :
              <div className="loginbox">
                <img className='Athena-logo' src={logo}/>
                <h1><FormattedMessage id="app.title" defaultMessage="Athena Board Portal" /> {this.serverType()}</h1>
                <div>Unsupported Browser: Please upgrade or use modern browser such as Chrome, Firefox or Safari.</div>
              </div>
            }
          </div>
        }
        {this.state.forgot &&
          <ForgotPage
            athenname={this.state.athenname}
            onExit={this.clearForgot.bind(this)}
            deviceId={this.state.deviceId}
            deviceHash={this.state.deviceHash}
            {...this.props}
          />
        }
        {this.state.maintenance !== null &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Maintenance in progress</h1>
              <p>{this.state.maintenance.statusMessage}</p>
              <p>Estimated end time: {moment(this.state.maintenance.endTime).format(DateFormat.LLLL)}</p>
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <Button variant="Primary" onClick={this.onDismiss.bind(this)} style={{ marginRight: 20 }}>OK</Button>
              </div>
            </div>
          </div>
        }
        {this.props.alert.message !== undefined && this.props.alert.message === 'H101' &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Unable to connect to Athena Board Servers</h1>
              <p>Please check your network connections.</p>
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <Button variant="Primary" onClick={this.onDismissError.bind(this)} style={{ marginRight: 20 }}>OK</Button>
              </div>
            </div>
          </div>
        }
        {this.props.wrongDevice === true &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Athena Board App Account</h1>
              <p>Your account does not have access to the Athena Board Admin Portal.</p>
              <p>To use this account please log in via the Athena Board App, available for:</p>
              <div className="page">
                <label className="centerVFlex">Windows 10 <a href="https://www.microsoft.com/store/apps/9PDVCTH74VTJ" target="_blank"><LinkIcon size={20} color="#4680ff" style={{ marginLeft: 10 }} /></a></label>
                <label className="centerVFlex">macOS <a href="https://apps.apple.com/au/app/id1482354553" target="_blank"><LinkIcon size={20} color="#4680ff" style={{ marginLeft: 10 }} /></a></label>
                <label className="centerVFlex">iPhone & iPad <a href="https://itunes.apple.com/au/app/athena-board/id1439126398?ls=1&mt=8" target="_blank"><LinkIcon size={20} color="#4680ff" style={{ marginLeft: 10 }} /></a></label>
              </div>
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <Button variant="Primary" onClick={this.onDismissDevice.bind(this)} style={{ marginRight: 20 }}>OK</Button>
              </div>
            </div>
          </div>
        }
        {this.props.pendingApproval === true &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Athena Board Account Setup</h1>
              <p>You are now registered with Athena Board.</p>
              <p>Your account is now being finalised. You will be notified by email when you can login.</p>
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <Button variant="Primary" onClick={this.onDismissDevice.bind(this)} style={{ marginRight: 20 }}>OK</Button>
              </div>
            </div>
          </div>
        }
        <div className="loginVersionApp"><label style={{ textAlign: 'center' }} onClick={() => { this.clearCache(); }}>Version: {getVersion()}</label></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    loggingIn,
    askforCode,
    hasDevice,
    askforUserDetails,
    wrongDevice,
    completeDetails,
    passwordPolicyDescription,
    passwordPolicyRegex,
    requiresRecoveryCard,
    passwordPolicy,
    pendingApproval,
    mfaId,
    mfaType,
    username,
    userChecked,
    userChecking,
    auth0Result,
    mode,
    universalLogin,
    password,
    universalRedirect,
    requiresPassword,
    deviceId,
    maintenance,
    enabled,
    error,
    hasIncompleteAnsaradaSignup,
    userType,
    alias,
    keys,
  } = state.authentication;
  return {
    alert: state.alert,
    loggingIn,
    askforCode,
    hasDevice,
    mfaId,
    mfaType,
    askforUserDetails,
    wrongDevice,
    completeDetails,
    requiresRecoveryCard,
    passwordPolicy,
    passwordPolicyDescription,
    passwordPolicyRegex,
    pendingApproval,
    username,
    userChecked,
    userChecking,
    auth0Result,
    mode,
    universalLogin,
    password,
    universalRedirect,
    requiresPassword,
    deviceId,
    maintenance,
    enabled,
    error,
    hasIncompleteAnsaradaSignup,
    userType,
    alias,
    keys,
    APIConnectionStatus: state.keyValuePair.APIConnectionStatus,
    isOnline: state.keyValuePair.isOnline,
  };
}

const connectedLoginPage = connect(mapStateToProps)(injectIntl(LoginAppClass));
export { connectedLoginPage as LoginApp };
