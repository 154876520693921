import React from 'react';
import {TextInput} from '@common/textinput';
import { timeoutOptions } from '@lib/simpletools';
import { MuiSelect, MuiSwitch } from '@common/MUI';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DEFAULTSETTINGS from '@common/optionsettings';

//import '../../styles/boardspage/boardspage.css';

export default class ListSettings extends React.Component {
  constructor(props) {
    super(props);

    this.timeoutValues = timeoutOptions()
    this.timeoutValuesOther = [...timeoutOptions()]

    //var minOs = DEFAULTSETTINGS.defaultSettings.minimumOSVersion;
    //var current = this.props.Settings.minimumOSVersion;

    this.readonly = false;
    if(this.props.readonly !== undefined)
      this.readonly = this.props.readonly;

    this.showAll = true;
    if(this.props.showAll !== undefined)
      this.showAll = this.props.showAll;
    this.showBinder = false;
    if(this.props.showBinder !== undefined)
      this.showBinder = this.props.showBinder;

    var set = this.props.Settings;
    if(!this.props.Settings.canPrint){
      set.showsWatermarkOnPrintedDocs = false;
    }
    if(!this.props.Settings.canAnnotate){
      set.canAnnotateFiles = false;
      set.allowAnnotationDeletion = false;
      set.canShareDocumentsIfAnnotated = false;
      set.canShareAnnotations = false;
      set.canReceiveSharedAnnotations = false;
    }
    if(!this.props.Settings.canTakeNotes){
      set.allowNotesDeletion = false;
      set.canPrintNotes = false;
      set.canExportNotes = false;
    }
    if(!this.props.Settings.canShareDocuments){
      set.canShareWithoutPassword = false;
    }

    this.state = {
      readOnlyShowsWatermarkOnPrintedDocs: !this.props.Settings.canPrint,
      readOnlyCanAnnotateFiles: !this.props.Settings.canAnnotate,
      readOnlyAnnotationsDeleteAfterMeeting: !this.props.Settings.canAnnotate,
      readOnlyAllowAnnotationDeletion: !this.props.Settings.canAnnotate,
      readOnlyNotesForBinderDeleteAfterMeeting: !this.props.Settings.canTakeNotes,
      readOnlyAllowNotesDeletion: !this.props.Settings.canTakeNotes,
      readOnlyCanShareDocumentsIfAnnotated: !this.props.Settings.canAnnotate,
      readOnlyCanShareAnnotations: !this.props.Settings.canAnnotate,
      readOnlyCanReceiveSharedAnnotations: !this.props.Settings.canAnnotate,
      readOnlyCanShareWithoutPassword: !this.props.Settings.canShareDocuments,
      readOnlyDaysUntilBoardDataDeletedUponLostDevice: !this.props.Settings.immediateBoardDataDeletedUponLostDevice,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleOsChange = this.handleOsChange.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.Settings){
      this.setState({
        readOnlyShowsWatermarkOnPrintedDocs: !nextProps.Settings.canPrint,
        readOnlyCanAnnotateFiles: !nextProps.Settings.canAnnotate,
        readOnlyAnnotationsDeleteAfterMeeting: !nextProps.Settings.canAnnotate,
        readOnlyAllowAnnotationDeletion: !nextProps.Settings.canAnnotate,
        readOnlyNotesForBinderDeleteAfterMeeting: !nextProps.Settings.canTakeNotes,
        readOnlyAllowNotesDeletion: !nextProps.Settings.canTakeNotes,
        readOnlyCanShareDocumentsIfAnnotated: !nextProps.Settings.canAnnotate,
        readOnlyCanShareAnnotations: !nextProps.Settings.canAnnotate,
        readOnlyCanReceiveSharedAnnotations: !nextProps.Settings.canAnnotate,
        readOnlyCanShareWithoutPassword: !nextProps.Settings.canShareDocuments,
        readOnlyDaysUntilBoardDataDeletedUponLostDevice: !nextProps.Settings.immediateBoardDataDeletedUponLostDevice,
      })
    }
  }

  doOnChange(set){
    if(this.props.onChange !== undefined)
      this.props.onChange(set);
  }

  handleChange(name, value) {
    var set = this.props.Settings;
    if(name === "readOnlyDaysUntilBoardDataDeletedUponLostDevice"){
      set.immediateBoardDataDeletedUponLostDevice = !value
      this.setState({readOnlyDaysUntilBoardDataDeletedUponLostDevice: value})
    }else {
      set[name] = value;
    }
    if(name === 'canPrint'){
      if(value){
        set.showsWatermarkOnPrintedDocs = DEFAULTSETTINGS.defaultSettings.showsWatermarkOnPrintedDocs;
      }else{
        set.showsWatermarkOnPrintedDocs = false;
      }
      this.setState({readOnlyShowsWatermarkOnPrintedDocs: !value});
    }
    if(name === 'canAnnotate'){
      if(value){
        set.canAnnotateFiles = DEFAULTSETTINGS.defaultSettings.canAnnotateFiles;
        set.allowAnnotationDeletion = DEFAULTSETTINGS.defaultSettings.allowAnnotationDeletion;
        set.canShareDocumentsIfAnnotated = DEFAULTSETTINGS.defaultSettings.canShareDocumentsIfAnnotated;
        set.canShareAnnotations = DEFAULTSETTINGS.defaultSettings.canShareAnnotations;
        set.canReceiveSharedAnnotations = DEFAULTSETTINGS.defaultSettings.canReceiveSharedAnnotations;
      }else{
        set.canAnnotateFiles = false;
        set.allowAnnotationDeletion = false;
        set.canShareDocumentsIfAnnotated = false;
        set.canShareAnnotations = false;
        set.canReceiveSharedAnnotations = false;
      }
      this.setState({
        readOnlyCanAnnotateFiles: !value,
        readOnlyAnnotationsDeleteAfterMeeting: !value,
        readOnlyAllowAnnotationDeletion: !value,
        readOnlyCanShareDocumentsIfAnnotated: !value,
        readOnlyCanShareAnnotations: !value,
        readOnlyCanReceiveSharedAnnotations: !value,
      });
    }
    if(name === 'canTakeNotes'){
      if(value){
        set.allowNotesDeletion = DEFAULTSETTINGS.defaultSettings.allowNotesDeletion;
        set.canPrintNotes = DEFAULTSETTINGS.defaultSettings.canPrintNotes;
        set.canExportNotes = DEFAULTSETTINGS.defaultSettings.canExportNotes;
      }else{
        set.allowNotesDeletion = false;
        set.canPrintNotes = false;
        set.canExportNotes = false;
      }
      this.setState({
        readOnlyNotesForBinderDeleteAfterMeeting: !value,
        readOnlyAllowNotesDeletion: !value,
      });
    }
    if(name === 'canShareDocuments'){
      if(value){
        set.canShareWithoutPassword = DEFAULTSETTINGS.defaultSettings.canShareWithoutPassword;
      }else{
        set.canShareWithoutPassword = false;
      }
      var v = !value;
      this.setState({canShareDocuments: v, readOnlyCanShareWithoutPassword: v});
    }
    if(name === 'includeRecipientsInVotesByDefault'){
      set.includeRecipientsInResolutionsByDefault = value;
    }
    if(name === 'canShareAnnotations') {
      set.canReceiveSharedAnnotations = value;
    }
    this.doOnChange(set);
  }

  handleTextChange(e) {
    var set = this.props.Settings;
    const { name, value } = e.target;
    set[name] = value;
    this.doOnChange(set);
  }

  handleOsChange(e){
    var set = this.props.Settings;
    const { name, value } = e.target;

    var minOs = set.minimumOSVersion;
    minOs[name] = value;
    set.minimumOSVersion = minOs;
    this.doOnChange(set);
  }

  handleNumChange(type, e) {
    var set = this.props.Settings;
    set[type] = e.target.value;
    this.doOnChange(set);
  }

  handleTimeout(type, d){
    var set = this.props.Settings;
    set[type] = d;
    this.doOnChange(set);
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    var set = this.props.Settings;
    set[name] = value;
    this.doOnChange(set);
  }

  getTimeoutValue(sec){
    var m = Math.floor(sec/60)
    if(m < 2) return { value: sec, label: m+" minute"}
    const c = this.timeoutValues.find(o => o.value === sec)
    if(c) return c
    return { value: sec, label: m+" minutes"}
  }

  renderOS() {
    let arry = [];

    var minOs = DEFAULTSETTINGS.defaultSettings.minimumOSVersion;
    var current = this.props.Settings.minimumOSVersion;
    for (var key in minOs) {
      var value = minOs[key]
      if(current[key] !== undefined) value = current[key]
      arry.push(
        <div className="custLabel" key={key}>
          <div>
            <label>Minimum {key} device version</label>
          </div>
          {!this.readonly ?
          <TextInput
            name={key}
            value={value}
            stylenormal="standardOsInput"
            onChange={this.handleOsChange}/>
          :
          value
          }
        </div>
      );
    }

    return arry;
  }

  getComparisonBorder = (settingName = '') => {
    if (!this.props.settingsToCompareTo || !settingName) { return {}; }
    if (this.props.settingsToCompareTo[settingName] !== this.props.Settings[settingName]) {
      return {
        'border': '1ps solid red'
      };
    }
  }

  render() {
    return (
      <div style={{ flex: 1, display: 'flex', overflow: 'hidden', paddingLeft: '5px' }}>
        <Grid container spacing={this.props.isPopup ? 0 : {md: 6}}>
          <Grid item xs={12} md={this.props.isPopup ? 12 : 6}>
            <div>
              <MuiSwitch
                style={this.getComparisonBorder('canPrint')}
                name="canPrint"
                label="Can print documents"
                detail="Allow users to print documents"
                readonly={this.readonly}
                value={this.props.Settings.canPrint}
                onChange={this.handleChange}
              />
              {this.props.Settings.canPrint && (
                <div className='setting-sub'>
                  <MuiSwitch
                    name="showsWatermarkOnPrintedDocs"
                    style={this.getComparisonBorder('showsWatermarkOnPrintedDocs')}
                    label="Add watermark to printed documents"
                    detail="The users name will be watermarked on the document"
                    readonly={this.readonly ? this.readonly : this.state.readOnlyShowsWatermarkOnPrintedDocs}
                    value={this.props.Settings.showsWatermarkOnPrintedDocs}
                    onChange={this.handleChange}
                  />
                </div>
              )}
            </div>
            <div>
              <MuiSwitch
                name="canAnnotate"
                style={this.getComparisonBorder('canAnnotate')}
                label="Can add annotation"
                detail="Allow users to annotate documents"
                readonly={this.readonly}
                value={this.props.Settings.canAnnotate}
                onChange={this.handleChange}
              />
              {this.props.Settings.canAnnotate && (
                <div className='setting-sub'>
                  <MuiSwitch
                    name="canAnnotateFiles"
                    label="Annotate documents"
                    detail="Allow users to annotate documents"
                    readonly={this.readonly ? this.readonly : this.state.readOnlyCanAnnotateFiles}
                    value={this.props.Settings.canAnnotateFiles}
                    onChange={this.handleChange}
                  />
                  <MuiSwitch
                    name="allowAnnotationDeletion"
                    label="Delete annotations on documents and files"
                    detail="Allow users to delete annotations"
                    readonly={this.readonly ? this.readonly : this.state.readOnlyAllowAnnotationDeletion}
                    value={this.props.Settings.allowAnnotationDeletion}
                    onChange={this.handleChange}
                  />
                  <MuiSwitch
                    name="canShareDocumentsIfAnnotated"
                    label="Include annotations in shared documents"
                    detail="Allow users to include annotations in shared documents"
                    readonly={this.readonly ? this.readonly : this.state.readOnlyCanShareDocumentsIfAnnotated}
                    value={this.props.Settings.canShareDocumentsIfAnnotated}
                    onChange={this.handleChange}
                  />
                  {this.props.isBoardSettings
                    ? <MuiSwitch
                      name="canShareAnnotations"
                      label="Share annotations"
                      detail="Allow users to share annotations to each other"
                      readonly={this.readonly ? this.readonly : this.state.readOnlyCanShareAnnotations}
                      value={this.props.Settings.canShareAnnotations}
                      onChange={this.handleChange}
                    />
                    : null
                  }
                  {/* {this.props.isBoardSettings
                    ? <MuiSwitch
                      name="canReceiveSharedAnnotations"
                      label="Can receive shared annotations"
                      detail="Allow users to receive shared annotations for documents"
                      readonly={this.readonly ? this.readonly : this.state.readOnlyCanReceiveSharedAnnotations}
                      value={this.props.Settings.canReceiveSharedAnnotations}
                      onChange={this.handleChange}
                    />
                    : null
                  } */}
                </div>
              )}
            </div>
            <div>
              <MuiSwitch
                name="canTakeNotes"
                style={this.getComparisonBorder('canTakeNotes')}
                label="Can take notes"
                detail="Allow users to take freehand notes"
                readonly={this.readonly}
                value={this.props.Settings.canTakeNotes}
                onChange={this.handleChange}
              />
              {this.props.Settings.canTakeNotes && (
                <div className='setting-sub'>
                  <MuiSwitch
                    name="allowNotesDeletion"
                    label="Delete notes"
                    detail="Allow users to delete notes"
                    readonly={this.readonly ? this.readonly : this.state.readOnlyAllowNotesDeletion}
                    value={this.props.Settings.allowNotesDeletion}
                    onChange={this.handleChange}
                  />
                  <MuiSwitch
                    name="canPrintNotes"
                    label="Print notes"
                    detail="Allow users to print notes"
                    readonly={this.readonly ? this.readonly : this.state.readOnlyAllowNotesDeletion}
                    value={this.props.Settings.canPrintNotes}
                    onChange={this.handleChange}
                  />
                  <MuiSwitch
                    name="canExportNotes"
                    label="Export notes (iOS and MacOS only)"
                    detail="Allow users to export and share notes"
                    readonly={this.readonly ? this.readonly : this.state.readOnlyAllowNotesDeletion}
                    value={this.props.Settings.canExportNotes}
                    onChange={this.handleChange}
                  />
                </div>
              )}
            </div>
            {!this.showBinder && (
              <div>
                <MuiSwitch
                  name="canShareDocuments"
                  label="Can share documents"
                  detail="Allow users to share documents via email"
                  readonly={this.readonly}
                  value={this.props.Settings.canShareDocuments}
                  onChange={this.handleChange}
                />
                {this.props.Settings.canShareDocuments && (
                  <div className='setting-sub'>
                    <MuiSwitch
                      name="canShareWithoutPassword"
                      label="Share without a password"
                      detail="Allow users to share documents without setting a password"
                      readonly={this.readonly ? this.readonly : this.state.readOnlyCanShareWithoutPassword}
                      value={this.props.Settings.canShareWithoutPassword}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={this.props.isPopup ? 12 : 6}>
            {this.showBinder && (
              <div>
                <MuiSwitch
                  name="canShareDocuments"
                  label="Can share documents"
                  detail="Allow users to share documents via email"
                  readonly={this.readonly}
                  value={this.props.Settings.canShareDocuments}
                  onChange={this.handleChange}
                />
                {this.props.Settings.canShareDocuments && (
                  <div className='setting-sub'>
                    <MuiSwitch
                      name="canShareWithoutPassword"
                      label="Share without a password"
                      detail="Allow users to share documents without setting a password"
                      readonly={this.readonly ? this.readonly : this.state.readOnlyCanShareWithoutPassword}
                      value={this.props.Settings.canShareWithoutPassword}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
              </div>
            )}
            {/* {this.props.showBinder && (
              <div>
                <MuiSwitch
                  name="autoHideItems"
                  label="Hide item display name"
                  detail="Hide item display name for users who don't have permission"
                  readonly={this.readonly}
                  value={this.props.Settings.autoHideItems}
                  onChange={this.handleChange}
                />
              </div>
            )} */}
            <div>
              <MuiSwitch
                name="allowPDFLinks"
                label="Allow external links to open from the document viewer"
                detail="Allow external links in documents to be opened from the document viewer"
                readonly={this.readonly}
                value={this.props.Settings.allowPDFLinks}
                onChange={this.handleChange}
              />
            </div>
            {/* {(this.showAll || this.showBinder) && (
              <div>
                <MuiSwitch
                  name="includeRecipientsInVotesByDefault"
                  label="Include recipients in votes and resolutions by default"
                  detail="Recipients are included in votes and resolutions by default"
                  readonly={this.readonly}
                  value={this.props.Settings.includeRecipientsInVotesByDefault}
                  onChange={this.handleChange}
                />
              </div>
            )} */}
            <div>
              {!this.showBinder && (
                <div>
                  <label className='colorStand text16s bold' style={{ marginTop: 10 }}>Time out users session</label>
                  <div className='setting-sub'>
                    <div className="maxWidth bottom30" style={{ marginTop: 20 }}>
                      <MuiSelect
                        label="Screen timeout"
                        size="small"
                        options={this.timeoutValues}
                        disabled={this.readonly}
                        onChange={this.handleTimeout.bind(this, "secondsForInAppTimeout")}
                        value={this.props.Settings.secondsForInAppTimeout}
                        detail="Period of inactivity before auto-logout countdown appears"
                      />
                    </div>
                    <div className="maxWidth bottom20">
                      <MuiSelect
                        label="App timeout"
                        size="small"
                        options={this.timeoutValuesOther}
                        disabled={this.readonly}
                        onChange={this.handleTimeout.bind(this, "secondsForTimeout")}
                        value={this.props.Settings.secondsForTimeout}
                        detail="Time before auto-logout after app been closed / sent to the background"
                      />
                    </div>
                    <MuiSwitch
                      name="disableInAppTimeoutDuringMeeting"
                      label="Don't timeout 2 hours before and 6 hours after a meeting"
                      detail="Stop the in-app timeout from appearing for 2 hours before and 6 hours after the meeting time"
                      readonly={this.readonly}
                      value={this.props.Settings.disableInAppTimeoutDuringMeeting}
                      onChange={this.handleChange}
                    />
                    <MuiSwitch
                      name="allowInAppTimeoutDisable"
                      label="Allow users to disable the session time out"
                      detail='Show "disable timeout" button in app'
                      readonly={this.readonly}
                      value={this.props.Settings.allowInAppTimeoutDisable}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              )}
              {this.showAll && (
                <div>
                  <label className='colorStand text16s bold' style={{marginTop: 10}}>Delete data if device is lost</label>
                  <div className='setting-sub' style={{marginTop: 10}}>
                    <MuiSwitch
                      name="immediateBoardDataDeletedUponLostDevice"
                      label="Immediately delete data if device is lost"
                      readonly={this.readonly}
                      value={this.props.Settings.immediateBoardDataDeletedUponLostDevice}
                      onChange={this.handleChange}
                    />
                    <MuiSwitch
                      name="readOnlyDaysUntilBoardDataDeletedUponLostDevice"
                      label="Days to retain data before it is deleted"
                      detail="Number of days to retain lost device data before it's deleted"
                      readonly={this.readonly}
                      value={this.state.readOnlyDaysUntilBoardDataDeletedUponLostDevice}
                      onChange={this.handleChange}
                    />
                    {this.state.readOnlyDaysUntilBoardDataDeletedUponLostDevice && (
                      <div className="setting-sub" style={{ width: "50%" }}>
                        <TextField
                          name="daysUntilBoardDataDeletedUponLostDevice"
                          label="Number of days"
                          disabled={this.readonly}
                          value={this.props.Settings.daysUntilBoardDataDeletedUponLostDevice}
                          onChange={this.handleNumChange.bind(this, "daysUntilBoardDataDeletedUponLostDevice")}
                          variant="outlined"
                          size="small"
                          type="number"
                          fullWidth
                          InputProps={{
                            inputProps: { min: 1, max: 21 }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {this.showAll && (
                <div>
                  <MuiSwitch
                    name="requiresLocation"
                    label="Restrict access from a list of countries"
                    detail="Disable board access in selected countries"
                    readonly={this.readonly}
                    value={this.props.Settings.requiresLocation}
                    onChange={this.handleChange}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
