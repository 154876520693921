import { userConstants, binderConstants, webConstants } from '@constants/appuser/';
import { userService } from '@services/appuser/';
import { alertActions, boardActions, binderActions, statusActions } from './';
import {keysStorage} from '@lib/indexeddb';
import { history, CodeError, SettingStorage, BlockLogout } from '@lib';
import { trackRecord, CheckInternet, checkForUpdate, DeviceFingerprint, CredentialsHash, getMfaType } from '@lib/simpletools';
import moment from 'moment';
import { kvpConstants } from "../../constants/admin";
import { isUserAndAdminPopover, kvpActions, popoverAction, resolutionsActions } from '../admin';
import { v4 as uuidv4 } from 'uuid';
import { RoutesConstants, UserTypeEnum } from '@constants/common.constants';
import { baseMarketingActions } from '../marketing.base.actions';
import { baseBackupActions } from '../backup.base.actions';
import { SESSIONREPLACEMENTTIMEOUT } from '../../lib/limits';
import { BroadcastChannelName } from '../../constants';

export const userActions = {
    checkUser,
    login,
    logout,
    loginWithOutRedirect,
    loginLockScreen,
    loginClear,
    loginClearError,
    connectWebSocket,
    clearErrorMsg,
    clearAuthCode,
    Auth0Logout,
    Auth0Credential,
    Auth0Register,
    Auth0Complete,
    Auth0CompleteDevice,
    Auth0Refresh,
    hasDevice,
    checkAlias,
    registerUserDeviceLogin,
    registerUserDeviceAll,
    registerUserDevicePage,
    registerUserDeviceWithKey,
    registerNewUser,
    registerNewUserLogin,
    registrationCompleted,
    getCurrentUserIds,
    getFirstSortedCustomerId,
    sendResetLink,
    keepAlive,
    lockScreen,
    activeScreen,
    getUsers,
    changePassword,
    clearPasswordLock,
    initialisePassChange,
    getPasswordPolicy,
    sendMFA,
    forgotNC,
    forgotNewPass,
    forgotWCard,
    forgotWCardNewPass,
    setKeys,
    impersonateUser,
    autoInvite,
    clearInvite,
    processInvite,
};

function getDeviceDetails(customerId, userId, event = "loginSession"){
  return {
    event,
    userId,
    customerId,
    screen: {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      devicePixelRatio: window.devicePixelRatio,
      availWidth: window.screen.availWidth,
      availHeight: window.screen.availHeight,
    },
    navigator: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      appVersion: navigator.appVersion,
      vendor: navigator.vendor,
      opera: window.opera
    }
  }
}

function RecordHasDevice(DeviceInfo){
  let payload = {}
  if(DeviceInfo.passwordPolicyDescription !== undefined)
    payload.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription;
  if(DeviceInfo.passwordPolicyRegex !== undefined)
    payload.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex;
  if(DeviceInfo.passwordPolicy !== undefined)
    payload.passwordPolicy = DeviceInfo.passwordPolicy;
  if(DeviceInfo.sms !== undefined)
    payload.sms = DeviceInfo.sms;
  if(DeviceInfo.startTime !== undefined){
    payload.maintenance = {
      enabled: DeviceInfo.enabled,
      endTime: DeviceInfo.endTime,
      startTime: DeviceInfo.startTime,
      statusMessage: DeviceInfo.statusMessage,
    }
  }

  return payload
}

function loadDefault(){
  return dispatch => {
    if(!window.demo)
      dispatch(connectWebSocket());
    //cache the boards, user, binders
    dispatch(boardActions.previewBoard());
    dispatch(getUsers());
  }
}

function checkFixingTask(dispatch, payload, loginReqest){
  if(payload.pendingInvites !== undefined){
    dispatch(autoInvite(payload, loginReqest))
  }
}

function checkUserDetails(loginReqest){
  return new Promise((resolve, reject) => {
    userService.hasAlias(loginReqest.alias)
    .then(
      aliasInfo => {
        let people = {}
        if (aliasInfo && aliasInfo.people && aliasInfo.people[0]) {
          people = aliasInfo.people[0];
        }
        if(loginReqest.username === undefined){
          if(aliasInfo.people === undefined || aliasInfo.people.length === 0){
            reject("H401")
            return
          }

          if(aliasInfo.people.length > 1){

          }

          loginReqest.username = aliasInfo.people[0].username
          people = aliasInfo.people[0]
        }

        userService.hasDevice(loginReqest.username)
           .then(
             DeviceInfo => {
               //DeviceInfo.username = loginReqest.username
               DeviceInfo = Object.assign(DeviceInfo, people, {alias: loginReqest.alias, deviceHash: loginReqest.deviceHash, universalLogin: false, universalRedirect: false})
               if(people.usesSSO === true || people.usesMFA === true){
                 DeviceInfo.universalLogin = true
                 if(people.usesSSO === true) DeviceInfo.universalRedirect = true
               }

               if(people.hasIncompleteAnsaradaSignup === true){
                 reject("Your Ansarada registration is not yet complete, please complete this before attempting board registration")
                 return
               }

               // if(people.mode === 2 && !DeviceInfo.universalLogin){
               //
               //   return
               // }
               //if(people.mode === 2)
              // DeviceInfo.universalLogin = true
              // DeviceInfo.universalRedirect = true

               console.log('hasDevice');
               resolve(DeviceInfo)
             },
             error => {
               reject(error)
             }
           )
       },
       error => {
         reject(error)
       }
    )
  })
}

function checkUser(loginReqest, redirect = false){
  return dispatch => {
    return new Promise((resolve, reject) => {
      window.demo = false
      dispatch(alertActions.clear());
      dispatch(request(loginReqest.alias));

      if (loginReqest.alias === "demo") {
        window.demo = true
        userService.loginDemo()
          .then(
            payload => {
              dispatch(successHasDevice(payload));
              keysStorage.Put({
                id: 'lastUser', key: {
                  universalLogin: false,
                  alias: loginReqest.alias,
                  universalRedirect: false,
                  userType: UserTypeEnum.Publish,
                  hasDevice: true,
                  hasIncompleteAnsaradaSignup: false,
                  canRegister: true,
                }
              }).then(() => {
                // window.location = '/admin.html'
              });
            },
            error => {
              dispatch(failure(error));
              dispatch(alertActions.error(error));
            }
          )
        return
      }

      //clear session cookies
      localStorage.removeItem('AppWeb');
      localStorage.removeItem('AthenaWeb');

      // var appTypeToLogInto = JSON.parse(localStorage.getItem("AppToLogInto"));
      // appTypeToLogInto = appTypeToLogInto ? appTypeToLogInto.type : null;

      checkUserDetails(loginReqest)
        .then(async (DeviceInfo) => {
          if (DeviceInfo && DeviceInfo.mode === 5) {
            dispatch(popoverAction.showRedirectToNewWebsite(true));
            reject('');
            return;
          }
          if (DeviceInfo.passwordResetRequired) {
            resolve(DeviceInfo);
            return;
          }
          if (DeviceInfo.isUserAndAdmin) {
            await isUserAndAdminPopover(dispatch, 'Director');
          }

          // var appTypeToLogInto = JSON.parse(localStorage.getItem("AppToLogInto"));
          // appTypeToLogInto = appTypeToLogInto ? appTypeToLogInto.type : null;
          // if (appTypeToLogInto == 'directorWebApp') {
          // force type to be user
          DeviceInfo.userType = 'User'
          // } else {
          // DeviceInfo.userType = DeviceInfo.userType
          // }

          dispatch(successHasDevice(DeviceInfo));
          resolve();
          // if(DeviceInfo.userType !== 'User'){
          //   setTimeout(()=>{
          //     if (appTypeToLogInto !== 'directorWebApp') {
          //       localStorage.setItem('LastType', JSON.stringify({type: 'admin'}))
          //     }

          //     keysStorage.Put({id: 'lastUser', key: {
          //       universalLogin: DeviceInfo.universalLogin,
          //       alias: loginReqest.alias,
          //       universalRedirect: DeviceInfo.universalRedirect,
          //       userType: DeviceInfo.userType,
          //       hasDevice: DeviceInfo.hasDevice && DeviceInfo.keys,
          //       hasIncompleteAnsaradaSignup: DeviceInfo.hasIncompleteAnsaradaSignup,
          //       canRegister: DeviceInfo.canRegister,
          //     }}).then(()=>{
          //       if (appTypeToLogInto !== 'directorWebApp') {
          //         window.location = '/admin.html'
          //       }
          //     });
          //   },100)
          //   return
          // }
          /*TODO add back in when Dan includes it
          if(!DeviceInfo.canRegister){
            dispatch(failureDevice());
            return;
          }*/

          if (DeviceInfo.universalRedirect && redirect && (DeviceInfo.hasDevice === true || DeviceInfo.hasDevice === false && DeviceInfo.requiresPassword === false)) {
            keysStorage.Put({
              id: 'lastUser', key: {
                universalLogin: DeviceInfo.universalLogin,
                alias: loginReqest.alias,
                universalRedirect: DeviceInfo.universalRedirect,
                userType: DeviceInfo.userType,
                hasDevice: DeviceInfo.hasDevice && DeviceInfo.keys,
                hasIncompleteAnsaradaSignup: DeviceInfo.hasIncompleteAnsaradaSignup,
                canRegister: DeviceInfo.canRegister,
              }
            }).then(() => {
              history.push(RoutesConstants.login_signin)
            });
          }
        })
        .catch((error) => {
          if (error === "H401") {
            dispatch(failureReg("H401"));
            dispatch(alertActions.error("H401"));
            reject();
            return
          }
          
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          reject();
        })
    });
  }

  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }

  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_CHECKED_SUCCESS, payload } }

  function request(username) { return { type: userConstants.LOGIN_CHECK, username } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function Auth0Logout(){
  return (dispatch) => {
    userService.Auth0Logout()
    .then(
      payload => {

      },
      error => {

      }
    )
  }
}


function Auth0Credential(profile, token){
  return async(dispatch, getState) => {
    const authentication = getState().authentication

    var KBDF2 = await CredentialsHash(authentication.username, profile)

    console.log("authentication")
    if(authentication.requiresPassword === false){
      if(authentication.hasDevice === true){
        dispatch(login({
          alias: authentication.alias,
          deviceHash: authentication.deviceHash,
          password: KBDF2,
          passwordHash: true,
        }));
      }else{
        userService.getRegistrationCode(token)
          .then(
            payload => {
              const user = {
                alias: authentication.alias,
                username: authentication.username,
                password: payload.registrationCode,
                newpassword: KBDF2,
                cardSerial: '',
                deviceId: authentication.deviceId,
                deviceHash: authentication.deviceHash,
              }

              userService.registerDevice(user)
                .then(
                  key => {
                    userService.registerUserDevice(user)
                      .then(
                        mfaId => {
                          const redirect = authentication.universalRedirect
                          dispatch(regDevice({
                            mfaId,
                            mfaType: mfaId !== ""?authentication.sms:"",
                            username: user.username,
                            alias: authentication.alias,
                            registrationCode: payload.registrationCode,
                            newpassword: KBDF2,
                            cardSerial: '',
                            deviceId: authentication.deviceId,
                            deviceHash: authentication.deviceHash,
                            mode: authentication.mode,
                            canRegister: authentication.canRegister,
                            requiresRecoveryCard: false,
                            universalLogin: authentication.universalLogin,
                            universalRedirect: authentication.universalRedirect,
                            usesMFA: authentication.usesMFA,
                            usesSSO: authentication.usesSSO,
                          }));
                         if(redirect)
                           history.push(RoutesConstants.login)
                        },
                        error => {
                          dispatch(failure(error, user.username));
                        }
                      );
                  },
                  error => {
                    dispatch(failure(error, user.username));
                  }
                );
            },
            error => {
              dispatch(failure(error));
            }
          )
      }
    }else if(authentication.requiresPassword === true){
      const redirect = authentication.universalRedirect
      dispatch(newUser({
        alias: authentication.alias,
        mode: authentication.mode,
        requiresPassword: authentication.requiresPassword,
        deviceId: authentication.deviceId,
        deviceHash: authentication.deviceHash,
        requiresRecoveryCard: false,
      }))
      if(redirect)
        history.push(RoutesConstants.login)
    }else{
      dispatch(login({
        alias: authentication.alias,
        deviceHash: authentication.deviceHash,
        password: KBDF2,
        passwordHash: true,
      }));
    }
  }
  function failure(error) { return { type: userConstants.LOGIN_AUTHZERO_FAILURE, error } }

  function regDevice(payload) { return { type: userConstants.REGISTER_USERANDDEVICE_AUTHZERO, payload } }
  function newUser(payload) { return { type: userConstants.REGISTER_USERANDDEVICE_NEWZERO, payload } }

  function success(payload) { return { type: userConstants.LOGIN_AUTHZERO_SUCCESS, payload } }
}

function Auth0Register(loginReqest){
  return dispatch => {
//    dispatch(request())
    userService.Auth0Login(loginReqest)
    .then(
      payload => {
        dispatch(Auth0Credential(payload.profileId, payload.token))
      },
      error => {
        dispatch(alertActions.recordDiagnosticData('Auth0Register', {
          error: JSON.stringify(error),
        }))
        //dispatch(failure(error));
        //dispatch(alertActions.error(error));
      }
    )
  }

//  function request() { return { type: userConstants.LOGIN_AUTHZERO_REQUEST } }
}

function Auth0CompleteDevice(alias, id, token){
  return dispatch => {
    DeviceFingerprint('login')
    .then((hash) => {
      checkUserDetails({
        alias: alias,
        deviceHash: hash,
        keys: true,
      })
      .then((DeviceInfo) => {
        keysStorage.Put({id: 'lastUser', key: {
          universalLogin: DeviceInfo.universalLogin,
          alias: alias,
          universalRedirect: DeviceInfo.universalRedirect,
          userType: DeviceInfo.userType,
          hasDevice: DeviceInfo.hasDevice && DeviceInfo.keys,
          hasIncompleteAnsaradaSignup: DeviceInfo.hasIncompleteAnsaradaSignup,
          canRegister: DeviceInfo.canRegister,
        }}).then(()=>{
          dispatch(successHasDevice(DeviceInfo));
          dispatch(Auth0Credential(id, token))
        });
      })
      .catch((error) => {
        if(error === "H401"){
          dispatch(failureReg("H401"));
          dispatch(alertActions.error("H401"));
          return
        }

        dispatch(failure(error));
        dispatch(alertActions.error(error));
      })
    })
    .catch(function(error) {
    });
  }

  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }
  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_CHECKED_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function Auth0Complete(auth){
  return (dispatch, getState) => {
    if(auth.error){
      dispatch(failure(auth.error))
      return
    }
    console.log("auth")
    const authentication = getState().authentication
    auth.getTokenSilently()
    .then(token => {
      console.log("token")
      if(auth.user.email !== authentication.alias){
        dispatch(failure('Username does not match', authentication.username));
      }

      dispatch(Auth0Credential(auth.user['https://platform.ansarada.com/claims/identity/profile/id'], token))
      // dispatch(success({
      //   token,
      //   profileId: auth.user['https://platform.ansarada.com/claims/identity/profile/id']
      // }))
    })
  }

  function failure(error) { return { type: userConstants.LOGIN_AUTHZERO_FAILURE, error } }
}

function Auth0Refresh(force = false){
  return (dispatch, getState) => {
    const authentication = getState().authentication
console.log("Auth0Refresh")
    if(authentication.keys !== undefined && !force)
      if(authentication.keys[authentication.customerId] !== undefined)
        if(authentication.keys[authentication.customerId].kUser !== undefined)
          return

    userService.Auth0Refresh()
    .then(
      async(userData) => {
        if(userData === undefined) return
        //const salt = authentication.username.toLowerCase()
        //const profileId = userData['https://platform.ansarada.com/claims/identity/profile/id'].toLowerCase()+salt

        var KBDF2 = await CredentialsHash(authentication.username, userData['https://platform.ansarada.com/claims/identity/profile/id'])
        //var KBDF2 = CrytpoLib.arrayBufferToBase64String(CrytpoLib.pbkdf2SHA512Hash(profileId, CrytpoLib.textToArrayBuffer(salt)))

        dispatch(loginWithOutRedirect({
          alias: authentication.alias,
          username: authentication.username,
          deviceId: authentication.deviceId,
          deviceHash: authentication.deviceHash,
          password: KBDF2,
          passwordHash: true,
        }));

      },
      error => {
        log("reject",error)
        dispatch(Auth0Logout());
      }
    ).catch(() => {
      dispatch(Auth0Logout());
    })
  }
}

function login(loginReqest) {
  return dispatch => {
    return new Promise((res, rej) => {

    window.demo = false
    dispatch(alertActions.clear());
    dispatch(request(loginReqest.alias));

    BlockLogout(false)
    if(loginReqest.alias === "demo" && loginReqest.password === "demo"){
      window.demo = true
      userService.loginDemo()
      .then(
        payload => {
          dispatch(success(payload));
          dispatch(loadDefault())
          history.push('/')
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )
      return
    }

   userService.hasAlias(loginReqest.alias)
   .then(
     aliasInfo => {
       let people = {}
       if (aliasInfo && aliasInfo.people && aliasInfo.people[0]) {
        people = aliasInfo.people[0];
      }
       if(loginReqest.username === undefined){
         if(aliasInfo.people === undefined || aliasInfo.people.length === 0){
           dispatch(failureReg("H401"));
           dispatch(alertActions.error("H401"));
           return
         }

         if(aliasInfo.people.length > 1){

         }

         loginReqest.username = aliasInfo.people[0].username
         people = aliasInfo.people[0]
       }

        userService.hasDevice(loginReqest.username)
          .then(
            async DeviceInfo => {
              if (DeviceInfo && DeviceInfo.mode === 5) {
                dispatch(popoverAction.showRedirectToNewWebsite(true));
                rej('');
                return;
              }
              if (DeviceInfo.isUserAndAdmin) {
                await isUserAndAdminPopover(dispatch, 'Director');
                localStorage.removeItem('user-admin-popover-selection');
              }
              DeviceInfo = Object.assign(DeviceInfo, {alias: loginReqest.alias, deviceHash: loginReqest.deviceHash, username: loginReqest.username, universalLogin: false, mode: people.mode, universalRedirect: false})
              if(people.usesMFA === true || people.usesSSO === true){
                loginReqest.universalLogin = true
                DeviceInfo.universalLogin = true
                if(people.usesSSO === true){
                  DeviceInfo.universalRedirect = true
                  loginReqest.universalRedirect = true
                }
              }
              //if(people.mode === 2)
             // loginReqest.universalLogin = true
             // DeviceInfo.universalLogin = true
             // loginReqest.universalRedirect = true
             // DeviceInfo.universalRedirect = true
              console.log('hasDevice');
              if(DeviceInfo.enabled === false && appConfig.ignoreMaintainence !== true){
                if(DeviceInfo.startTime !== undefined && DeviceInfo.startTime !== "" && DeviceInfo.endTime !== undefined && DeviceInfo.endTime !== ""){
                  if(moment().isAfter(moment(DeviceInfo.startTime)) && moment().isBefore(moment(DeviceInfo.endTime))){
                    DeviceInfo.loggingIn = false
                    DeviceInfo.loading = false
                    dispatch(successHasDevice(DeviceInfo));
                    return;
                  }
                }
              }
              if(!DeviceInfo.hasDevice){
                if(people.mode === 2 && !DeviceInfo.universalLogin && !people.requiresPassword){
                  DeviceInfo.requiresPassword = false
                  DeviceInfo.loggingIn = true
                  dispatch(successHasDevice(DeviceInfo));
                  dispatch(userActions.Auth0Register({
                    alias: loginReqest.alias,
                    username: loginReqest.username,
                    password: loginReqest.password,
                    newpassword: '',
                    cardSerial: '',
                    deviceId: DeviceInfo.deviceId,
                    deviceHash: loginReqest.deviceHash,
                  }))
                  return
                }
                dispatch(successHasDevice(DeviceInfo));
                return;
              }

              //TODO add back in if Once dan fixes it
              /*if( !DeviceInfo.canRegister){
                dispatch(failureDevice());
                return;
              }*/

              loginReqest.deviceId = DeviceInfo.deviceId
              loginReqest.mode = people.mode

              // var appTypeToLogInto = JSON.parse(localStorage.getItem("AppToLogInto"));
              // appTypeToLogInto = appTypeToLogInto ? appTypeToLogInto.type : null;
              // if (appTypeToLogInto == 'directorWebApp') {
              // force type to be user
                loginReqest.userType = 'User'
              // } else {
              // loginReqest.userType = DeviceInfo.userType
              // }

              loginReqest.passwordPolicy = DeviceInfo.passwordPolicy
              loginReqest.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex
              loginReqest.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription

              if(!DeviceInfo.keys){
                dispatch(requestReg(loginReqest.username, loginReqest.universalLogin?loginReqest.password:""));
                userService.registerDevice(loginReqest)
                  .then(
                    key => {
                      userService.registerUserDevice(loginReqest)
                        .then(
                          mfaData => {
                            DeviceInfo.mfaId = mfaData.mfaId
                            DeviceInfo.mfaType = getMfaType(mfaData.mfaMethod)
                            dispatch(successReg(DeviceInfo));
                          },
                          error => {
                            dispatch(failureReg(error));
                            dispatch(alertActions.error(error));
                          }
                        );
                    },
                    error => {
                      dispatch(failure(error));
                      dispatch(alertActions.error(error));
                    }
                  );
                return;
              }

              userService.login(loginReqest)
                .then(async payload => {
                    payload = Object.assign(payload, RecordHasDevice(DeviceInfo))
                    //Temp for Ansarada message
                    payload.mainLogin = true;

                    if (payload.showMigrationSuccess) {
                      dispatch({
                        type: kvpConstants.SET_KVP,
                        payload: {
                          key: 'migrateCredentialsSuccess',
                          value: payload.showMigrationSuccess
                        }
                      })
                    }

                    if(appConfig.recordDeviceFingerprint === true){
                      DeviceFingerprint('success')
                      .then((hash) => {
                        SettingStorage.Put({id: 'sucesshash', key: hash}).then(()=>{}).catch((e)=>{console.log(e);});
                      })
                      .catch(()=>{})
                    }

                    //Populate with base data
                    var redirect = false

                    try {
                      if (payload.loginCount == 1) {
                        try {
                          let aliasInfo = await userService.hasAlias(loginReqest.alias)
                          if (aliasInfo.people[0].usesSSO) {
                            payload.universalLogin = true;
                            payload.universalRedirect = true;
                            loginReqest.universalLogin = true;
                            loginReqest.universalRedirect = true;
                            DeviceInfo.universalLogin = true;
                            DeviceInfo.universalRedirect = true;
                          }
                        } catch { }
                      }
                    } catch { }

                    dispatch(success(payload));
                    dispatch(loadDefault());

                    checkFixingTask(dispatch, payload, loginReqest)
                    history.push('/');
                  },
                  error => {
                    if(error === 'register'){
                      console.log("Register")
                      //Need a delay of 3 seconds
                      setTimeout(() => {
                        //Device hash has changed do a new register
                        userService.resetKey(loginReqest.username)
                        .then(
                          newDeviceId => {
                            loginReqest.deviceId = newDeviceId;
                            dispatch(requestReg(loginReqest.username, loginReqest.universalLogin?loginReqest.password:""));
                            userService.registerDevice(loginReqest)
                              .then(
                                key => {
                                  userService.registerUserDevice(loginReqest)
                                    .then(
                                      mfaData => {
                                        DeviceInfo.mfaId = mfaData.mfaId
                                        DeviceInfo.mfaType = getMfaType(mfaData.mfaMethod)
                                        console.log('DeviceInfo')
                                        dispatch(successReg(DeviceInfo));
                                      },
                                      error => {
                                        dispatch(failureReg(error));
                                        dispatch(alertActions.error(error));
                                      }
                                    );
                                },
                                error => {
                                  dispatch(failure(error));
                                  dispatch(alertActions.error(error));
                                }
                              );
                          },
                          error => {
                            dispatch(failure(error));
                            dispatch(alertActions.error(error));
                          }
                        );
                      }, 500); //Give server time to ignore rate limiting
                      return;
                    }
                    if (error && error.resetPassword) {
                      dispatch(failure("Password is required"));
                      rej(error);
                      return;
                    }
                    if(error === 'maintenace'){
                      const payload = Object.assign(DeviceInfo, RecordHasDevice(DeviceInfo))
                      console.log('DeviceInfo')
                      dispatch(successHasDevice(payload));
                      return
                    }

                    let passwordLength = 'same'
                    if(loginReqest.passwordLength !== undefined){
                      if(loginReqest.passwordLength !== loginReqest.password.length){
                        passwordLength = "" + loginReqest.passwordLength + " - "+ loginReqest.password.length
                      }
                    }

                    dispatch(alertActions.recordDiagnosticData('LoginError', {
                      error: JSON.stringify(error),
                      passwordLength,
                    }))
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                  }
                );
            },
            error => {
              dispatch(alertActions.recordDiagnosticData('LoginHasDevice', {
                error: JSON.stringify(error),
              }))
              dispatch(failure(error));
              dispatch(alertActions.error(error));
            }
          )
      },
      error => {
        dispatch(alertActions.recordDiagnosticData('LoginhasAlias', {
          error: JSON.stringify(error),
        }))
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  });
  };

  function requestReg(username, password) { return { type: userConstants.REGISTER_USERDEVICE_REQUEST, username, password } }
  function successReg(payload, username, deviceId) { return { type: userConstants.REGISTER_USERDEVICE_SUCCESS, payload, username, deviceId } }
  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }

  function failureDevice() { return { type: userConstants.LOGIN_DEVICE_FAILURE } }

  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }

  function request(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
  function waiting(payload) { return { type: userConstants.LOGIN_WAITING_APPROVAL, payload } }
}

function loginClear(){
  return dispatch => {
    dispatch(succuss());
  }

  function succuss() { return { type: userConstants.LOGIN_CLEAR } }
}

function loginClearError(){
  return dispatch => {
    dispatch(succuss());
  }

  function succuss() { return { type: userConstants.LOGIN_CLEAR_ERROR } }
}

function logout(deviceId) {
  return (dispatch, getState) => {
    const { userId, mode } = getState().authentication
    var webSocket = getState().authentication.webSocket
    //trackRecord({event: "logoutSession"})
    var items = (window.dataAthenaLayer = window.dataAthenaLayer || []).slice(0)
    window.dataAthenaLayer = []
    items.forEach((d)=>{
      d.userId = userId
    })

    BlockLogout(true)
    dispatch(statusActions.uploadStatus())

    function redirect(){
      localStorage.removeItem('AppWeb');
      localStorage.removeItem('AthenaWeb');

      setTimeout(()=>{
        if(localStorage.getItem('AppWeb') !== null || localStorage.removeItem('AthenaWeb') !== null){
          localStorage.removeItem('AppWeb');
          localStorage.removeItem('AthenaWeb');
          setTimeout(()=>{
            if (window.location.origin.startsWith('app.')) {
              window.location = '/appuser.html';
            } else {
              window.location = '/admin.html';
            }
            window.location.reload(true);
          }, 1000)
        }else{
          window.location.reload(true);
        }
      },1000)
    }

    function out(){
      userService.logout(deviceId)
        .then(
          user => {
//            localStorage.removeItem('app');
            dispatch(success());
            if(mode === 2){
              dispatch(Auth0Logout())
            }

            if(mode === 5) {
              
            }

            redirect()
          },
          error => {
//            localStorage.removeItem('app');
            dispatch(failure());
            if(mode === 2){
              dispatch(Auth0Logout())
            }

            redirect()
          }
        );
    }
    if(items.length === 0) out()

    if(webSocket !== undefined && webSocket !== null && webSocket.close !== undefined){
      webSocket.close(4987)
    }
    dispatch(request());
    out()
  };

  function request() { return { type: userConstants.LOGOUT_REQUEST  } }
  function success() { return { type: userConstants.LOGOUT_SUCCESS  } }
  function failure() { return { type: userConstants.LOGOUT_SUCCESS } }
}

function registrationCompleted(){
  return dispatch =>{
    dispatch(succuss());
  }

  function succuss() { return { type: userConstants.REGISTER_NEW_USER_CCOMPLETED } }
}

function connectWebSocket(){
  return (dispatch, getState) => {
    function establishConnection(dispatch, getState){
      var sessionToken = getState().authentication.sessionToken
      var authentication = getState().authentication
      if(getState().authentication.lockScreen) return
      userService.connectWebSocket(sessionToken)
        .then(
          payload => {
            dispatch(success(payload));

            payload.onclose = function(e) {
                if(e !== undefined && e.code !== undefined && e.code === 4987){
                  return
                }
                console.log('echo-protocol Client Closed');
                establishConnection(dispatch, getState)
            };
            payload.onmessage = function(e) {
              console.log("WEBSOCKET onmessage");
              if (typeof e.data === 'string') {
                // console.log("Received: '" + e.data + "'");
                try{
                  const r = JSON.parse(e.data)

                  try {
                    if (r.newCustomer && page == 'appuser') {
                      dispatch(boardActions.previewBoardHome());
                    }
                  } catch { }
                  if (r.annotationsShared && r.annotationsShared.documentId) {
                    dispatch(baseBackupActions.getSharedAnnotations(r.annotationsShared.documentId, true));
                  }
                  if(r.binderAddition !== undefined){
                    dispatch(binderAddition(r.binderAddition))
                  }
                  if(r.binderRemoval !== undefined){
                    dispatch(binderRemoval(r.binderRemoval.boardId, r.binderRemoval.binderId))
                  }
                  if(r.binderUpdate !== undefined){
                    dispatch(binderUpdate(r.binderUpdate))
                  }
                  if(r.boardFileUpdate !== undefined){
                    dispatch(boardActions.populateFiles(r.boardFileUpdate.boardId))
                  }
                  if(r.sessionReplaced !== undefined || r.sessionTerminated){
                    if (r.sessionTerminated) {
                      LogoutAndRedirect();
                      return;
                    }
                    BlockLogout(true);
                    dispatch(sessionReplace())
                    setTimeout(() => {
                      BlockLogout(false);
                      LogoutAndRedirect()
                    }, SESSIONREPLACEMENTTIMEOUT)
                    return
                  }

                  if (r.circularResolutionUpdate !== undefined) {
                    if (window.CRUpdateNotifShown) { return; }
                    window.CRUpdateNotifShown = true;
                    setTimeout(() => {
                      window.CRUpdateNotifShown = false;
                    }, 5000);
                    dispatch(resolutionsActions.getCircularResolutionResolution(r.circularResolutionUpdate.circularResolutionId, r.circularResolutionUpdate.customerId, r.circularResolutionUpdate.boardId, false, true));
                  }

                  if (r.circularResolutionAddition && r.circularResolutionAddition.circularResolutionId) {
                    dispatch(resolutionsActions.getCircularResolutionResolution(r.circularResolutionAddition.circularResolutionId, r.circularResolutionAddition.customerId, r.circularResolutionAddition.boardId, false, true));
                  }

                  if (r.circularResolutionRemoval) {
                    dispatch(resolutionsActions.removeCircularResolutionFromRedux(r.circularResolutionRemoval.circularResolutionId, r.circularResolutionRemoval.boardId));
                  }

                  if(r.binderAddition !== undefined || r.binderUpdate !== undefined){
                    const binderId = r.binderAddition !== undefined? r.binderAddition.binderId : r.binderUpdate.binderId
                    var binders = getState().binder
                    const binder = binders[binderId]
                    if(binder !== undefined && binder.hasPopulated === true){
                      dispatch(binderActions.populateBinderContent(binderId))
                    }
                  }

                  if (r.formAddition) {
                    try {
                      const bc = new BroadcastChannel(BroadcastChannelName.Forms.name);
                      bc.postMessage({ message: BroadcastChannelName.Forms.messages.FormAddition, details: r });
                      bc.close();
                    } catch { }
                  }
                  if (r.formRemoval) {
                    try {
                      const bc = new BroadcastChannel(BroadcastChannelName.Forms.name);
                      bc.postMessage({ message: BroadcastChannelName.Forms.messages.FormRemoval, details: r });
                      bc.close();
                    } catch { }
                  }
                  if (r.formUpdate) {
                    try {
                      const bc = new BroadcastChannel(BroadcastChannelName.Forms.name);
                      bc.postMessage({ message: BroadcastChannelName.Forms.messages.FormUpdated, details: r });
                      bc.close();
                    } catch { }
                  }

                  if (r.type == "sync") {
                    if (r && authentication && r.deviceId == authentication.deviceId) { return; }
                    const bc = new BroadcastChannel(BroadcastChannelName.AnnotationAdded);
                    setTimeout(() => {
                      bc.postMessage({ message: BroadcastChannelName.AnnotationAdded, details: r });
                      bc.close();
                    }, 10000);
                  }

                  if(r.alert !== undefined){
                    dispatch(alertActions.notificationSuccess({
                      id: uuidv4(),
                      name: r.alert.message,
                      style: webConstants.MESSAGE,
                    }))
                  }
                }catch(e){
                  console.log("websocket error",e)
                }
              }
            };
          },
          error => {
            // dispatch(alertActions.errorDiagnosticData(
            //   new CodeError("WebSockets",'801', "socket error", error, error.toString())
            // ))
            dispatch(failure());
          }
        );

    }
    establishConnection(dispatch, getState)

  };

  function success(payload) { return { type: userConstants.LOGIN_WEBSOCKET_SUCCESS, payload  } }
  function failure() { return { type: userConstants.LOGIN_WEBSOCKET_FAILURE } }

  function binderAddition(binder) { return { type: binderConstants.BINDER_ADDITION_SUCCESS, binder }}
  function binderUpdate(binder) { return { type: binderConstants.BINDER_UPDATE_SUCCESS, binder }}
  function binderRemoval(boardId, binderId) { return { type: binderConstants.BINDER_REMOVAL_SUCCESS, boardId, binderId }}

  function sessionReplace() { return { type: userConstants.SESSION_REPLACEMENT } }
}

function hasDevice(username){
  return dispatch => {
    dispatch(request());

    userService.hasDevice(username)
      .then(
        payload => {
          payload.username = username
          dispatch(success(payload));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.HAS_DEVICE_NOTFY_REQUEST } }
  function success(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.HAS_DEVICE_NOTFY_FAILURE, error } }
}

function checkAlias(username, redirect = null) {
  return dispatch => {
    return new Promise((resolve, reject) => {

      dispatch(request());

      userService.hasAlias(username)
        .then(
          aliasInfo => {
            if (aliasInfo.people === undefined || aliasInfo.people.length === 0) {
              dispatch(failureReg("H401"));
              dispatch(alertActions.error("H401"));
              reject();
              return
            }

            // if (aliasInfo.people.length > 1) {

            // }

            const mode = aliasInfo.people[0].mode;
            const uName = aliasInfo.people[0].username
            const people = aliasInfo.people[0]

            userService.hasDevice(uName)
              .then(
                payload => {
                  payload = Object.assign(payload, people, { alias: username, universalLogin: false, username: uName, universalRedirect: false, mode: mode })
                  if (people.usesSSO === true || people.usesMFA === true) {
                    payload.universalLogin = true
                    if (people.usesSSO === true) {
                      people.universalRedirect = true
                    }
                  }

                  if (!payload.canRegister) {
                    dispatch(failureDevice());
                    reject();
                    return;
                  }

                  dispatch(success(payload));
                  resolve(payload);
                },
                error => {
                  dispatch(failure(error));
                  dispatch(alertActions.error(error));
                  reject();
                }
              );
          },
          error => {
            dispatch(failure("H401"));
            reject();
            dispatch(alertActions.error(error));
          }
        );
    })
  };

  function request() { return { type: userConstants.HAS_DEVICE_NOTFY_REQUEST } }
  function success(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.HAS_DEVICE_NOTFY_FAILURE, error } }

  function failureDevice() { return { type: userConstants.LOGIN_DEVICE_FAILURE } }
}

function registerUserDeviceAll(user){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(user.username));

    userService.hasDevice(user.username)
      .then(
        DeviceInfo => {
          /*TODO check with Dan want to include this
          if(!DeviceInfo.canRegister){
            var error = 'Unable to register this device';
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            return;
          }
          */

          userService.registerDevice(user)
            .then(
              key => {
                userService.registerUserDevice(user)
                  .then(
                    data => {
                      dispatch(success(DeviceInfo, user.username));
                    },
                    error => {
                      dispatch(failure(error));
                      dispatch(alertActions.error(error));
                    }
                  );
              },
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
          }
        );
  };

  function request(username) { return { type: userConstants.REGISTER_USERDEVICE_REQUEST, username } }
  function success(payload, username) { return { type: userConstants.REGISTER_USERDEVICE_POLICY_SUCCESS, payload, username } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }
}

function registerUserDevicePage(user){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(user.username));

    userService.registerDevice(user)
      .then(
        key => {
          userService.registerUserDevice(user)
            .then(
              mfaData => {
                mfaData.mfaType = getMfaType(mfaData.mfaMethod)
                dispatch(success(mfaData, user.username));
              },
              error => {
                dispatch(failure(error, user.username));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          dispatch(failure(error, user.username));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(username) { return { type: userConstants.REGISTER_USERANDDEVICE_REQUEST, username } }
  function success(mfaData, username) { return { type: userConstants.REGISTER_USERANDDEVICE_SUCCESS, mfaData, username } }
  function failure(error, username) { return { type: userConstants.REGISTER_USERANDDEVICE_FAILURE, error, username } }
}

function registerUserDeviceLogin(loginReqest){
  return async(dispatch) => {
    dispatch(alertActions.clear());
console.log("registerUserDeviceLogin")
    dispatch(request(loginReqest.username));

    userService.hasDevice(loginReqest.username)
      .then(
        DeviceInfo => {
          DeviceInfo.username = loginReqest.username
          console.log('hasDevice2');
          if(!DeviceInfo.hasDevice){
            dispatch(successHasDevice(DeviceInfo));
            return;
          }

          /*TODO check with Dan want to include this
          if(!DeviceInfo.canRegister){
            var error = 'Unable to register this device';
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            return;
          }*/

          loginReqest.deviceId = DeviceInfo.deviceId

          loginReqest.passwordPolicy = DeviceInfo.passwordPolicy
          loginReqest.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex
          loginReqest.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription

          userService.registerUserDeviceWithKey(loginReqest)
            .then(
              data => {
                dispatch(success());

                dispatch(requestLog(loginReqest.username));

                dispatch(requestLog(loginReqest));
                userService.login(loginReqest)
                  .then(
                    payload => {
                      payload = Object.assign(payload, RecordHasDevice(DeviceInfo))
                      
                      if (payload.showMigrationSuccess) {
                        dispatch({
                          type: kvpConstants.SET_KVP,
                          payload: {
                            key: 'migrateCredentialsSuccess',
                            value: payload.showMigrationSuccess
                          }
                        })
                      }

                      dispatch(successLog(payload));
                      if (payload.pendingInvites && payload.pendingInvites.length && payload.pendingInvites.length > 0) {
                        dispatch(autoInvite(payload, loginReqest));
                      }
                      
                      //if(payload.hasOwnProperty('customerIds')){
                      dispatch(loadDefault());
                      history.push('/');
                      
                      /*TODO need to work out how to detect user registration
                        }else{
                          dispatch(waiting(payload));
                        }
                      */
                    },
                    error => {
                      if (error && error.resetPassword) {
                        dispatch(failureLog("Password reset is required"));
                        return
                      }
                      dispatch(failureLog(error));
                      dispatch(alertActions.error(error));
                    }
                  );
              },
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function requestLog(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function successLog(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failureLog(error) { return { type: userConstants.LOGIN_FAILURE, error } }

  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }

  function request(loginReqest) { return { type: userConstants.REGISTER_USERDEVICEKEY_REQUEST, loginReqest } }
  function success() { return { type: userConstants.REGISTER_USERDEVICEKEY_SUCCESS } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICEKEY_FAILURE, error } }
  function waiting(payload) { return { type: userConstants.LOGIN_WAITING_APPROVAL, payload } }
}

function registerUserDeviceWithKey(loginReqest){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(loginReqest));

    userService.registerUserDeviceWithKey(loginReqest)
      .then(
        data => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(loginReqest) { return { type: userConstants.REGISTER_USERDEVICEKEY_REQUEST, loginReqest } }
  function success() { return { type: userConstants.REGISTER_USERDEVICEKEY_SUCCESS } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICEKEY_FAILURE, error } }
}

function registerNewUser(newitem){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(newitem.username, customerId));
    dispatch(alertActions.clear());
    console.log('registerNewUser');
    userService.registerUserDeviceWithKey(newitem)
      .then(
        async(serialKeys) => {
          newitem.unregisteredUserType = serialKeys.unregisteredUserType;
          newitem.customerName = serialKeys.unregisteredUserCustomerName;
          if(serialKeys.unregisteredUserKAthenaAdmin !== undefined && serialKeys.unregisteredUserKAthenaAdmin !== "")
            newitem.unregisteredUserKAthenaAdmin = serialKeys.unregisteredUserKAthenaAdmin;
          if(serialKeys.unregisteredUserAuth0ProfileId !== undefined && serialKeys.unregisteredUserAuth0ProfileId !== ""){
            var KBDF2 = await CredentialsHash(newitem.username, serialKeys.unregisteredUserAuth0ProfileId)

            newitem.auth0Password = newitem.newpassword
            newitem.newpassword = KBDF2
          } else if (serialKeys.unregisteredUserCognitoSecret) {
            var KBDF2 = await CredentialsHash(newitem.username, serialKeys.unregisteredUserCognitoSecret)
            newitem.cognitoPassword = newitem.newpassword
            newitem.newpassword = KBDF2
          }

          userService.registerNewUser(newitem)
            .then(
              payload => {
                dispatch(success(newitem.username, customerId));
                keysStorage.Put({id: 'lastUser', key: {
                  alias: newitem.alias,
                  universalLogin: newitem.universalLogin,
                  universalRedirect: newitem.universalRedirect,
                  userType: "User",
                  hasDevice: true,
                  hasIncompleteAnsaradaSignup: false,
                  canRegister: true
                }}).then(()=>{});
              },
              error => {
                dispatch(failure(error, newitem.username, customerId));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error, newitem.username, customerId));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(username, customerId) { return { type: userConstants.REGISTER_NEW_USER_REQUEST, username, customerId } }
  function success(username, customerId) { return { type: userConstants.REGISTER_NEW_USER_SUCCESS, username, customerId } }
  function failure(error, username, customerId) { return { type: userConstants.REGISTER_NEW_USER_FAILURE, error, username, customerId } }
}

function registerNewUserLogin(regReqest){
  return dispatch => {
    dispatch(request());
    dispatch(login(regReqest));
  };

  function request() { return { type: userConstants.REGISTER_NEW_USER_CLEAR } }
}

function forgotNC(userId){
  return dispatch => {
    return new Promise((resolve, reject) => {
    dispatch(alertActions.clear());
    dispatch(request());

    userService.forgotNC(userId)
      .then(
        mfaId => { dispatch(success(mfaId)); resolve(); },
        error => {
          dispatch(failure(error));
          reject();
        }
      );
    });
  };

  function request() { return { type: userConstants.FORGOT_NOCODE_NOTFY_REQUEST } }
  function success(mfaId) { return { type: userConstants.FORGOT_NOCODE_NOTFY_SUCCESS, mfaId } }
  function failure(error) { return { type: userConstants.FORGOT_NOCODE_NOTFY_FAILURE, error } }
}

function forgotNewPass(newitem){
  return (dispatch, getState) => {
    dispatch(request());

    const mode = getState().authentication.mode


    userService.forgotNCCode(newitem)
      .then(
        item => {
          //dispatch(success(item))
          newitem.sessionToken = item.sessionToken
          newitem.kPlatform = item.kPlatform
          newitem.kPlatformType = item.kPlatformType
          newitem.secretariatKeys = item.secretariatKeys
          
          if(mode === 2 || mode === 5){
            newitem.mode = mode
            newitem.universalLogin = getState().authentication.universalLogin
            newitem.universalRedirect = getState().authentication.universalRedirect
            newitem.alias = getState().authentication.alias
            newitem.newPassword = newitem.password;
            
            var resetApi = null;
            switch(mode){
              case 2: resetApi = userService.changePasswordAuth0; break;
              case 5: resetApi = userService.changePasswordCognito; break;
            }
            if (!resetApi) {
              dispatch(failure('Error resetting password'));
              dispatch(alertActions.error('Error resetting password'));
              return;
            }
            resetApi(newitem)
              .then(
                item => dispatch(success(item)),
                error => {
                  dispatch(failure(error));
                  dispatch(alertActions.error(error));
                }
              );
            return;
          }

          userService.forgotNewPass(newitem)
            .then(
              item => dispatch(success(item)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_NOCODE_NEWPASS_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_NOCODE_NEWPASS_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_NOCODE_NEWPASS_FAILURE, error } }
}

function forgotWCard(newitem){
  return dispatch => {
    dispatch(request());
    userService.forgotWCard(newitem)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_CARD_NOTFY_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_CARD_NOTFY_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_CARD_NOTFY_FAILURE, error } }
}

function forgotWCardNewPass(newitem){
  return dispatch => {
    dispatch(request());
    userService.forgotWCardNewPass(newitem)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_CARD_NEWPASS_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_CARD_NEWPASS_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_CARD_NEWPASS_FAILURE, error } }
}

function clearAuthCode(){
  return dispatch => {
    dispatch(request());
  };

  function request() { return { type: userConstants.CLEAR_AUTH_CODE_REQUEST } }
}

function clearErrorMsg(id = 0){
  return (dispatch, getState) => {
    var customerId = getState().authentication.customerId
    dispatch(request(id, customerId));
    dispatch(alertActions.clear());
  };

  function request(id, customerId) { return { type: userConstants.CLEAR_ERROR_MSG, id, customerId } }
}

function getCurrentUserIds() {
  return (dispatch, getState) => {
    try { return getState().authentication.userIds; } catch { return []; }
  }
}

function getFirstSortedCustomerId() {
  return (dispatch, getState) => {
    let state = getState();
    var currentUserIds = dispatch(userActions.getCurrentUserIds());
    return Object.keys(state.authentication.keys)
      .filter(k => {
        let c = state.authentication.customers.find(c => c.id == k);
        return Boolean(c && currentUserIds.includes(c.userId));
      })
      .sort()[0];
  }
}

function sendResetLink(alias){
  return dispatch => {
    dispatch(request(alias));
    userService.sendResetLink(alias)
    .then(
      () => {
        dispatch(success(alias))
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(alias) { return { type: userConstants.AUTHZERO_RESET_REQUEST, alias } }
  function success(alias) { return { type: userConstants.AUTHZERO_RESET_SUCCESS, alias } }
  function failure(error) { return { type: userConstants.AUTHZERO_RESET_FAILURE, error } }
}

function keepAlive(){
  return dispatch => {
    dispatch(request())
    checkForUpdate()
    .then((updateAvailable)=>{
      if(updateAvailable){
        dispatch(requestUpdate(updateAvailable));
      }
    })
    userService.keepAlive()
    .then(
      payload => {
      },
      error => {
      }
    );
  };

  function request() { return { type: userConstants.KEEP_SESSION_ALIVE } }
  function requestUpdate(status) { return { type: userConstants.WEBSITE_UPDATE_AVAILABLE, status } }
}

function lockScreen(){
  return dispatch => {
    dispatch(request());
  };

  function request() { return { type: userConstants.KILL_SESSION_ALIVE } }
}

function activeScreen(){
  return dispatch => {
    dispatch(request());
  };

  function request() { return { type: userConstants.ACTIVE_SESSION_ALIVE } }
}

function loginWithOutRedirect(loginReqest){
  return (dispatch, getState) => {
console.log("loginReqest2")
    dispatch(request(loginReqest.username));
    userService.hasDevice(loginReqest.username)
      .then(
        DeviceInfo => {
          if(!DeviceInfo.hasDevice){
            return;
          }

          loginReqest.passwordPolicy = DeviceInfo.passwordPolicy
          loginReqest.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex
          loginReqest.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription

          userService.login(loginReqest)
            .then(
              payload => {
                const authentication = getState().authentication
                if(authentication.universalLogin) payload.universalLogin = authentication.universalLogin
                if(authentication.universalRedirect) payload.universalRedirect = authentication.universalRedirect

                payload = Object.assign(payload, RecordHasDevice(DeviceInfo))

                dispatch(success(payload));
                dispatch(loadDefault());

                if (payload.pendingInvites && payload.pendingInvites.length && payload.pendingInvites.length > 0) {
                  dispatch(autoInvite(payload, loginReqest));
                }
              },
              error => {
                if(error === 'maintenace'){
                  dispatch(logout(loginReqest.deviceId));
                  if(RoutesConstants.login !== window.location.pathname)
                    window.location.reload(true);
                  return
                }
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          //No has device, which should always work, so logout and refresh the page
          dispatch(logout(loginReqest.deviceId));
          if(RoutesConstants.login !== window.location.pathname)
            window.location.reload(true);
        }
      );
  };

  function request(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function loginLockScreen(loginReqest){
  return dispatch => {
    dispatch(request(loginReqest.username));
    userService.hasDevice(loginReqest.username)
      .then(
        DeviceInfo => {
          if(!DeviceInfo.hasDevice){
            return;
          }

          loginReqest.passwordPolicy = DeviceInfo.passwordPolicy
          loginReqest.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex
          loginReqest.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription

          userService.login(loginReqest)
            .then(
              payload => {
                payload = Object.assign(payload, RecordHasDevice(DeviceInfo))

                dispatch(success(payload));
                dispatch(loadDefault());
                //TODO trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "loginLockScreen"))
              },
              error => {
                if(error === 'maintenace'){
                  dispatch(logout(loginReqest.deviceId));
                  if(RoutesConstants.login !== window.location.pathname)
                    window.location.reload(true);
                  return
                }
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          //No has device, which should always work, so logout and refresh the page
          dispatch(failure(error));
        }
      );
  };

  function request(username) { return { type: userConstants.LOGIN_LOCK_REQUEST, username } }
  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_LOCK_FAILURE, error } }
}

function getUsers(){
  return (dispatch, getState) => {
    dispatch(request());

    userService.getUsers()
      .then(
        list => {
          dispatch(success(list));
        },
        error => {
          dispatch(failure(error));
        }
      );

  };

  function request() { return { type: userConstants.GET_USERS_REQUEST } }
  function success(list) { return { type: userConstants.GET_USERS_SUCCESS, list } }
  function failure(error) { return { type: userConstants.GET_USERS_FAILURE, error } }
}

function changePassword(loginReqest){
  return (dispatch, getState) => {
    //const customerId = getState().authentication.customerId

    const mode = getState().authentication.mode

    if(mode === 2 || mode === 5){
      loginReqest.mode = mode
      loginReqest.universalLogin = getState().authentication.universalLogin
      loginReqest.universalRedirect = getState().authentication.universalRedirect
      loginReqest.alias = getState().authentication.alias
    }

    dispatch(request());
    userService.hasDevice(loginReqest.username)
      .then(
        DeviceInfo => {
          if(!DeviceInfo.hasDevice){
            return;
          }

          loginReqest.passwordPolicy = DeviceInfo.passwordPolicy
          loginReqest.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex
          loginReqest.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription
          
          userService.login(loginReqest)
            .then(
              payload => {
                payload = Object.assign(payload, RecordHasDevice(DeviceInfo))

                if(payload.keys !== undefined)
                  loginReqest.keys = payload.keys;

                loginReqest.customerIds = payload.customers.map(o => o.id)
                
                if (mode == 2 || mode === 5) {
                  var resetApi = null;
                  switch (mode) {
                    case 2: resetApi = userService.changePasswordAuth0; break;
                    case 5: resetApi = userService.changePasswordCognito; break;
                  }
                  if (!resetApi) {
                    dispatch(failure('Error resetting password'));
                    dispatch(alertActions.error('Error resetting password'));
                    return;
                  }
                  resetApi(loginReqest)
                    .then(
                      item => {
                        //new sessionToken update
                        dispatch(success())
                        for (var key in payload.keys) {
                          delete payload.keys[key].kUserSensitive
                          delete payload.keys[key].kUserPem
                        }

                        payload.deviceId = DeviceInfo.deviceId
                        payload.mode = mode;
                        payload.userType = DeviceInfo.userType

                        payload.passwordPolicy = DeviceInfo.passwordPolicy
                        payload.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex
                        payload.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription

                        dispatch(successLogin(payload));
                        //TODO trackRecord(getDeviceDetails(loginReqest.customerIds[0], payload.userIds[0], "changePassword"))
                      },
                      error => {
                        dispatch(failure(error));
                      }
                    );
                }else{
                  userService.changePassword(loginReqest)
                    .then(
                      item => {
                        //new sessionToken update
                        dispatch(success())
                        for(var key in payload.keys){
                          delete payload.keys[key].kUserSensitive
                          delete payload.keys[key].kUserPem
                        }

                        payload.deviceId = DeviceInfo.deviceId
                        payload.mode = mode;
                        payload.userType = DeviceInfo.userType

                        payload.passwordPolicy = DeviceInfo.passwordPolicy
                        payload.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex
                        payload.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription

                        dispatch(successLogin(payload));
                        //TODO trackRecord(getDeviceDetails(loginReqest.customerIds[0], payload.userIds[0], "changePassword"))
                      },
                      error => {
                        dispatch(failure(error));
                      }
                    );
                }
              },
              error => {
                dispatch(failure(error));
                //dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          //No has device, which should always work, so logout and refresh the page
          dispatch(failure(error));
        }
      );
  };

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function request() { return { type: userConstants.CHANGE_PASSWORD_REQUEST } }
  function success() { return { type: userConstants.CHANGE_PASSWORD_SUCCESS } }
  function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function clearPasswordLock(){
  return dispatch => {
    dispatch(success());
  };

  function success() { return { type: userConstants.CHANGE_PASSWORD_CLEAR } }
}

function initialisePassChange(){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(success(customerId));
  };

  function success(customerId) { return { type: userConstants.CHANGE_PASSWORD_INTIALISE, customerId } }
}

function getPasswordPolicy(){
  return (dispatch, getState) => {
    const username = getState().authentication.username
    dispatch(request());

    userService.hasDevice(username)
      .then(
        payload => {
          dispatch(success(payload));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.GET_PASSWORDPOLICY_REQUEST } }
  function success(payload) { return { type: userConstants.GET_PASSWORDPOLICY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.GET_PASSWORDPOLICY_FAILURE, error } }
}

function sendMFA(mfa, type){
  return dispatch => {
    userService.sendMFA(mfa, type)
    .then(
      code => {
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  };
}

function setKeys(keys){
  return dispatch => {
    dispatch(success(keys));
  };

  function success(keys) { return { type: userConstants.SET_KEYS, keys } }
}

function impersonateUser(userId){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    const keys = getState().authentication.keys
    let kUser = null

    if(customerId !== undefined && customerId !== ""){
      if(keys[customerId] !== undefined && keys[customerId].kUser !== undefined){
        kUser = keys[customerId].kUser
      }
    }

    dispatch(request(userId));
    userService.impersonateUser(userId, kUser)
      .then(
        userKeys => {
          dispatch(success(userKeys));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(userId) { return { type: userConstants.GET_IMPERSONATE_REQUEST, userId } }
  function success(payload) { return { type: userConstants.GET_IMPERSONATE_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.GET_IMPERSONATE_FAILURE, error } }
}

function autoInvite(userItem, loginReqest){
  return (dispatch, getState) => {
    let customerId = getState().authentication.customerId
    var initial = {
      password: loginReqest.password,
      invites: [],
      username: userItem.username,
      keys: userItem.keys,
      ignoreTask: false,
    }
    userItem.pendingInvites.forEach(o => {
      if(o.autoAccept){
        o.result = true;
        initial.invites.push(o)
      }
    })
// console.log("AAAAAA",userItem, loginReqest,initial)
// return
    if(initial.invites.length === 0) return
    dispatch(request(customerId))
    userService.getMyRecoveryCards()
    .then(
      recoveryCards => {
        dispatch(update(1, customerId))
        initial.serialKeys = recoveryCards
        userService.processInvite(initial)
          .then(
            payload => {
              dispatch(update(2, customerId))
              customerId = getState().authentication.customerId
              //We need to confirm the password is correct
              userService.login(loginReqest)
                .then(
                  logindetails => {
                    dispatch(update(3, customerId))
                    logindetails.lcustomerId = customerId
                    dispatch(successLogin(logindetails));
                    dispatch(success(initial.invites))
                  },
                  error => {
                    dispatch(failure(error, customerId))
                  }
                );
            },
            error => {
              log(error);
              dispatch(failure(error, customerId))
            }
          );
      },
      error => {
        log(error);
        dispatch(failure(error, customerId))
      }
    );
  }

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function request(customerId) { return { type: userConstants.AUTO_INVITE_REQUEST, customerId } }
  function update(value, customerId) { return { type: userConstants.AUTO_INVITE_UPDATE, value } }
  function success(list) { return { type: userConstants.AUTO_INVITE_SUCCESS, list } }
  function failure(error, customerId) { return { type: userConstants.AUTO_INVITE_FAILURE, error, customerId } }
}

function clearInvite(){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(success(customerId))
  };

  function success(customerId) { return { type: userConstants.CLEAR_CONFIRM_INVITE_SUCESS, customerId } }
}

function processInvite(userItems){
  return (dispatch, getState) => {
    userItems.keys = getState().authentication.keys;
    userItems.username = getState().authentication.username;
    const customerId = getState().authentication.customerId
    function runProcess(ut){
      userService.processInvite(ut)
        .then(
          payload => {
            //todo cognito
            //We need to confirm the password is correct
            if(ut.mode === 2){
              ut.passwordAuth0 = ut.password
              ut.password = ut.auth0Password
            }
            userService.login(ut)
              .then(
                logindetails => {
                  dispatch(successLogin(logindetails))
                  dispatch(success(customerId))
                },
                error => {
                  dispatch(failure(error, customerId))
                }
              );
          },
          error => {
            dispatch(failure(error, customerId))
          }
        );
    }

    dispatch(request(customerId))
    userService.getMyRecoveryCards()
    .then(
      recoveryCards => {
        userItems.serialKeys = recoveryCards
        
        //todo cognito
        //Are we Alias Company if so Get the profile id
        if(userItems.mode === 2){
          userService.Auth0Login(userItems)
          .then(
            async(auth0) => {
              var KBDF2 = await CredentialsHash(userItems.username, auth0.profileId)

              userItems.auth0Password = userItems.password
              userItems.password = KBDF2

              runProcess(userItems);
            },
            error => {
              dispatch(failure(error, customerId))
            }
          );
        }else{
          runProcess(userItems)
        }
      },
      error => {
        dispatch(failure(error, customerId))
      }
    );

  };

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function request(customerId) { return { type: userConstants.MANUAL_CONFIRM_INVITE_REQUEST, customerId } }
  function success(customerId) { return { type: userConstants.MANUAL_CONFIRM_INVITE_SUCCESS, customerId } }
  function failure(error, customerId) { return { type: userConstants.MANUAL_CONFIRM_INVITE_FAILURE, error, customerId } }
}
