import React from 'react';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Portal, Snackbar} from "@mui/material";
import { MuiButton, DemoPopulateCountDown } from '@common/MUI';
import { popoverConstants } from "../../constants/admin";
import { v4 as uuidv4 } from 'uuid';
import { PDFViewer } from '../../pages/common/pdfViewer';

export const popoverAction = {
    add,
    remove,
    clearAll,
    showError,
    showMessage,
    showDialog,
    showDemoDataPopulateDialog,
    showPDF,
    showToast,
    removeToast,
    showRedirectToNewWebsite
}

function showRedirectToNewWebsite(isAppLogin = false) {
    return dispatch => {
        const newUrl = isAppLogin ? 'https://app.athenaboard.com' : 'https://admin.athenaboard.com';
        dispatch(showDialog({
            title: 'Important Notice',
            content: <div>
                <div>We have moved to a new website.</div>
                <div>Please log in at <a href={newUrl}>{newUrl}</a> to continue using Athena Board (formerly Ansarada Board).</div>
            </div>
        }));
    }
}

// messages = string Array
function showToast(type, id, messages = [], onClose = () => { }, isPermanant = false) {
    return (dispatch) => {
        let toastId = id || uuidv4();
        var popover = {
            id: toastId,
            el: <Paper key={toastId} elevation={5}>
                <Alert isPermanant={isPermanant} onClose={() => { if (onClose) { onClose(); } dispatch(removeToast(toastId)) }} severity={type || "success"} sx={{ width: '100%' }}>{messages.map(m => <div>{m}</div>)}</Alert>
            </Paper>
        }
        dispatch({ type: popoverConstants.ADD_TOAST, payload: popover });
    }
}
function removeToast(id) {
    return (dispatch, getState) => {
        dispatch({ type: popoverConstants.REMOVE_TOAST, payload: id });
    }
}

function showPDF(file, onClose) {
    return (dispatch) => {
        var popoverId = 'show-pdf-popover';
        dispatch(remove(popoverId));
        var popover = {
            id: popoverId,
            el: <Portal>
                <PDFViewer
                    zIndex={2000}
                    initial={0}
                    files={[file]}
                    onClose={() => { dispatch(remove(popoverId)); if (onClose) { onClose(); } }}
                    onRetrive={() => { }}
                />
            </Portal>
        }
        dispatch({ type: popoverConstants.ADD_POPOVER, payload: popover });
    }
}

function showError(errorDetails) {
    return (dispatch, getState) => {
        var popoverId = uuidv4();
        var popover = {
            id: errorDetails.id || popoverId,
            el:
                <Dialog key={popoverId} open={true} maxWidth='sm' fullWidth={true}>
                    <DialogTitle sx={{ color: 'red', fontWeight: '700' }}>{errorDetails.title}</DialogTitle>
                    <DialogContent>{errorDetails.body}</DialogContent>
                    <DialogActions>
                        <MuiButton variant='contained' onClick={() => {
                            dispatch(remove(popoverId));
                            if (errorDetails.onClose) {
                                errorDetails.onClose()
                            }
                        }}>Close</MuiButton>
                    </DialogActions>
                </Dialog>
        }

        dispatch({ type: popoverConstants.ADD_POPOVER, payload: popover });
    }
}

function showMessage(messageDetails, dialogId = "") {
    return (dispatch, getState) => {
        if (dialogId) { dispatch(remove(dialogId)); }
        var popoverId = dialogId || uuidv4();
        var popover = {
            id: popoverId,
            el:
                <Dialog key={popoverId} open={true} maxWidth='sm' fullWidth={true}>
                    <DialogTitle sx={{ fontWeight: messageDetails.boldTitle ? '700' : '400' }}>{messageDetails.title}</DialogTitle>
                    <DialogContent>{messageDetails.body}</DialogContent>
                    <DialogActions>
                        <MuiButton variant='contained' onClick={() => {
                            dispatch(remove(popoverId));
                            if (messageDetails.onClose) {
                                messageDetails.onClose()
                            }
                        }}>Close</MuiButton>
                    </DialogActions>
                </Dialog>
        }

        dispatch({ type: popoverConstants.ADD_POPOVER, payload: popover });
    } 
}
// dialogDetails = {
// dialogId: string (unique),
// width: string 
// title: JSX 
// content : JSX 
// dialogActions : JSX 
// }
function showDialog(dialogDetails) {
    return (dispatch, getState) => {
        var popover = {
            id: dialogDetails.dialogId,
            el:
                <Dialog key={dialogDetails.dialogId} open={true} maxWidth={dialogDetails.width || 'sm'} fullWidth={true}>
                    <DialogTitle>{dialogDetails.title}</DialogTitle>
                    <DialogContent>{dialogDetails.content}</DialogContent>
                    <DialogActions>{dialogDetails.dialogActions}</DialogActions>
                </Dialog>
        }

        dispatch({ type: popoverConstants.ADD_POPOVER, payload: popover });
    }
}

function add(popover) {
    return (dispatch, getState) => {
        dispatch({ type: popoverConstants.ADD_POPOVER, payload: popover });
    }
}

function remove(id) {
    return (dispatch, getState) => {
        dispatch({ type: popoverConstants.REMOVE_POPOVER, payload: id});
    }
}

function clearAll() {
    return (dispatch, getState) =>{ 
        dispatch({ type: popoverConstants.CLEAR_ALL, payload: null })
    }
}

function showDemoDataPopulateDialog(seconds = 600, reset = false) {
    return dispatch => {
        dispatch(showDialog({
            dialogId: 'sample_demo_is_loading_popover',
            title: `Demonstration data is ${reset ? 'resetting' : 'populating'}`,
            content: <DemoPopulateCountDown seconds={seconds} isReset={reset}/>,
            dialogActions: <div></div>
        }));
    }
}