import React from 'react';
import { MuiButton } from '../../pages/common/MUI';
import { userConstants, boardConstants, fileConstants, binderConstants, minutesConstants, webConstants } from '@constants/admin';
import { userService, fileService, boardService, alertService, adminPermissionsService } from '@services/admin';
import { alertActions, fileActions, binderActions, customerActions, companyActions, boardActions, minutesActions, kvpActions, popoverAction, resolutionsActions, adminPermissionsActions } from './';
import { boardActions as appUserBoardActions } from '../appuser';
import { userActions as userAppActions } from '../appuser/user.actions';
import { GetURL, history, SettingStorage, checkDemo, CodeError, BlockLogout, LogoutAndRedirect } from '@lib';
import {keysStorage} from '@lib/indexeddb';
import { CheckInternet, checkForUpdate, trackRecord, DeviceFingerprint, CredentialsHash, handle206, TrackEvent, BLANK_GUID } from '@lib/simpletools';
import RSACrypto, * as CrytpoLib from '@lib/cryptojs';
import { v4 as uuidv4 } from 'uuid';
import { SESSIONREPLACEMENTTIMEOUT } from '@lib/limits';
import moment from 'moment';
import { UserTypeEnum, BinderStatus, UserAccountTask, RoutesConstants } from '@constants/common.constants';

import { ErrorType } from '../../lib/auth-header';
import IconBinder from '@image/icon/binder.svg';
import IconBoard from '@image/icon/placeholder-board.svg';

import WorkerPool from '@worker/workerpool';
import { AppType, BroadcastChannelName } from '../../constants';
import { AdminMembershipType } from '../../reducers/admin/adminPermissions.reducer';
import { baseContributorActions } from '../contributor.base.actions';
import { resolutionsConstants } from '../../reducers/admin/resolutions.reducer';
import { baseUserService } from '../../services/user.base.service';
import { userService as appUserService } from '../../services/appuser/user.service';
import { userNameOrder } from '../../lib';
import { bulkUploadActions } from './bulkupload.actions';
import _ from 'lodash';
import { SmartlookWrapper } from '../../lib/smartlookFunctions';
import { Stack } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { userBaseActions } from '../user.base.actions';
let bgAdminTask = require("worker-loader?name=admin.worker.js!../../pages/common/adminTask.js");

export const userActions = {
    getAllUsersWithMembershipsToBoard,
    doesUserHaveMembershipToBoard,
    getUserById,
    checkUser,
    login,
    logout,
    loginWithOutRedirect,
    loginLockScreen,
    loginClear,
    loginClearError,
    directSignup,
    inviteCosecViaDirector,
    deleteTrailEmailCheckData,
    trailCheckEmail,
    trailSaveCoSecDetails,
    getTrailNewUserInfo,
    completeSignUp,
    completeExistingUserSignUp,
    registerExistingUser,
    firstLoginClear,
    connectWebSocket,
    Auth0Login,
    Auth0Logout,
    Auth0Credential,
    Auth0Register,
    Auth0Complete,
    Auth0CompleteDevice,
    Auth0Refresh,
    hasDevice,
    checkAlias,
    registerDevice,
    registerUserDevice,
    registerUserDeviceLogin,
    registerUserDeviceAll,
    registerUserDevicePage,
    registerUserDeviceWithKey,
    registerNewUser,
    registerNewUserLogin,
    registerRecoveryCard,
    sendWelcomeEmail,
    regenerateWelMsg,
    regeneratePdf,
    regenerateClear,
    getListofUsers,
    getMyUsers,
    populateUsers,
    populateListofUsers,
    checkEmailDomainMatchesCompanyDomains,
    deleteUser,
    deleteMultiUser,
    simpleUserUpdate,
    updateUser,
    updateAdminUser,
    getAvailableNames,
    checkAliasName,
    importNewUser,
    inviteUser,
    inviteMultiUser,
    approveNewAdminMaster,
    clearCheckInvite,
    confirmCheckInvite,
    checkNewUser,
    newUser,
    getUserDetails,
    getUserPublicKey,
    //forgotAskForLogin,
    forgotNC,
    forgotNCCode,
    forgotNewPass,
    forgotWCard,
    forgotWCardNewPass,
    changePassword,
    clearPasswordLock,
    initialisePassChange,
    clearAuthCode,
    clearErrorMsg,
    getListofPendingKey,
    approveUserNewPass,
    approveUserNewPassGroup,
    approveNewAdminUser,
    approveNewUser,
    getUserSerialCards,
    getUserDevices,
    getCustomerCards,
    removeUserSerialCard,
    removeCustomerSerialCard,
    clearNewTask,
    getAllTask,
    getTask,
    updateTask,
    completedTask,
    completedTaskGroup,
    lockTask,
    keepAlive,
    lockScreen,
    regUserSignCert,
    sendFeedBack,
    sendBugCrash,
    sendErrorBack,
    getGenKey,
    getGenPrivKey,
    groupAdd,
    groupDelete,
    groupGet,
    groupGetAll,
    groupUpdate,
    updateDisplaySettings,
    checkRecoverCard,
    loadCustomer,
    lastCustomer,
    autoInvite,
    clearInvite,
    processInvite,
    //autoGenSecUpgrade,
    autoGenSecRegister,
    //autoGenSecPopulate,
    autoGenSecComplete,
    //autoAdminKeySetup,
    //autoFixImpersonation,
    autoFixTask,
    getMFACode,
    hasLockPass,
    pairLockPass,
    sendMFA,
    sendResetLink,
    getTermsAndCondition,
    setKeys,
    getUserAnalytics,
    changeRoleType,
    deleteUserSettings,
    updateUserSettings,
    getFullUserName,
};

function getFullUserName(userId, returnAsObject = false) {
  return (dispatch, getState) => {
    var fullName = '';
    try {
      var user = { ...getState().users.data[userId] };
      if (!user) { return ''; }
      var firstNameOrder = true;
      try {
        firstNameOrder = getState().authentication.displaySettings.userSort == false ? false : true
      } catch { }
      if (user.firstName || user.lastName) {
        fullName = userNameOrder(user.firstName, user.lastName, firstNameOrder);
      }
    } catch { }
    if (returnAsObject) {
      return { firstName: user.firstName, lastName: user.lastName, email: user.email }
    }
    return fullName;
  }
}

function getUserById(userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!userId || userId == BLANK_GUID) { reject(); return; }
      baseUserService.getUserById(userId).then((response) => {
        dispatch({ type: userConstants.GETUSER_SUCCESS, item: response });
        resolve(response);
      })
        .catch(() => {
          reject();
        })
    });
  }
}

function getDeviceDetails(customerId, userId, event = "loginSession"){
  return {
    event,
    userId,
    customerId,
    screen: {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      devicePixelRatio: window.devicePixelRatio,
      availWidth: window.screen.availWidth,
      availHeight: window.screen.availHeight,
    },
    navigator: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      appVersion: navigator.appVersion,
      vendor: navigator.vendor,
      opera: window.opera
    }
  }
}

function checkRecoverCard(username){
  return (dispatch) => {
    var k = username+"-recovery"
    SettingStorage.Get(k)
    .then((d)=>{
      var i = d.key-1
      if(i >= 0){
        SettingStorage.Put({id: k, key: i}).then(()=>{}).catch((e)=>{});
      }
    }).catch((e)=>{
      if(e === "pending"){
        setTimeout(()=>{
          dispatch(checkRecoverCard(username))
        }, 1000)
      }
    })
  }
}

function lastCustomer(payload, type, redirect = true){
  return (dispatch, getState) => {
    const auth = getState().authentication
    var customerId = ""
    if(auth.customerId !== undefined && auth.customerId !== ""){
      customerId = auth.customerId
    }else{
      let lastCache = JSON.parse(localStorage.getItem('offline'));
      if(lastCache === null) lastCache = JSON.parse(localStorage.getItem('offline'));
      var userCache = JSON.parse(localStorage.getItem(window.athenaAppID));

      if(auth.lcustomerId !== undefined && auth.lcustomerId !== ""){
        customerId = auth.lcustomerId
      }else if(lastCache && lastCache.customerId !== undefined && lastCache.customerId !== ""){
        customerId = lastCache.customerId
        if(!payload.customerIds.includes(customerId))
          customerId = payload.customerIds[0];
      }else if(userCache && userCache.customerId !== undefined && userCache.customerId !== ""){
        customerId = userCache.customerId
      }else{
        customerId = payload.customerIds[0];
        payload.customers.some((o) => {
          if(o.parentCompanyId === undefined){
            customerId = o.id
            return true
          }
          return false;
        })
      }
    }

    payload.customerId = customerId
    dispatch(success(payload))
    //trackRecord(getDeviceDetails(customerId, auth.userId, type))
    if(redirect) history.push('/')
    dispatch(loadCustomer(customerId))
  }

  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
}

function loadCustomer(customerId, switchBoard = false){
  return dispatch => {
    if (!window.demo) {
      try {
        if (window.athenaAppID == 'AppWeb') {
          dispatch(userAppActions.connectWebSocket());
        } else {
          dispatch(connectWebSocket());
        }
      } catch { }
    }
    if(customerId === undefined) return
    //cache the boards, user, binders, templates
    dispatch(binderActions.CacheBinderTemplatePreview(customerId))
    dispatch(customerActions.getCustomerSettings(customerId))
      .then((payload) => {
        if (!payload || !window.freemiumCompanylogo) { return; }
        const newPayload = { ...payload };
        Object.keys(payload).forEach(key => {
          if (!newPayload[key]) {
            delete newPayload[key];
          }
        });
        newPayload.logoImageId = "";
        if (window.freemiumCompanylogo.indexOf('data:') === 0) {
          var block = window.freemiumCompanylogo.split(";");
          // Get the content type of the image
          var contentType = block[0].split(":")[1];// In this case "image/gif"
          // get the real base64 content of the file
          var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1]);
          //Update the new image
          newPayload.image = { type: contentType, data: realData };
        }
        newPayload.boards = [];
        newPayload.customerSettingsIds = [];
        newPayload.customerIds = [newPayload.customerId];
        dispatch(customerActions.setCustomerSettings(newPayload)).then(() => {
          window.freemiumCompanylogo = undefined;
          dispatch(customerActions.getCustomerSettings(customerId));
        });
      });
    dispatch(companyActions.getCompanyProperties(customerId))
    dispatch(populateListofUsers(customerId));
    dispatch(getListofUsers(customerId));
    dispatch(populateUsers(customerId));
    dispatch(boardActions.getBoards(customerId));
    dispatch(boardActions.getBoardsPreview()).then(() => {
      if(!switchBoard){
        dispatch(boardActions.getCurrentBoard())
      }
    });

    dispatch(companyActions.getCompanyUserLimits());
    dispatch(customerActions.getAllLocks(customerId));
    dispatch(getGenPrivKey(customerId));

    dispatch(getGenKey(customerId))
    dispatch(getMyUsers())
    //dispatch(populateListofUsers(customerId));
    dispatch(getAllTask())
  }
}

function checkFixingTask(dispatch, payload, loginReqest){
  if(payload.pendingInvites !== undefined){
    dispatch(autoInvite(payload, loginReqest))
  }
  if(payload.genSecSetupsRequired !== undefined){
    //dispatch(autoGenSecUpgrade(payload, loginReqest))
    dispatch(autoFixTask(loginReqest, "AdminGenSecImpersonationsSetup"))
  }
  if(payload.genSecImpersonationsRequired !== undefined){
    dispatch(autoGenSecRegister(loginReqest))
  }
  if(payload.genSecDataRequired !== undefined){
    //dispatch(autoGenSecPopulate(payload, loginReqest))
    dispatch(autoFixTask(loginReqest, "AdminGenSecDataSetup"))
  }
  if(payload.completedInvites !== undefined){
    dispatch(autoGenSecComplete(payload, loginReqest))
  }
  if(payload.athenaAdminKeySetupRequiredCustomerIds !== undefined){
    //dispatch(autoAdminKeySetup(payload, loginReqest))
    dispatch(autoFixTask(loginReqest, "AthenaAdminKeySetup"))
  }
  if(payload.FixImpersonationByGenSec !== undefined){
    //dispatch(autoFixImpersonation(loginReqest))
    dispatch(autoFixTask(loginReqest, "FixImpersonationByGenSec"))
  }
  if(payload.fixLockPassImpersonationCustomerIds !== undefined){
    dispatch(autoFixTask(loginReqest, "FixLockPassImpersonation"))
  }
}

function checkUserDetails(loginReqest){
  return new Promise((resolve, reject) => {
    userService.hasAlias(loginReqest.alias)
    .then(
      aliasInfo => {
        let people = {}
        if (aliasInfo && aliasInfo.people && aliasInfo.people[0]) {
          people = aliasInfo.people[0];
        }
        if(loginReqest.username === undefined){
          if(aliasInfo.people === undefined || aliasInfo.people.length === 0){
            reject("H401")
            return
          }

          if(aliasInfo.people.length > 1){

          }

          loginReqest.username = aliasInfo.people[0].username
          people = aliasInfo.people[0]
        }

        userService.hasDevice(loginReqest.username)
           .then(
             async DeviceInfo => {
               //DeviceInfo.username = loginReqest.username

               DeviceInfo = Object.assign(DeviceInfo, people, {alias: loginReqest.alias, deviceHash: loginReqest.deviceHash, universalLogin: false, universalRedirect: false})
               if(people.usesSSO === true || people.usesMFA === true){
                 DeviceInfo.universalLogin = true
                 if(people.usesSSO === true) DeviceInfo.universalRedirect = true
               }

               if(people.hasIncompleteAnsaradaSignup === true){
                 reject("Your Ansarada registration is not yet complete, please complete this before attempting board registration")
                 return
               }

               // if(people.mode === 2 && !DeviceInfo.universalLogin){
               //
               //   return
               // }
               //if(people.mode === 2)
              // DeviceInfo.universalLogin = true
              // DeviceInfo.universalRedirect = true

               log('hasDevice');
               resolve(DeviceInfo)
             },
             error => {
               reject(error)
             }
           )
       },
       error => {
         reject(error)
       }
    )
  })
}

function checkUser(loginReqest, redirect = false){
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      window.demo = false
      dispatch(alertActions.clear());
      dispatch(request(loginReqest.alias));

      if (loginReqest.alias === "demo") {
        window.demo = true
        dispatch(successHasDevice({
          canRegister: true,
          canResetPasssword: false,
          canResetPasswordWithAdmin: false,
          deviceId: "e55f7963-153d-4b9f-8844-b74ed37316a1",
          hasDevice: true,
          keys: true,
          passwordPolicyDescription: "",
          passwordPolicyRegex: "",
          pns: false,
          recoveryCardCount: 0,
          requiresRecoveryCard: false,
          sms: false,
          mode: 1,
          username: "demo",
          alias: "demo",
        }));
        /*userService.loginDemo()
        .then(
          payload => {
            dispatch(successLogin(payload));
            dispatch(loadCustomer(payload.customerIds[0]))
            history.push('/')
          },
          error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        )*/
        return
      }

      //clear session cookies
      localStorage.removeItem('AppWeb');
      localStorage.removeItem('AthenaWeb');

      checkUserDetails(loginReqest)
        .then(async (DeviceInfo) => {
          if (DeviceInfo && DeviceInfo.mode === 5) {
            dispatch(popoverAction.showRedirectToNewWebsite());
            reject('');
            return;
          }
          if (DeviceInfo.isUserAndAdmin) {
            await isUserAndAdminPopover(dispatch);
          }
          // DeviceInfo.mode = 5;
          // DeviceInfo.usesSSO = true;
          // DeviceInfo.universalRedirect = true;
          // DeviceInfo.requiresPassword = false;
          dispatch(successHasDevice(DeviceInfo));

          if (DeviceInfo.userType === 'User') {
            dispatch(showDirectorWebAppRedirectPopup());
            dispatch(failure(""));
            //   setTimeout(()=>{
            //     localStorage.setItem('LastType', JSON.stringify({type: 'app'}))
            //     keysStorage.Put({id: 'lastUser', key: {
            //       universalLogin: DeviceInfo.universalLogin,
            //       alias: loginReqest.alias,
            //       isUserAndAdmin: DeviceInfo.isUserAndAdmin,
            //       universalRedirect: DeviceInfo.universalRedirect,
            //       userType: DeviceInfo.userType,
            //       hasDevice: DeviceInfo.hasDevice && DeviceInfo.keys,
            //       hasIncompleteAnsaradaSignup: DeviceInfo.hasIncompleteAnsaradaSignup,
            //       canRegister: DeviceInfo.canRegister,
            //     }}).then(()=>{
            //       window.location = '/appuser.html'
            //     });
            //   },100)
            return
          }

          if (DeviceInfo.passwordResetRequired) {
            resolve(DeviceInfo);
            return;
          }

          /*TODO add back on once dan one
          if(!DeviceInfo.canRegister){
            dispatch(failureDevice());
            return;
          }*/

          if (DeviceInfo.universalRedirect && redirect && (DeviceInfo.hasDevice === true || DeviceInfo.hasDevice === false && DeviceInfo.requiresPassword === false)) {
            keysStorage.Put({
              id: 'lastUser', key: {
                universalLogin: DeviceInfo.universalLogin,
                alias: loginReqest.alias,
                universalRedirect: DeviceInfo.universalRedirect,
                userType: DeviceInfo.userType,
                hasDevice: DeviceInfo.hasDevice && DeviceInfo.keys,
                hasIncompleteAnsaradaSignup: DeviceInfo.hasIncompleteAnsaradaSignup,
                canRegister: DeviceInfo.canRegister,
              }
            }).then(() => {
              if (DeviceInfo.mode === 2) {
                history.push(RoutesConstants.login_signin);
              }
              if (DeviceInfo.mode === 5) {
                var ssoData = localStorage.getItem('BoardToken');
                if (ssoData) {
                  try {
                    ssoData = JSON.parse(ssoData);
                    userBaseActions.AWSCognitoRefreshToken(DeviceInfo)
                      .then(async response => {
                        const authentication = getState().authentication;
                        var KBDF2 = await CredentialsHash(authentication.username, response['cognito:username']);
                        //var KBDF2 = CrytpoLib.arrayBufferToBase64String(CrytpoLib.pbkdf2SHA512Hash(profileId, CrytpoLib.textToArrayBuffer(salt)))
                        dispatch(loginWithOutRedirect({
                          alias: authentication.alias,
                          username: authentication.username,
                          deviceId: authentication.deviceId,
                          deviceHash: authentication.deviceHash,
                          password: KBDF2,
                          passwordHash: true,
                        }));
                      }, (reason) => {
                        userBaseActions.AWSCognitoSSO();
                        console.log('refresh failed');
                      });
                  } catch { localStorage.clear('BoardToken'); }
                } else {
                  userBaseActions.AWSCognitoSSO();
                }
                return;
              }
            });
          }
        })
        .catch((error) => {
          if (error === "H401") {
            dispatch(failureReg("H401"));
            dispatch(alertActions.error("H401"));
            return
          }

          dispatch(failure(error));
          dispatch(alertActions.error(error));
        })
    })
  }

  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }

  function failureDevice() { return { type: userConstants.LOGIN_DEVICE_FAILURE } }

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_CHECKED_SUCCESS, payload } }

  function request(username) { return { type: userConstants.LOGIN_CHECK, username } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function Auth0Login(loginReqest){
  return dispatch => {
    dispatch(request(loginReqest.username))
    userService.Auth0Login(loginReqest)
      .then(
        payload => {
          dispatch(success(payload));
        },
        error => {
          dispatch(failure(error));
        }
      )
  }

  function request(username) { return { type: userConstants.LOGIN_AUTHZERO_REQUEST, username } }
  function success(payload) { return { type: userConstants.LOGIN_AUTHZERO_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_AUTHZERO_FAILURE, error } }
}

function Auth0Logout(){
  return (dispatch) => {
    userService.Auth0Logout()
    .then(
      payload => {

      },
      error => {

      }
    )
  }
}

function Auth0Credential(profile, token){
  return async(dispatch, getState) => {
    const authentication = getState().authentication

    var KBDF2 = await CredentialsHash(authentication.username, profile)

    devlog("authentication", authentication)
    if(authentication.requiresPassword === false){
      if(authentication.hasDevice === true){
        dispatch(login({
          alias: authentication.alias,
          deviceHash: authentication.deviceHash,
          password: KBDF2,
          passwordHash: true,
        }));
      }else{
        userService.getRegistrationCode(token)
          .then(
            payload => {
              const user = {
                alias: authentication.alias,
                username: authentication.username,
                password: payload.registrationCode,
                newpassword: KBDF2,
                cardSerial: '',
                deviceId: authentication.deviceId,
                deviceHash: authentication.deviceHash,
              }

              userService.registerDevice(user)
                .then(
                  key => {
                    userService.registerUserDevice(user)
                      .then(
                        mfaId => {
                          const redirect = authentication.universalRedirect
                          dispatch(regDevice({
                            mfaId,
                            mfaType: mfaId !== ""?authentication.sms:"",
                            username: user.username,
                            alias: authentication.alias,
                            registrationCode: payload.registrationCode,
                            newpassword: KBDF2,
                            cardSerial: '',
                            deviceId: authentication.deviceId,
                            deviceHash: authentication.deviceHash,
                            mode: authentication.mode,
                            canRegister: authentication.canRegister,
                            requiresRecoveryCard: false,
                            universalLogin: authentication.universalLogin,
                            universalRedirect: authentication.universalRedirect,
                            usesMFA: authentication.usesMFA,
                            usesSSO: authentication.usesSSO,
                          }));
                         if(redirect)
                           history.push(RoutesConstants.login)
                        },
                        error => {
                          dispatch(failure(error, user.username));
                        }
                      );
                  },
                  error => {
                    dispatch(failure(error, user.username));
                  }
                );
            },
            error => {
              dispatch(failure(error));
            }
          )
      }
    }else if(authentication.requiresPassword === true){
      const redirect = authentication.universalRedirect
      dispatch(newUser({
        alias: authentication.alias,
        mode: authentication.mode,
        requiresPassword: authentication.requiresPassword,
        deviceId: authentication.deviceId,
        deviceHash: authentication.deviceHash,
        requiresRecoveryCard: false,
      }))
      if(redirect)
        history.push(RoutesConstants.login)
    }else{
      dispatch(login({
        alias: authentication.alias,
        deviceHash: authentication.deviceHash,
        password: KBDF2,
        passwordHash: true,
      }));
    }
  }
  function failure(error) { return { type: userConstants.LOGIN_AUTHZERO_FAILURE, error } }

  function regDevice(payload) { return { type: userConstants.REGISTER_USERANDDEVICE_AUTHZERO, payload } }
  function newUser(payload) { return { type: userConstants.REGISTER_USERANDDEVICE_NEWZERO, payload } }

  function success(payload) { return { type: userConstants.LOGIN_AUTHZERO_SUCCESS, payload } }
}

function Auth0Register(loginReqest){
  return dispatch => {
    dispatch(request())
    userService.Auth0Login(loginReqest)
    .then(
      payload => {
        dispatch(Auth0Credential(payload.profileId, payload.token))
      },
      error => {
        dispatch(alertActions.recordDiagnosticData('Auth0Register', {
          error: JSON.stringify(error),
        }))
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    )
  }

  function request() { return { type: userConstants.LOGIN_AUTHZERO_REQUEST } }

  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function Auth0CompleteDevice(alias, id, token){
  return dispatch => {
    DeviceFingerprint('login')
    .then((hash) => {
      checkUserDetails({
        alias: alias,
        deviceHash: hash,
        keys: true,
      })
      .then((DeviceInfo) => {
        keysStorage.Put({id: 'lastUser', key: {
          universalLogin: DeviceInfo.universalLogin,
          alias: alias,
          universalRedirect: DeviceInfo.universalRedirect,
          userType: DeviceInfo.userType,
          hasDevice: DeviceInfo.hasDevice && DeviceInfo.keys,
          hasIncompleteAnsaradaSignup: DeviceInfo.hasIncompleteAnsaradaSignup,
          canRegister: DeviceInfo.canRegister,
        }}).then(()=>{
          dispatch(successHasDevice(DeviceInfo));
          dispatch(Auth0Credential(id, token))
        });
      })
      .catch((error) => {
        if(error === "H401"){
          dispatch(failureReg("H401"));
          dispatch(alertActions.error("H401"));
          return
        }

        dispatch(failure(error));
        dispatch(alertActions.error(error));
      })
    })
    .catch(function(error) {
    });
  }

  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }
  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_CHECKED_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function Auth0Complete(auth){
  return (dispatch, getState) => {
    if(auth.error){
      dispatch(failure(auth.error))
      return
    }
    devlog("auth", auth)
    const authentication = getState().authentication
    auth.getTokenSilently()
    .then(token => {
      devlog("token",auth, token)
      if(auth.user.email !== authentication.alias){
        dispatch(failure('Username does not match', authentication.username));
      }

      dispatch(Auth0Credential(auth.user['https://platform.ansarada.com/claims/identity/profile/id'], token))
      // dispatch(success({
      //   token,
      //   profileId: auth.user['https://platform.ansarada.com/claims/identity/profile/id']
      // }))
    })
  }

  function failure(error) { return { type: userConstants.LOGIN_AUTHZERO_FAILURE, error } }
}

function Auth0Refresh(force = false){
  return (dispatch, getState) => {
    const authentication = getState().authentication
log("Auth0Refresh")
    if(authentication.keys !== undefined && !force)
      if(authentication.keys[authentication.customerId] !== undefined)
        if(authentication.keys[authentication.customerId].kUser !== undefined)
          return

    userService.Auth0Refresh()
    .then(
      async(userData) => {
        if(userData === undefined) return
        //const salt = authentication.username.toLowerCase()
        //const profileId = userData['https://platform.ansarada.com/claims/identity/profile/id'].toLowerCase()+salt

        var KBDF2 = await CredentialsHash(authentication.username, userData['https://platform.ansarada.com/claims/identity/profile/id'])
        //var KBDF2 = CrytpoLib.arrayBufferToBase64String(CrytpoLib.pbkdf2SHA512Hash(profileId, CrytpoLib.textToArrayBuffer(salt)))

        dispatch(loginWithOutRedirect({
          alias: authentication.alias,
          username: authentication.username,
          deviceId: authentication.deviceId,
          deviceHash: authentication.deviceHash,
          password: KBDF2,
          passwordHash: true,
        }));

      },
      error => {
        log("reject",error)
        dispatch(Auth0Logout());
      }
    ).catch(() => {
      dispatch(Auth0Logout());
    })
  }
}

function RecordHasDevice(DeviceInfo){
  let payload = {}
  if(DeviceInfo.passwordPolicyDescription !== undefined)
    payload.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription;
  if(DeviceInfo.passwordPolicyRegex !== undefined)
    payload.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex;
  if(DeviceInfo.passwordPolicy !== undefined)
    payload.passwordPolicy = DeviceInfo.passwordPolicy;
  if(DeviceInfo.sms !== undefined)
    payload.sms = DeviceInfo.sms;
  if(DeviceInfo.startTime !== undefined){
    payload.maintenance = {
      enabled: DeviceInfo.enabled,
      endTime: DeviceInfo.endTime,
      startTime: DeviceInfo.startTime,
      statusMessage: DeviceInfo.statusMessage,
    }
  }

  return payload
}

export async function isUserAndAdminPopover (dispatch, app) {
  var hasSelected = localStorage.getItem('user-admin-popover-selection');
  if (hasSelected) { return Promise.resolve(); }
  return await new Promise((resolve, reject) => {
    const popoverId = 'is-admin-and-user-apptype-selection-popover';
    dispatch(popoverAction.showDialog({
      width: 'sm',
      dialogId: popoverId,
      title: null,
      content:
        <div>
          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '28px', paddingBottom: '20px' }}>What would you like to do?</div>
          <div className='adminuser-select-button-wrapper'>
            <div className='adminuser-select-button adminuser-select-button-admin-view' onClick={() => {
              localStorage.setItem('user-admin-popover-selection', 'set');
              dispatch(popoverAction.remove(popoverId));
              if (app == 'Director') {
                localStorage.removeItem('LastType');
                // localStorage.removeItem('AppToLogInto');
                window.location.reload();
                return;
              }
              resolve();
            }}>
              <div className='adminuser-select-button-inner-wrapper'>
                <img style={{ width: '50px' }} src={IconBinder} />
                <div style={{ fontWeight: 'bold' }}>Administrator portal</div>
                <div>Manage my board papers and users</div>
              </div>
            </div>

            <div className='adminuser-select-button adminuser-select-button-director-view' onClick={() => {
              localStorage.setItem('user-admin-popover-selection', 'set');
              // localStorage.setItem('AppToLogInto', JSON.stringify({ type: AppType.directorWebApp }));
              localStorage.setItem('LastType', JSON.stringify({ type: 'app' }))
              dispatch(popoverAction.remove(popoverId));
              if (app !== 'Director') {
                window.location = '/appuser.html';
                return;
              }
              resolve();
            }}>
              <div className='adminuser-select-button-inner-wrapper'>
                <img style={{ width: '50px', height: '65px', filter: 'brightness(0.5)' }} src={IconBoard} />
                <div style={{ fontWeight: 'bold' }}>Director Portal</div>
                <div>View my board documents</div>
              </div>
            </div>
          </div>
        </div>
      ,
      dialogActions: null,
    }))
  });
}

function showDirectorWebAppRedirectPopup() {
  return dispatch => {
    dispatch(popoverAction.showDialog({
      dialogId: 'app-user-redirect-message',
      width: 'sm',
      title: 'Redirect to the Director web app?',
      content: <div>
        <div>
          <div>It looks like you're trying to access the Director web app.</div>
          <div>You cannot log in to the admin portal as a user.</div>
          <div>Would you like to be redirected to the admin portal?</div>
        </div>
      </div>,
      dialogActions: <Stack direction='row' spacing={2}>
        <MuiButton variant='outlined' type='red' onClick={() => { dispatch(popoverAction.remove('app-user-redirect-message')); }}>Cancel</MuiButton>
        <MuiButton variant='contained' onClick={() => { window.location = '/appuser.html'; }}>Yes</MuiButton>
      </Stack>
    }));
  }
}

function login(loginReqest) {
  return dispatch => {
    return new Promise((res, rej) => {
    window.demo = false
    dispatch(alertActions.clear());
    dispatch(request(loginReqest.alias));

    console.log(loginReqest);

    BlockLogout(false)
    if(loginReqest.alias === "demo" && loginReqest.password === "demo"){
      window.demo = true
      userService.loginDemo()
      .then(
        payload => {
          dispatch(success(payload));
          dispatch(loadCustomer(payload.customerIds[0]))
          history.push('/')
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )
      return
    }

   userService.hasAlias(loginReqest.alias)
   .then(
     aliasInfo => {
       let people = {}
       if (aliasInfo && aliasInfo.people && aliasInfo.people[0]) {
         people = aliasInfo.people[0];
       }
       if(loginReqest.username === undefined){
         if(aliasInfo.people === undefined || aliasInfo.people.length === 0){
           dispatch(failureReg("H401"));
           dispatch(alertActions.error("H401"));
           return
         }

         if(aliasInfo.people.length > 1){

         }

         loginReqest.username = aliasInfo.people[0].username
         people = aliasInfo.people[0];
        }

        userService.hasDevice(loginReqest.username)
          .then(
            async DeviceInfo => {
              if (DeviceInfo && DeviceInfo.mode === 5) {
                dispatch(popoverAction.showRedirectToNewWebsite());
                rej('');
                return;
              }

              if (DeviceInfo.isUserAndAdmin) {
                await isUserAndAdminPopover(dispatch);
                localStorage.removeItem('user-admin-popover-selection');
              }
              if(DeviceInfo.userType === 'User'){
                dispatch(showDirectorWebAppRedirectPopup());
                dispatch(failure(''));
                return;
                // keysStorage.Put({id: 'lastUser', key: {
                //   universalLogin: DeviceInfo.universalLogin,
                //   alias: loginReqest.alias,
                //   isUserAndAdmin: DeviceInfo.isUserAndAdmin,
                //   universalRedirect: DeviceInfo.universalRedirect,
                //   userType: DeviceInfo.userType,
                //   hasDevice: DeviceInfo.hasDevice && DeviceInfo.keys,
                //   hasIncompleteAnsaradaSignup: DeviceInfo.hasIncompleteAnsaradaSignup,
                //   canRegister: DeviceInfo.canRegister,
                // }}).then(()=>{
                //   window.location = '/appuser.html'
                // });
                // return;
              }

              DeviceInfo = Object.assign(DeviceInfo, {alias: loginReqest.alias, deviceHash: loginReqest.deviceHash, username: loginReqest.username, universalLogin: false, mode: people.mode, universalRedirect: false})
              if(people.usesMFA === true || people.usesSSO === true){
                loginReqest.universalLogin = true
                DeviceInfo.universalLogin = true
                if(people.usesSSO === true){
                  DeviceInfo.universalRedirect = true
                  loginReqest.universalRedirect = true
                }
              }
              //if(people.mode === 2)
             // loginReqest.universalLogin = true
             // DeviceInfo.universalLogin = true
             // loginReqest.universalRedirect = true
             // DeviceInfo.universalRedirect = true
              // devlog('hasDevice',DeviceInfo,people,loginReqest);
              if(DeviceInfo.enabled === false && appConfig.ignoreMaintainence !== true){
                if(DeviceInfo.startTime !== undefined && DeviceInfo.startTime !== "" && DeviceInfo.endTime !== undefined && DeviceInfo.endTime !== ""){
                  if(moment().isAfter(moment(DeviceInfo.startTime)) && moment().isBefore(moment(DeviceInfo.endTime))){
                    DeviceInfo.loggingIn = false
                    DeviceInfo.loading = false
                    dispatch(successHasDevice(DeviceInfo));
                    return;
                  }
                }
              }
              if (!DeviceInfo.hasDevice) {
                if (!DeviceInfo.universalLogin && !people.requiresPassword) {
                  if (people.mode === 2) {
                    DeviceInfo.requiresPassword = false
                    DeviceInfo.loggingIn = true
                    dispatch(successHasDevice(DeviceInfo));
                    dispatch(userActions.Auth0Register({
                      alias: loginReqest.alias,
                      username: loginReqest.username,
                      password: loginReqest.password,
                      newpassword: '',
                      cardSerial: '',
                      deviceId: DeviceInfo.deviceId,
                      deviceHash: loginReqest.deviceHash,
                    }))
                  } else if (people.mode == 5) {
                    DeviceInfo.requiresPassword = false
                    DeviceInfo.loggingIn = true
                    dispatch(successHasDevice(DeviceInfo));
                    userBaseActions.AWSCognitoConnection(loginReqest)
                      .then(() => { }, (e) => { dispatch(failure(e)); })
                      .catch((e) => { dispatch(e); });
                  }
                  return
                }
                dispatch(successHasDevice(DeviceInfo));
                return;
              }

              /*TODO add back in once Dan done
              if(!DeviceInfo.canRegister){
                //var error = 'Unable to register this device';
                dispatch(failureDevice());
                //dispatch(alertActions.error(error));
                return;
              }*/

              loginReqest.deviceId = DeviceInfo.deviceId
              loginReqest.mode = people.mode

              if (loginReqest.mode === 5) {
                loginReqest.cognitoDetails = {
                  clientId: people.clientId,
                  region: people.region,
                  subdomain: people.domain
                }
              }

              if(!DeviceInfo.keys){
                dispatch(requestReg(loginReqest.username, loginReqest.universalLogin?loginReqest.password:""));
                userService.registerDevice(loginReqest)
                  .then(
                    key => {
                      userService.registerUserDevice(loginReqest)
                        .then(
                          mfaId => {
                            DeviceInfo.mfaId = mfaId
                            dispatch(successReg(DeviceInfo));
                          },
                          error => {
                            dispatch(failureReg(error));
                            dispatch(alertActions.error(error));
                          }
                        );
                    },
                    error => {
                      dispatch(failure(error));
                      dispatch(alertActions.error(error));
                    }
                  );
                return;
              }

              userService.login(loginReqest)
                .then(async payload => {
                    payload = Object.assign(payload, RecordHasDevice(DeviceInfo))
                    //Temp for Ansarada message
                    payload.mainLogin = true;

                    if(payload.showMigrationSuccess) {
                      dispatch(kvpActions.set_kvp({ key: "migrateCredentialsSuccess", value: payload.showMigrationSuccess }));
                    }

                    if(appConfig.recordDeviceFingerprint === true){
                      DeviceFingerprint('success')
                      .then((hash) => {
                        SettingStorage.Put({id: 'sucesshash', key: hash}).then(()=>{}).catch((e)=>{log(e);});
                      })
                      .catch(()=>{})
                    }

                    //Populate with base data
                    var redirect = false
                    if(payload.hasOwnProperty('customerIds')){
                      //get last login customerId
                      if(payload.customerIds.length > 1){
                        dispatch(lastCustomer(payload, "loginSession"))
                      }else{
                        //trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0]))
                        try {
                          if (payload.loginCount == 1) {
                            try {
                              let aliasInfo = await userService.hasAlias(loginReqest.alias)
                              if (aliasInfo.people[0].usesSSO) {
                                payload.universalLogin = true;
                                payload.universalRedirect = true;
                                loginReqest.universalLogin = true;
                                loginReqest.universalRedirect = true;
                                DeviceInfo.universalLogin = true;
                                DeviceInfo.universalRedirect = true;
                              }
                            } catch { }
                          }
                        } catch { }
                        dispatch(success(payload));
                        dispatch(loadCustomer(payload.customerIds[0]))
                        redirect = true
                      }
                      dispatch(checkRecoverCard(payload.username))

                      checkFixingTask(dispatch, payload, loginReqest)
                      res(payload);
                      if(redirect) history.push('/');
                    }else{
                      dispatch(waiting(payload));
                      if(DeviceInfo.universalRedirect) history.push(RoutesConstants.login);
                    }
                    res(payload);
                  },
                  error => {
                    if(error === 'register'){
                      log("Register")
                      //Need a delay of 3 seconds
                      setTimeout(() => {
                        //Device hash has changed do a new register
                        userService.resetKey(loginReqest.username)
                        .then(
                          newDeviceId => {
                            loginReqest.deviceId = newDeviceId;
                            dispatch(requestReg(loginReqest.username, loginReqest.universalLogin?loginReqest.password:""));
                            userService.registerDevice(loginReqest)
                              .then(
                                key => {
                                  userService.registerUserDevice(loginReqest)
                                    .then(
                                      payload => {
                                        DeviceInfo.mfaId = payload
                                        log('DeviceInfo', DeviceInfo)
                                        dispatch(successReg(DeviceInfo));
                                        res();
                                      },
                                      error => {
                                        rej(error);
                                        dispatch(failureReg(error));
                                        dispatch(alertActions.error(error));
                                      }
                                    );
                                },
                                error => {
                                  rej(error);
                                  dispatch(failure(error));
                                  dispatch(alertActions.error(error));
                                }
                              );
                          },
                          error => {
                            rej(error);
                            dispatch(failure(error));
                            dispatch(alertActions.error(error));
                          }
                        );
                      }, 500); //Give server time to ignore rate limiting
                      return;
                    }
                    if (error && error.resetPassword) {
                      dispatch(failure("Password reset is required"));
                      rej(error);
                      return;
                    }

                    if(error === 'maintenace'){
                      const payload = Object.assign(DeviceInfo, RecordHasDevice(DeviceInfo))
                      devlog('DeviceInfo', payload)
                      dispatch(successHasDevice(payload));
                      rej(error);
                      return
                    }

                    let passwordLength = 'same'
                    if(loginReqest.passwordLength !== undefined){
                      if(loginReqest.passwordLength !== loginReqest.password.length){
                        passwordLength = "" + loginReqest.passwordLength + " - "+ loginReqest.password.length
                      }
                    }

                    dispatch(alertActions.recordDiagnosticData('LoginError', {
                      error: JSON.stringify(error),
                      passwordLength,
                    }))
                    dispatch(failure(error));
                    dispatch(setUsername(loginReqest));
                    dispatch(alertActions.error(error));
                    rej(error);
                  }
                );
            },
            error => {
              if (error && error.resetPassword) {
                dispatch(failure("Password reset is required"));
                return;
              }
              dispatch(alertActions.recordDiagnosticData('LoginHasDevice', {
                error: JSON.stringify(error),
              }))
              dispatch(failure(error));
              dispatch(alertActions.error(error));
            }
          )
      },
      error => {
        dispatch(alertActions.recordDiagnosticData('LoginhasAlias', {
          error: JSON.stringify(error),
        }))
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  })
  };

  function requestReg(username, password) { return { type: userConstants.REGISTER_USERDEVICE_REQUEST, username, password } }
  function successReg(payload, username, deviceId) { return { type: userConstants.REGISTER_USERDEVICE_SUCCESS, payload, username, deviceId } }
  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }

  function failureDevice() { return { type: userConstants.LOGIN_DEVICE_FAILURE } }

  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }

  function request(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
  function setUsername(payload) { return { type: userConstants.SET_USERNAME, payload } }
  function waiting(payload) { return { type: userConstants.LOGIN_WAITING_APPROVAL, payload } }
}

function loginClear(){
  return dispatch => {
    localStorage.removeItem(window.athenaAppID);
    dispatch(succuss());
  }

  function succuss() { return { type: userConstants.LOGIN_CLEAR } }
}

function loginClearError(){
  return dispatch => {
    dispatch(success());
  }

  function success() { return { type: userConstants.LOGIN_CLEAR_ERROR } }
}

function firstLoginClear(){
  return dispatch => {
    dispatch(succuss());
  }

  function succuss() { return { type: userConstants.MAINLOGIN_CLEAR_REQUEST } }
}

function loginWithOutRedirect(loginReqest){
  console.log("loginWithOutRedirect 1")
  return (dispatch, getState) => {
    devlog("loginReqest2")
    let authentication = getState().authentication
    loginReqest.universalLogin = authentication.universalLogin
    loginReqest.universalRedirect = authentication.universalRedirect

    dispatch(request(loginReqest.username));
    userService.hasDevice(loginReqest.username)
      .then(
        DeviceInfo => {
          if(!DeviceInfo.hasDevice){
            return;
          }

          if (!loginReqest.mode && DeviceInfo.mode) {
            loginReqest.mode = DeviceInfo.mode;
          }

          userService.login(loginReqest)
            .then(
              payload => {
                authentication = getState().authentication
                if(authentication.universalLogin) payload.universalLogin = authentication.universalLogin
                if(authentication.universalRedirect) payload.universalRedirect = authentication.universalRedirect

                payload = Object.assign(payload, RecordHasDevice(DeviceInfo))
                if (!payload.customerIds || payload.customerIds.length == 0) { payload.customerIds = []; }
                if(payload.customerIds.length > 1){
                  dispatch(lastCustomer(payload, "loginWithOutRedirect", false))
                }else{
                  dispatch(success(payload));
                  dispatch(loadCustomer(payload.customerIds[0]))
                  trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "loginWithOutRedirect"))
                  dispatch(checkRecoverCard(payload.username))
                }
                if (payload.pendingInvites && payload.pendingInvites.length && payload.pendingInvites.length > 0) {
                  dispatch(autoInvite(payload, loginReqest));
                }
              },
              error => {
                if(error === 'maintenace' || error.resetPassword){
                  dispatch(logout(loginReqest.deviceId));
                  if(RoutesConstants.login !== window.location.pathname)
                    window.location.reload(true);
                  return
                }
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          //No has device, which should always work, so logout and refresh the page
          dispatch(logout(loginReqest.deviceId));
          if(RoutesConstants.login !== window.location.pathname)
            window.location.reload(true);
        }
      );
  };

  function request(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function loginLockScreen(loginReqest){
  return dispatch => {
    dispatch(request(loginReqest.username));
    userService.hasDevice(loginReqest.username)
      .then(
        DeviceInfo => {
          if(!DeviceInfo.hasDevice){
            return;
          }

          userService.login(loginReqest)
            .then(
              payload => {
                payload = Object.assign(payload, RecordHasDevice(DeviceInfo))

                dispatch(checkRecoverCard(payload.username))
                if(payload.customerIds.length > 1){
                  dispatch(lastCustomer(payload, 'loginLockScreen', false))
                }else{
                  dispatch(success(payload));
                  dispatch(loadCustomer(payload.customerIds[0]))
                  trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "loginLockScreen"))
                }
              },
              error => {
                if(error === 'maintenace' || error.resetPassword){
                  dispatch(logout(loginReqest.deviceId));
                  if(RoutesConstants.login !== window.location.pathname)
                    window.location.reload(true);
                  return
                }
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          //No has device, which should always work, so logout and refresh the page
          dispatch(failure(error));
        }
      );
  };

  function request(username) { return { type: userConstants.LOGIN_LOCK_REQUEST, username } }
  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_LOCK_FAILURE, error } }
}

function logout(deviceId) {
console.log("logout")
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
    const { userId, mode } = getState().authentication
    var webSocket = getState().authentication.webSocket
    //trackRecord({event: "logoutSession"})
    var items = (window.dataAthenaLayer = window.dataAthenaLayer || []).slice(0)
    window.dataAthenaLayer = []
    items.forEach((d)=>{
      d.userId = userId
    })

    BlockLogout(true)

    function redirect(){
      localStorage.removeItem('AppWeb');
      localStorage.removeItem('AthenaWeb');
      localStorage.removeItem('LastType');
      // localStorage.removeItem('AppToLogInto');

      if (appConfig.smartlook) {
        try { SmartlookWrapper('anonymize'); } catch (e) { console.error(e); }
      }

      // if(appConfig.segment && typeof analytics != 'undefined'){
      //   analytics.reset()
      // }

      setTimeout(()=>{
        setTimeout(()=>{
          if(localStorage.getItem('AppWeb') !== null || localStorage.removeItem('AthenaWeb') !== null){
            localStorage.removeItem('AppWeb');
            localStorage.removeItem('AthenaWeb');
            localStorage.removeItem('LastType');
            // localStorage.removeItem('AppToLogInto');

            setTimeout(()=>{
              window.location.href = '/';
            }, 10)
          }else{
            window.location.href = '/';
          }
        },1000)
      },1000)
    }

    function out(){
      userService.logout(deviceId)
        .then(
          user => {
//            localStorage.removeItem(window.athenaAppID);
            dispatch(success());
            resolve();
            // if(mode === 2){
            //   dispatch(Auth0Logout())
            // }
            // redirect();
          },
          error => {
//            localStorage.removeItem(window.athenaAppID);
            dispatch(failure());
            reject();
            // if(mode === 2){
            //   dispatch(Auth0Logout())
            // }
            // redirect();
          }
        );
    }
    if(items.length === 0) out()

    if(webSocket !== undefined && webSocket !== null && webSocket.close !== undefined){
      webSocket.close(4987)
    }
    // dispatch(request());
    alertService.analytics(items)
      .then(
        item => {
          out()
        },
        error => {
          out()
        }
      );
    })
  };

  function request() { return { type: userConstants.LOGOUT_REQUEST  } }
  function success() { return { type: userConstants.LOGOUT_SUCCESS  } }
  function failure() { return { type: userConstants.LOGOUT_SUCCESS } }
}

function inviteCosecViaDirector(item) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      userService.inviteCosecViaDirector(item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error && error.httpcode == 403 && error.code == 303) {
            var emailDomain = "";
            try { emailDomain = item.inviteeEmail.split("@")[1].trim(); } catch { }
            dispatch(popoverAction.showError({
              title: 'Email domain is not allowed',
              body: <div>The email domain {emailDomain ? <b>{emailDomain}</b> : ''} is not allowed.</div>
            }));
            reject(error);
            return;
          } else if (error && error.HttpCode == 400 && error.Code == 123) {
            dispatch(popoverAction.showError({
              title: 'Incorrect email domain',
              body: <div>The valid email domains are {error.Detail.replaceAll(",", ", ")}.</div>
            }));
          }
          reject(error)
        })
    });
  }
}

function directSignup(item){
  return dispatch => {
    dispatch(request());
// console.log(item)
    userService.directSignup(item)
      .then(
        async(payload) => {
          payload.deviceHash = item.deviceHash
          dispatch(success(payload));

          payload.unregisteredUserType = payload.userType;
          payload.serialKeys = [];
          // todo cognito
          payload.mode = 2;
          payload.checkTermsAndConditions = null
          payload.password = payload.registrationCode
          var KBDF2 = await CredentialsHash(payload.username, item.userProfileId)
          payload.newpassword = KBDF2

          payload.unregisteredUserKAthenaAdmin = payload.kAthenaAdmin
          payload.shareKeysWithAthenaAdmin = true

          userService.registerNewUser(payload)
            .then(
              regPayload => {
                dispatch(successReg(payload.username));
                var regReqest = {
                  alias: payload.alias,
                  username: payload.username,
                  password: payload.newpassword,
                  deviceId: payload.deviceId,
                  deviceHash: payload.deviceHash,
                  keys: true,
                }
                dispatch(login(regReqest));
              },
              error => {
                dispatch(failure(error, payload.username, ""));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.DIRECT_SIGNUP_REQUEST } }
  function success(payload) { return { type: userConstants.DIRECT_SIGNUP_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.DIRECT_SIGNUP_FAILURE, error } }

  function successReg(username, customerId) { return { type: userConstants.REGISTER_NEW_USER_SUCCESS, username } }
}

function deleteTrailEmailCheckData(){
  return dispatch => {
    dispatch(success());
  }
  function success() { return { type: userConstants.TRAIL_CHECKEMAIL_DELETE } }
}

function trailSaveCoSecDetails(data){
  return dispatch => {
    dispatch(request());
    userService.trailSaveCoSecDetails(data)
      .then(
        () => {
          dispatch(success())
        },
        error => {
          let errorMessage = error
          if (error.code) errorMessage = error.message
          dispatch(failure(errorMessage));
        }
      );
  }

  function request() { return { type: userConstants.TRAIL_COSECSAVE_REQUEST } }
  function success() { return { type: userConstants.TRAIL_COSECSAVE_SUCCESS } }
  function failure(error) { return { type: userConstants.TRAIL_COSECSAVE_FAILURE, error } }
}

function trailCheckEmail(data, checkOnly) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      userService.trailCheckEmail(data, checkOnly)
        .then(
          async (payload) => {
            try {
              var attempts = 0;
              var analyticsinterval = null;
              await new Promise((resolve, reject) => {
                analyticsinterval = setInterval(() => {
                  if (appConfig.smartlook) {
                    SmartlookWrapper('identify', {
                      email: data.email,
                      plan: "board.trial",
                      loginCount: 0,
                      boardPortalType: 'admin',
                    })
                  }

                  // Checking if it is loaded else continue
                  if (attempts >= 5) { clearInterval(analyticsinterval); resolve(); return; }
                  if (typeof analytics == 'undefined') { attempts++; return; }
                  resolve();
                  clearInterval(analyticsinterval);
                  

                  // if (appConfig.segment && typeof analytics != 'undefined') {
                  //   analytics.identify(data.email, {
                  //     email: data.email,
                  //     plan: "board.freemium",
                  //     loginCount: 0,
                  //     boardPortalType: 'admin',
                  //   });
                  // }
                  
                }, 100);
              }).catch(() => { });

              if (appConfig.smartlook && data && data.email) {
                SmartlookWrapper('identify', { email: data.email });
              }

              clearInterval(analyticsinterval);

              if (payload.existingUser !== undefined && payload.existingUser === true) {
                TrackEvent("f_website_board-signup-button.clicked", {
                  signup_email: data.email,
                  plan: "board.freemium",
                  is_existing: true,
                })
              } else {
                TrackEvent("f_website_board-signup-button.clicked", {
                  signup_email: data.email,
                  plan: "board.freemium",
                  is_existing: false
                })
                payload.existingUser = false
              }
            } catch {
              clearInterval(analyticsinterval);
            }
            payload.username = data.email

            dispatch(success(payload))
            resolve(payload);
          },
          error => {
            reject(error);
            if (error === 501) {
              dispatch(success({
                username: data.email,
                existingUser: true,
                clientId: undefined
              }))
            } 
            if ((error.HttpCode == 403 || error.httpcode == 403) && (error.code == 303 || error.Code == 303)) {
              var emailDomain = "";
              try { emailDomain = data.email.split("@")[1].trim(); } catch { }
              dispatch(popoverAction.showError({
                title: 'Email domain is not allowed',
                body: <div>The email domain {emailDomain ? <b>{emailDomain}</b> : ''} is not allowed.</div>
              }));
              return;
            }

            let errorMessage = error
            if (error.code) errorMessage = error.message
            dispatch(failure(errorMessage));
            //dispatch(alertActions.error(errorMessage));
          }
        );
    })
  }

  function request() { return { type: userConstants.TRAIL_CHECKEMAIL_REQUEST } }
  function success(payload) { return { type: userConstants.TRAIL_CHECKEMAIL_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.TRAIL_CHECKEMAIL_FAILURE, error } }
}

function getTrailNewUserInfo(token){
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      userService.getTrailNewUserInfo(token)
        .then(
          payload => {
            payload.clientId = 1
            if (payload.ansaradaProfileAlreadyExists === false && payload.email) {
              TrackEvent("f_board_verify-email-button.clicked", {
                signup_email: payload.email,
                plan: "board.freemium",
                is_existing: false,
              })
            }
            payload.usesSSO = payload.usesSso || false;
            dispatch(success(payload))
            resolve(payload);
          },
          error => {
            if (error && error.code) {
              error = ErrorType(error);
            }
            reject(error);
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        );
    })
  }

  function request() { return { type: userConstants.TRAIL_GETNEWUSERINFO_REQUEST } }
  function success(payload) { return { type: userConstants.TRAIL_GETNEWUSERINFO_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.TRAIL_GETNEWUSERINFO_FAILURE, error } }
}

function completeSignUp(item){
  return dispatch => {
    return new Promise((resolve, reject) => {
      log("completeSignUp", {
        firstname: item.firstname,
        lastname: item.lastname,
        companyName: item.companyName,
        city: item.city,
        country: item.country,
        username: item.username,
        primaryNeed: item.primaryNeed,
        nextMeetingDate: item.nextMeetingDate || "",
        meetingFrequency: item.meetingFrequency || "",
        boardCount: item.boardCount,
        directorCount: item.directorCount,
        implementationDate: item.implementationDate,
        employeeCountRangeStart: item.employeeCountRangeStart,
        employeeCountRangeEnd: item.employeeCountRangeEnd,
      })
      keysStorage.Delete('lastUser')
      localStorage.removeItem('LastType');

      dispatch(request());
      userService.completeSignUp(item)
        .then(
          async (payload) => {
            payload.deviceHash = item.deviceHash
            payload.unregisteredUserType = payload.userType;
            payload.serialKeys = [];
            payload.checkTermsAndConditions = null
            payload.password = payload.registrationCode
            var isCognitoUser = Boolean(payload.cognitoSecret);
            payload.mode = isCognitoUser ? 5 : 2; // 2 = auth0, 5 = cognito
            var KBDF2 = await CredentialsHash(payload.username, isCognitoUser ? payload.cognitoSecret : payload.auth0ProfileId)
            payload.newpassword = KBDF2

            payload.alias = item.username
            if (isCognitoUser) {
              payload.cognitoPassword = item.password
            } else {
              payload.auth0Password = item.password
            }

            payload.unregisteredUserKAthenaAdmin = payload.kAthenaAdmin
            payload.shareKeysWithAthenaAdmin = true
            resolve({payload, item});
            userService.registerNewUser(payload)
              .then(
                regPayload => {
                  dispatch(success(payload))
                  dispatch(successReg(payload.username));
                  var regReqest = {
                    alias: payload.alias,
                    username: payload.username,
                    password: payload.newpassword,
                    deviceId: payload.deviceId,
                    deviceHash: payload.deviceHash,
                    keys: true,
                    id: payload.auth0ProfileId,
                  }

                  if (payload.mode === 5) {
                    regReqest.password = payload.cognitoPassword;
                  }

                  TrackEvent("f_board_complete-profile-button.clicked", {
                    signup_email: payload.alias,
                    company_name: item.companyName,
                    city: item.city,
                    country: item.country,
                    plan: "board.freemium",
                    is_existing: false,
                  })
                  setTimeout(() => {
                    if (item.companyLogo) {
                      window.freemiumCompanylogo = item.companyLogo;
                    }
                    dispatch(login(regReqest));
                  }, 100)
                },
                error => {
                  reject(error);
                  dispatch(failure(error, payload.username, ""));
                  dispatch(alertActions.error(error));
                }
              );
          },
          error => {
            reject(error);
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        );
    })

  }

  function request() { return { type: userConstants.TRAIL_COMPLETE_REQUEST } }
  function success(payload) { return { type: userConstants.TRAIL_COMPLETE_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.TRAIL_COMPLETE_FAILURE, error } }

  function successReg(username, customerId) { return { type: userConstants.REGISTER_NEW_USER_SUCCESS, username } }
}

function completeExistingUserSignUp(item) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      log("completeExistingUserSignUp", item)
      dispatch(request());
      const authentication = getState().authentication
      userService.completeExistingUserSignUp(item)
        .then(
          payload => {
            resolve(item);
            const user = {
              username: payload.username,
              deviceId: payload.deviceId,
              deviceHash: item.deviceHash,
              registrationCode: payload.registrationCode,
            }

            TrackEvent("f_board_complete-profile-button.clicked", {
              signup_email: item.username,
              company_name: item.company,
              city: item.city,
              country: item.country,
              plan: "board.freemium",
              is_existing: true,
            })

            RSACrypto.setStoreName(payload.username)
            log("registerDevice", user)
            userService.registerDevice(user)
              .then(
                key => {
                  userService.registerUserDevice(user)
                    .then(
                      mfaId => {
                        dispatch(success(mfaId, user));
                      },
                      error => {
                        dispatch(failure(error, user.username));
                      }
                    );
                },
                error => {
                  reject();
                  dispatch(failure(error, user.username));
                }
              );
          },
          error => {
            if(error && (error.Code == 119 || error.code == 119)) {
              reject(error);
              return;
            }
            reject();
            if (error === "H215") {
              window.location.href= '/';
            } else {
              dispatch(failure(error, authentication.username));
              dispatch(alertActions.error(error));
            }
          }
        );
    })
  }

  function request(username) { return { type: userConstants.TRAIL_EXISTINGCOMPLETE_REQUEST, username } }
  function success(mfaId, user) { return { type: userConstants.TRAIL_EXISTINGCOMPLETE_SUCCESS, mfaId, user } }
  function failure(error, username) { return { type: userConstants.TRAIL_EXISTINGCOMPLETE_FAILURE, error, username } }
}

function registerExistingUser(newitem){
  return (dispatch) => {
    log("registerExistingUser", {
      alias: newitem.alias,
      username: newitem.username,
      deviceId: newitem.deviceId,
      deviceHash: newitem.deviceHash,
      authCode: newitem.authCode,
    })
    dispatch(request(newitem.username));
    dispatch(alertActions.clear());
    userService.registerUserDeviceWithKey(newitem)
      .then(
        async(serialKeys) => {
          newitem.unregisteredUserType = serialKeys.unregisteredUserType;
          newitem.customerName = serialKeys.unregisteredUserCustomerName;
          newitem.shareKeysWithAthenaAdmin = true;
          newitem.serialKeys = [];
          if(serialKeys.unregisteredUserKAthenaAdmin !== undefined && serialKeys.unregisteredUserKAthenaAdmin !== "")
            newitem.unregisteredUserKAthenaAdmin = serialKeys.unregisteredUserKAthenaAdmin;

          userService.registerNewUser(newitem)
            .then(
              payload => {
                dispatch(success(newitem.username));
                var regReqest = {
                  alias: newitem.alias,
                  username: newitem.username,
                  password: newitem.newpassword,
                  deviceId: newitem.deviceId,
                  deviceHash: newitem.deviceHash,
                  keys: true,
                  id: newitem.id,
                }
                setTimeout(()=>{
                  if (newitem.companyLogo) {
                    window.freemiumCompanylogo = newitem.companyLogo;
                  }
                  dispatch(login(regReqest));
                }, 500)
              },
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(username) { return { type: userConstants.TRAIL_EXISTINGREGISTER_REQUEST, username } }
  function success(username) { return { type: userConstants.TRAIL_EXISTINGREGISTER_SUCCESS, username } }
  function failure(error) { return { type: userConstants.TRAIL_EXISTINGREGISTER_FAILURE, error } }
}

function connectWebSocket(){
  return (dispatch, getState) => {
    function establishConnection(dispatch, getState){
      var sessionToken = getState().authentication.sessionToken
      var authentication = getState().authentication
      if(getState().authentication.lockScreen) return
      userService.connectWebSocket(sessionToken)
        .then(
          payload => {
            dispatch(success(payload));

            payload.onclose = function(e) {
                if(e !== undefined && e.code !== undefined && e.code === 4987){
                  return
                }
                log('echo-protocol Client Closed',e);
                establishConnection(dispatch, getState)
            };
            payload.onmessage = function(e) {
              log("WEBSOCKET onmessage");
              if (typeof e.data === 'string') {
                // log("Received: '" + e.data + "'");
                // console.log("Received: '" + e.data + "'");
                try{
                  const r = JSON.parse(e.data)
                  
                  let l = null
                  try {
                    if (r.newCustomer && page == 'appuser') {
                      dispatch(appUserBoardActions.previewBoardHome());
                    }
                  } catch { }
                  if(r.newLock !== undefined){
                    l = r.newLock
                  } else if (r.lockUpdated !== undefined) {
                    try {
                      var bc = new BroadcastChannel(BroadcastChannelName.Locks.LockUpdated);
                      bc.postMessage({ message: BroadcastChannelName.Locks.LockUpdated, data: { ...r.lockUpdated } });
                      bc.close();
                    } catch { }

                    l = r.lockUpdated
                  } else if (r.lockCreated !== undefined) {
                    try {
                      var bc = new BroadcastChannel(BroadcastChannelName.Locks.LockCreated);
                      bc.postMessage({ message: BroadcastChannelName.Locks.LockCreated, data: { ...r.lockCreated } });
                      bc.close();
                    } catch { }

                    l = r.lockCreated
                  } else if (r.lockDeleted !== undefined) {
                    try {
                      var bc = new BroadcastChannel(BroadcastChannelName.Locks.LockDeleted);
                      bc.postMessage({ message: BroadcastChannelName.Locks.LockDeleted, data: { ...r.lockDeleted } });
                      bc.close();
                    } catch { }

                    l = r.lockDeleted
                    l.userId = ""
                    l.expiryTime = ""
                    l.expirySeconds = ""
                  }else if(r.binderUpdate !== undefined || r.binderAddition !== undefined){
                    const b = r.binderUpdate !== undefined? r.binderUpdate : r.binderAddition
                    l = {
                      objectType: 'BinderUpdate',
                      id: b.binderId,
                      boardId: b.boardId,
                      name: b.binderName,
                      binderStatus: b.type,
                      meetingDate: b.meetingDate,
                      itemCount: b.itemCount,
                      //updatedByUserId: b.updatedByUserId,
                    }

                    if(b.modifiedBinderName) l.modifiedName = b.modifiedBinderName
                    if(b.modifiedMeetingDate) l.modifiedMeetingDate = b.modifiedMeetingDate
                    if(b.modifiedItemCount !== undefined) l.modifiedItemCount = b.modifiedItemCount
                    if(b.displayItemCount !== undefined) l.displayItemCount = b.displayItemCount
                    if(b.modifiedThumbnailImageDownloadId !== undefined) l.modifiedThumbnailImageDownloadId = b.modifiedThumbnailImageDownloadId
                    if(b.expiryDate) l.expiryDate = b.expiryDate
                    if(b.thumbnailImageDownloadId) l.thumbnailImageDownloadId = b.thumbnailImageDownloadId
                    if(b.activeTransactionIds !== undefined){
                      if(b.activeTransactionIds.length === 0){
                        dispatch(ClearCache(b.boardId, b.binderId))
                      }else if(b.activeTransactionIds.length > 0) l.activeTransactionIds = b.activeTransactionIds
                    }
                    if(b.updatedByUserId === authentication.userId){
                      var binders = getState().binder
                      const binder = binders[b.binderId]
                      if(binder !== undefined){
                        //l.binderStatus = binder.binderStatus
                        if(r.binderUpdate !== undefined){
                          if(binder.binderStatus === "")
                            dispatch(TemplateUpdate({id: b.binderId, cacheId: ""}));
                        }
                      }
                    }//else l.binderStatus = b.type

                    if(r.binderUpdate !== undefined){
                      l.lockType = ''
                      l.lockExpired = ''
                      l.lockUserId = ''
                      l.lastErrorCode = ''
                      l.lastErrorDate = ''
                      l.lastErrorDetail = ''

                      dispatch(alertActions.notificationSuccess({
                        style: webConstants.PUBLISH_BINDER,
                        id: b.binderId,
                        boardId: b.boardId,
                        name: b.binderName,
                        binderStatus: b.type,
                      }))
                    }
                  }else if(r.binderUpdateFailed !== undefined){
                    const b = r.binderUpdateFailed

                    l = {
                      objectType: 'BinderUpdate',
                      id: b.binderId,
                      boardId: b.boardId,
                      name: b.binderName,
                      binderStatus: b.type,
                      itemCount: b.itemCount,
                      lockType: '',
                      lockExpired: '',
                      lockUserId: '',
                    }

                    const j = {
                      name: b.binderName,
                      id: b.binderId,
                      contentType: 'binder',
                      direction: 'upload',
                      error: '',
                      errormsg: '',
                      style: "PUBLISH_BINDER"
                    }

                    dispatch(alertActions.notificationError(j));
                  }else if(r.binderUpdateInProgress !== undefined){
                    const b = r.binderUpdateInProgress
                    l = {
                      objectType: 'binderUpdateInProgress',
                      id: b.binderId,
                      boardId: b.boardId,
                      name: b.binderName,
                      binderStatus: b.type,
                      meetingDate: b.meetingDate,
                      itemCount: b.itemCount,
                      updatedByUserId: b.updatedByUserId,
                      addedItemCount: b.addedItemCount,
                      updatedItemCount: b.updatedItemCount,
                      deletedItemCount: b.deletedItemCount,
                      addedItemsProcessedCount: b.addedItemsProcessedCount,
                      updatedItemsProcessedCount: b.updatedItemsProcessedCount,
                      deletedItemsProcessedCount: b.deletedItemsProcessedCount,
                    }
                    dispatch(BinderProgress(l))
                    return
                  }else if(r.binderRemoval !== undefined){
                    dispatch(BinderRemove(r.binderRemoval.binderId, r.binderRemoval.boardId))
                    return
                  }else if(r.binderTemplateAddition !== undefined || r.binderTemplateUpdate !== undefined){
                    const b = r.binderTemplateUpdate !== undefined? r.binderTemplateUpdate : r.binderTemplateAddition
                    l = {
                      objectType: 'TemplateUpdate',
                      id: b.binderTemplateId,
                      boardId: b.boardId,
                      name: b.binderTemplateName,
                      itemCount: b.itemCount,
                    }
                  }else if(r.binderTemplateRemoval !== undefined){
                    dispatch(TemplateRemove(r.binderTemplateRemoval.boardId, r.binderTemplateRemoval.binderTemplateId))
                    return
                  }else if(r.transactionCreated !== undefined){
                    const b = r.transactionDeleted
                    if(b.objectType === "Binder"){
                      var binders = getState().binder
                      const binder = binders[b.objectId]
                      if(binder !== undefined){
                        l = {
                          objectType: 'BinderUpdate',
                          id: b.objectId,
                          activeTransactionIds: [b.transactionId],
                        }
                      }
                    }
                  }else if(r.transactionDeleted !== undefined){
                    const b = r.transactionDeleted
                    if(b.objectType === "Binder"){
                      var binders = getState().binder
                      const binder = binders[b.objectId]
                      if(binder !== undefined){
                        dispatch(ClearCache(binder.boardId, b.objectId))
                      }
                    }
                  }else if(r.minutesAddition !== undefined || r.minutesUpdate !== undefined){
                    const b = r.minutesUpdate !== undefined? r.minutesUpdate : r.minutesAddition
                    l = {
                      objectType: 'MinutesUpdate',
                      id: b.minutesId,
                      boardId: b.boardId,
                      name: b.name,
                      status: b.status,
                      meetingDate: b.meetingDate,
                      updateDate: b.updateDate,
                    }
                    if(b.status === BinderStatus.Current){
                      setTimeout(() => {
                        dispatch(minutesActions.getMinutes(b.boardId, b.minutesId))
                        dispatch(boardActions.getBoardDrafts(b.boardId, ""))
                        setTimeout(() => {
                          dispatch(boardActions.getBoardDrafts(b.boardId, ""))
                        }, 1000)
                      }, 1000)
                    }
                  }else if(r.minutesRemoval !== undefined){
                    dispatch(MinutesRemove(r.minutesRemoval.minutesId, r.minutesRemoval.boardId))
                    return
                  }else if(r.sessionReplaced !== undefined || r.sessionTerminated){
                    if (r.sessionTerminated) {
                      LogoutAndRedirect();
                      return;
                    }
                    BlockLogout(true);
                    dispatch(sessionReplace())
                    setTimeout(() => {
                      BlockLogout(false);
                      LogoutAndRedirect()
                    }, SESSIONREPLACEMENTTIMEOUT)
                    return
                  }else if(r.userDeleted !== undefined){
                    const j = r.userDeleted
                    dispatch(UserDelete(j.userId, j.customerId))
                    return
                  }else if(r.userCreated !== undefined){
                    const j = r.userCreated
                    dispatch(UserCreate({
                      id: j.userId,
                      firstName: j.firstName,
                      lastName: j.lastName,
                      customerId: j.customerId,
                      type: j.type,
                    }))
                    dispatch(getUserDetails(j.userId))
                    return
                  }else if(r.userUpdated !== undefined){
                    const j = r.userUpdated
                    dispatch(UserUpdate({
                      id: j.userId,
                      firstName: j.firstName,
                      lastName: j.lastName,
                      customerId: j.customerId,
                    }, j.customerId))
                    dispatch(getUserDetails(j.userId))
                    return
                  }else if(r.boardAddition !== undefined){

                  }else if(r.boardUpdate !== undefined){

                  } else if (r.boardRemoval !== undefined) {
                  } else if (r.circularResolutionUpdate !== undefined || r.circularResolutionAddition !== undefined) {
                    if (r.circularResolutionAddition && r.alert) { return; }
                    if (window.lastCRUpdateNotifShown == e.data) { return; }
                    window.lastCRUpdateNotifShown = e.data;
                    var showCRNotification = true;
                    try {
                      if (authentication.userIds.includes((r.circularResolutionUpdate || r.circularResolutionAddition).updatedByUserId)) {
                        showCRNotification = false;
                        return;
                      }
                    } catch { }
                    if (r.circularResolutionAddition) {
                      dispatch(resolutionsActions.getCircularResolutionResolution(r.circularResolutionAddition.circularResolutionId, r.circularResolutionAddition.customerId, r.circularResolutionAddition.boardId, showCRNotification));
                    } else {
                      dispatch(resolutionsActions.getCircularResolutionResolution(r.circularResolutionUpdate.circularResolutionId, r.circularResolutionUpdate.customerId, r.circularResolutionUpdate.boardId, showCRNotification));
                    }
                  } else if (r.circularResolutionRemoval) {
                    if (window.lastCRUpdateNotifShown == e.data) { return; }
                    window.lastCRUpdateNotifShown = e.data;
                    var showCRNotification = true;
                    try {
                      if (authentication.userIds.includes(r.circularResolutionRemoval.deletedByUserId)) {
                        showCRNotification = false;
                        return;
                      }
                    } catch { }
                    dispatch(resolutionsActions.removeCircularResolutionFromRedux(r.circularResolutionRemoval.circularResolutionId, r.circularResolutionRemoval.boardId, r.circularResolutionRemoval.customerId, showCRNotification));
                  } else if (r.contributorFileAddition !== undefined) {
                    if (r.contributorFileAddition.contributorFileId) {
                      dispatch(baseContributorActions.contributorFileRecieved(r.contributorFileAddition));
                    }
                  }

                  if (r.formAddition) {
                    try {
                      const bc = new BroadcastChannel(BroadcastChannelName.Forms.name);
                      bc.postMessage({ message: BroadcastChannelName.Forms.messages.FormAddition, details: r });
                      bc.close();
                    } catch { }
                  }
                  if (r.formRemoval) {
                    try {
                      const bc = new BroadcastChannel(BroadcastChannelName.Forms.name);
                      bc.postMessage({ message: BroadcastChannelName.Forms.messages.FormRemoval, details: r });
                      bc.close();
                    } catch { }
                  }
                  if (r.formUpdate) {
                    try {
                      const bc = new BroadcastChannel(BroadcastChannelName.Forms.name);
                      bc.postMessage({ message: BroadcastChannelName.Forms.messages.FormUpdated, details: r });
                      bc.close();
                    } catch { }
                  }

                  if(l !== null){
                    // console.log(l);
                    switch(l.objectType){
                      case 'Binder':
                      case 'BinderTemplate':
                        dispatch(binderLock(l))
                        break;
                      case 'BinderUpdate':
                        dispatch(bulkUploadActions.updateSavedBinderId(l.id, { isSaved: true, isSaving: false }));
                        dispatch(BinderUpdate(l))
                        break;
                      case 'Minutes':
                        dispatch(minutesLock(l))
                        break;
                      case 'MinutesUpdate':
                        dispatch(MinutesUpdate(l.boardId, l))
                        break;
                      case 'TemplateUpdate':
                        dispatch(TemplateUpdate(l))
                        break;
                      case "CircularResolution":
                        dispatch({ type: resolutionsConstants.UPDATE_LOCK, payload: l });
                        break;
                    }
                  }
                }catch(e){
                  ("websocket error",e)
                }
              }
            };
          },
          error => {
            // log("WEBSOCKET ERROR", error)
            // dispatch(alertActions.errorDiagnosticData(
            //   new CodeError("WebSockets",'801', "socket error"+error.toString(), error, error.toString())
            // ))
            dispatch(failure());
          }
        );

    }
    establishConnection(dispatch, getState)

  };

  function success(payload) { return { type: userConstants.LOGIN_WEBSOCKET_SUCCESS, payload  } }
  function failure() { return { type: userConstants.LOGIN_WEBSOCKET_FAILURE } }

  function sessionReplace() { return { type: userConstants.SESSION_REPLACEMENT } }

  function binderLock(payload) { return { type: binderConstants.BINDER_WEBSOCKET_LOCK, payload } }
  function BinderUpdate(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function BinderProgress(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_PROGRESS, item } }
  function BinderRemove(binderId, boardId) { return { type: binderConstants.DELETE_BINDERCONTENT_SUCCESS, binderId, boardId } }
  function ClearCache(boardId, id) { return { type: binderConstants.DELETE_BINDERTRANSACTION_SUCCESS, boardId, id } }

  function minutesLock(payload) { return { type: minutesConstants.MINUTES_WEBSOCKET_LOCK, payload } }
  function MinutesUpdate(boardId, minutes) { return { type: minutesConstants.GET_MINUTES_SUCCESS, boardId, minutes } }
  function MinutesRemove(minutesId, boardId) { return { type: minutesConstants.DELETE_MINUTECONTENT_SUCCESS, minutesId, boardId } }

  function TemplateUpdate(templateItem) { return { type: binderConstants.NEW_TEMPLATECONTENT_SUCCESS, templateItem } }
  function TemplateRemove(boardId, binderId) { return { type: binderConstants.DELETE_TEMPLATECONTENT_SUCCESS, boardId, binderId } }

  function UserDelete(userId, customerId) { return { type: userConstants.DELETE_SUCCESS, userId, customerId } }
  function UserCreate(item) { return { type: userConstants.GETUSER_SUCCESS, item } }
  function UserUpdate(item, customerId) { return { type: userConstants.UPDATE_SUCCESS, item, customerId } }
}

function hasDevice(username){
  return dispatch => {
    dispatch(request());

    userService.hasDevice(username)
      .then(
        payload => {
          payload.username = username
          dispatch(success(payload));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.HAS_DEVICE_NOTFY_REQUEST } }
  function success(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.HAS_DEVICE_NOTFY_FAILURE, error } }
}

function checkAlias(username, redirect = null) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      userService.hasAlias(username)
        .then(
          aliasInfo => {
            if (aliasInfo.people === undefined || aliasInfo.people.length === 0) {
              dispatch(failureReg("H401"));
              dispatch(alertActions.error("H401"));
              reject();
              return
            }

            if (aliasInfo.people.length > 1) {

            }
            const mode = aliasInfo.people[0].mode;
            const uName = aliasInfo.people[0].username
            const people = aliasInfo.people[0]

            userService.hasDevice(uName)
              .then(
                async payload => {
                  payload = Object.assign(payload, people, { alias: username, universalLogin: false, username: uName, universalRedirect: false, mode: mode })

                  // If admin is last saved user, on the admin login page and tried to change login to a user account and reset password, clear some data and send to director view
                  if (payload.userType == "User" && !payload.isUserAndAdmin && page == 'admin') {
                    var lastUserAlias = await keysStorage.Get('lastUser');
                    if (lastUserAlias && lastUserAlias.key && lastUserAlias.key.alias !== payload.alias) {
                      keysStorage.Delete('lastUser');
                      keysStorage.Put({
                        id: 'lastUser', key: {
                          universalLogin: payload.universalLogin,
                          alias: payload.alias,
                          isUserAndAdmin: payload.isUserAndAdmin,
                          universalRedirect: payload.universalRedirect,
                          userType: payload.userType,
                          hasDevice: payload.hasDevice && payload.keys,
                          hasIncompleteAnsaradaSignup: payload.hasIncompleteAnsaradaSignup,
                          canRegister: payload.canRegister,
                        }
                      }).then(() => {
                        window.location = '/appuser.html'
                      });
                      localStorage.setItem('LastType', JSON.stringify({ type: 'app' }));
                      window.location = '/appuser.html'
                      return;
                    }
                  }

                  if (people.usesSSO === true || people.usesMFA === true) {
                    payload.universalLogin = true
                    if (people.usesSSO === true) {
                      people.universalRedirect = true
                    }
                  }

                  if (!payload.canRegister) {
                    dispatch(failureDevice());
                    reject();
                    return;
                  }

                  dispatch(success(payload));
                  resolve(payload);
                },
                error => {
                  dispatch(failure(error));
                  dispatch(alertActions.error(error));
                  reject(error);
                }
              );
          },
          error => {
            dispatch(failure("H401"));
            dispatch(alertActions.error(error));
            reject(error);
          }
        );
    })
  };

  function request() { return { type: userConstants.HAS_DEVICE_NOTFY_REQUEST } }
  function success(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.HAS_DEVICE_NOTFY_FAILURE, error } }

  function failureDevice() { return { type: userConstants.LOGIN_DEVICE_FAILURE } }
}

function registerDevice(user) {
  return dispatch => {
    dispatch(request(user));

    userService.registerDevice(user)
      .then(
        user => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.REGISTER_DEVICE_REQUEST } }
  function success() { return { type: userConstants.REGISTER_DEVICE_SUCCESS } }
  function failure(error) { return { type: userConstants.REGISTER_DEVICE_FAILURE, error } }
}

function registerUserDevice(user) {
  return dispatch => {
    dispatch(request(user.username));

    userService.registerUserDevice(user)
      .then(
        payload => {
          user.mfaId = payload
          dispatch(success(payload));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(username) { return { type: userConstants.REGISTER_USERDEVICE_REQUEST, username } }
  function success(payload) { return { type: userConstants.REGISTER_USERDEVICE_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }
}

function registerUserDeviceAll(user){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(user.username));

    userService.hasDevice(user.username)
      .then(
        DeviceInfo => {
          /*TODO add back on once dan one
          if(!DeviceInfo.canRegister){
            var error = 'Unable to register this device';
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            return;
          }*/

          userService.registerDevice(user)
            .then(
              key => {
                userService.registerUserDevice(user)
                  .then(
                    data => {
                      dispatch(success(DeviceInfo, user.username));
                    },
                    error => {
                      dispatch(failure(error));
                      dispatch(alertActions.error(error));
                    }
                  );
              },
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
          }
        );
  };

  function request(username) { return { type: userConstants.REGISTER_USERDEVICE_REQUEST, username } }
  function success(payload, username) { return { type: userConstants.REGISTER_USERDEVICE_POLICY_SUCCESS, payload, username } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }
}

function registerUserDevicePage(user){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(user.username));

    userService.registerDevice(user)
      .then(
        key => {
          userService.registerUserDevice(user)
            .then(
              mfaId => {
                dispatch(success(mfaId, user.username));
              },
              error => {
                dispatch(failure(error, user.username));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          dispatch(failure(error, user.username));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(username) { return { type: userConstants.REGISTER_USERANDDEVICE_REQUEST, username } }
  function success(mfaId, username) { return { type: userConstants.REGISTER_USERANDDEVICE_SUCCESS, mfaId, username } }
  function failure(error, username) { return { type: userConstants.REGISTER_USERANDDEVICE_FAILURE, error, username } }
}

function registerUserDeviceLogin(loginReqest){
console.log("registerUserDeviceLogin 1")
  return async(dispatch) => {
    return new Promise((res, rej) => {
    dispatch(alertActions.clear());
devlog("loginReqest1",loginReqest)
    dispatch(request(loginReqest.username));

    userService.hasDevice(loginReqest.username)
      .then(
        async DeviceInfo => {
          DeviceInfo.username = loginReqest.username
          devlog('hasDevice2',DeviceInfo);
          if(!DeviceInfo.hasDevice){
            dispatch(successHasDevice(DeviceInfo));
            return;
          }

          /*TODO add back on once dan one
          if(!DeviceInfo.canRegister){
            var error = 'Unable to register this device';
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            return;
          }*/

          loginReqest.deviceId = DeviceInfo.deviceId
          if (loginReqest.mode === 5) {
            if (!loginReqest.cognitoDetails) {
              try {
                var cognitoDetails = await baseUserService.getCognitoDetails(loginReqest.username);
                if (cognitoDetails) {
                  loginReqest.cognitoDetails = {
                    clientId: cognitoDetails.clientId,
                    region: cognitoDetails.region,
                    subdomain: cognitoDetails.domain
                  }
                }
              } catch (e) {
                dispatch(failure(''));
                return;
              }
            }
          }

          userService.registerUserDeviceWithKey(loginReqest)
            .then(
              data => {
                dispatch(success());

                dispatch(requestLog(loginReqest.username));

                dispatch(requestLog(loginReqest));
                userService.login(loginReqest)
                  .then(
                    payload => {
                      payload = Object.assign(payload, RecordHasDevice(DeviceInfo))

                      if(payload.showMigrationSuccess) {
                        dispatch(kvpActions.set_kvp({ key: "migrateCredentialsSuccess", value: payload.showMigrationSuccess }));
                      }

                      if(payload.hasOwnProperty('customerIds')){
                        dispatch(successLog(payload));
                        dispatch(lastCustomer(payload, "registerUserDeviceLogin"))
                        //dispatch(loadCustomer(payload.customerIds[0]))
                        //trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "registerUserDeviceLogin"))

                        checkFixingTask(dispatch, payload, loginReqest)
                        res(payload);
                        history.push('/');
                      }else{
                        dispatch(waiting(payload));
                      }
                      res(payload);
                    },
                    error => {
                      if (error && error.resetPassword) {
                        dispatch(failureLog("Password reset is required"));
                        rej(error);
                        return;
                      }
                      dispatch(failureLog(error));
                      dispatch(alertActions.error(error));
                      rej(error);
                    }
                  );
              },
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
                rej(error);
              }
            );
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        rej(err);
      }
    );
  })
  };

  function requestLog(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function successLog(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failureLog(error) { return { type: userConstants.LOGIN_FAILURE, error } }

  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }

  function request(loginReqest) { return { type: userConstants.REGISTER_USERDEVICEKEY_REQUEST, loginReqest } }
  function success() { return { type: userConstants.REGISTER_USERDEVICEKEY_SUCCESS } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICEKEY_FAILURE, error } }
  function waiting(payload) { return { type: userConstants.LOGIN_WAITING_APPROVAL, payload } }
}

function registerUserDeviceWithKey(loginReqest){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(loginReqest));

    userService.registerUserDeviceWithKey(loginReqest)
      .then(
        data => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(loginReqest) { return { type: userConstants.REGISTER_USERDEVICEKEY_REQUEST, loginReqest } }
  function success() { return { type: userConstants.REGISTER_USERDEVICEKEY_SUCCESS } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICEKEY_FAILURE, error } }
}

function registerNewUser(newitem){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(newitem.username, customerId));
    dispatch(alertActions.clear());
    devlog('registerNewUser',newitem);
    userService.registerUserDeviceWithKey(newitem)
      .then(
        async(serialKeys) => {
          newitem.unregisteredUserType = serialKeys.unregisteredUserType;
          newitem.customerName = serialKeys.unregisteredUserCustomerName;
          if(serialKeys.unregisteredUserKAthenaAdmin !== undefined && serialKeys.unregisteredUserKAthenaAdmin !== "")
            newitem.unregisteredUserKAthenaAdmin = serialKeys.unregisteredUserKAthenaAdmin;
          newitem.serialKeys = serialKeys.recoveryCards;
          if(newitem.serialKeys === undefined)
            newitem.serialKeys = []
          if(serialKeys.unregisteredUserAuth0ProfileId !== undefined && serialKeys.unregisteredUserAuth0ProfileId !== ""){
            var KBDF2 = await CredentialsHash(newitem.username, serialKeys.unregisteredUserAuth0ProfileId)

            newitem.auth0Password = newitem.newpassword
            newitem.newpassword = KBDF2
          } else if (serialKeys.unregisteredUserCognitoSecret) {
            var KBDF2 = await CredentialsHash(newitem.username, serialKeys.unregisteredUserCognitoSecret)
            newitem.cognitoPassword = newitem.newpassword
            newitem.newpassword = KBDF2
          }

          userService.registerNewUser(newitem)
            .then(
              payload => {
                dispatch(success(newitem.username, customerId));
                if(newitem.mode === 2 || newitem.mode === 5){
                  var regReqest = {
                    alias: newitem.alias,
                    username: newitem.username,
                    password: newitem.mode === 5 ? newitem.cognitoPassword : newitem.newpassword,
                    deviceId: newitem.deviceId,
                    deviceHash: newitem.deviceHash,
                    keys: true,
                  }
                  setTimeout(()=>{
                    dispatch(login(regReqest));
                  }, 200)
                }
              },
              error => {
                dispatch(failure(error, newitem.username, customerId));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error, newitem.username, customerId));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(username, customerId) { return { type: userConstants.REGISTER_NEW_USER_REQUEST, username, customerId } }
  function success(username, customerId) { return { type: userConstants.REGISTER_NEW_USER_SUCCESS, username, customerId } }
  function failure(error, username, customerId) { return { type: userConstants.REGISTER_NEW_USER_FAILURE, error, username, customerId } }
}

function registerNewUserLogin(regReqest){
  return dispatch => {
    dispatch(request());
    dispatch(login(regReqest));
  };

  function request() { return { type: userConstants.REGISTER_NEW_USER_CLEAR } }
}

var existingGetListOfUsers = {};
function getListofUsers(customerId) {
  return dispatch => {
    if(customerId === undefined) return
    if(checkDemo('getListofUsers'+customerId))return

    if (existingGetListOfUsers[customerId]) { return existingGetListOfUsers[customerId]; }

    dispatch(request(customerId));
    
    existingGetListOfUsers[customerId] = userService.getListofUsers(customerId)
      .then(
        users => {
          dispatch(success(users, customerId))
          delete existingGetListOfUsers[customerId];
        },
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error, customerId));
          delete existingGetListOfUsers[customerId];
          //dispatch(alertActions.error(error));
        }
      );
      return existingGetListOfUsers[customerId];
  };

  function request(customerId) { return { type: userConstants.GETALL_REQUEST, customerId } }
  function success(items, customerId) { return { type: userConstants.GETALL_SUCCESS, items, customerId } }
  function failure(error, customerId) { return { type: userConstants.GETALL_FAILURE, error, customerId } }
}

function populateUsers(customerId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (checkDemo('populateUsers' + customerId)) return
      if (customerId === undefined) return
      dispatch(request(customerId));

      handle206(userService.populateUsers, (data) => {
        dispatch(update(data, customerId))
      }, customerId)
        .then((items) => {
          dispatch(success(items, customerId))
          resolve();
        })
        .catch((error) => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error, customerId));
          reject();
          //dispatch(alertActions.error(error));
        })
    })
  };

  function request(customerId) { return { type: userConstants.POPULATE_USERS_REQUEST, customerId } }
  function update(items, customerId) { return { type: userConstants.POPULATE_USERS_UPDATE, items, customerId } }
  function success(items, customerId) { return { type: userConstants.POPULATE_USERS_SUCCESS, items, customerId } }
  function failure(error, customerId) { return { type: userConstants.POPULATE_USERS_FAILURE, error, customerId } }
}

var existingPopulateListOfUsers = {};
function populateListofUsers(customerId){
  return (dispatch, state) => {
    existingPopulateListOfUsers[customerId] = new Promise((resolve, reject) =>{

    if(checkDemo('populateListofUsers'+customerId))return
    if(customerId === undefined) return

    if (existingPopulateListOfUsers[customerId]) { return existingPopulateListOfUsers[customerId]; }

    dispatch(request(customerId));

    userService.populateListofUsers(customerId)
      .then(
        items => {
          delete existingPopulateListOfUsers[customerId];
          dispatch(success(items, customerId));
          if (items && items.boards && items.boards.length > 0) {
            var s = state();
            var nonsampleBoardLength = items.boards.filter(b => !b.isSample);
            let currentCustomer = s.authentication && s.authentication.customers && s.authentication.customers.find(c => c.id == s.authentication.customerId);
            if (nonsampleBoardLength.length > 0 || (currentCustomer && currentCustomer.accountType && currentCustomer.accountType.toLowerCase().includes('demo'))) {
              TrackEvent('appcues.board.createBoard.completed', { numberOfBoards: items.boards.length });
            }
          }
          if (items && items.users && items.users.length > 1) {
            var adminCount = items.adminUsers.length;
            var userCount = items.users.length - adminCount;
            TrackEvent('appcues.board.createUser.completed', {
              totalUsers: items.users.length,
              adminUsers: adminCount,
              appUsers: userCount,
            });
          }
          resolve(items);
        },
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error, customerId));
          delete existingPopulateListOfUsers[customerId];
          reject();
          //dispatch(alertActions.error(error));
        }
      );
    });

    return existingPopulateListOfUsers[customerId];
  };

  function request(customerId) { return { type: userConstants.POPULATEALL_USERS_REQUEST, customerId } }
  function success(items, customerId) { return { type: userConstants.POPULATEALL_USERS_SUCCESS, items, customerId } }
  function failure(error, customerId) { return { type: userConstants.POPULATEALL_USERS_FAILURE, error, customerId } }
}

function deleteUser(id, customerId, type) {
  return (dispatch, getState) => {
    var name = "";
    const listOfUsers = getState().users.data;
    const auth = getState().authentication;
    if(listOfUsers !== undefined)
      if(listOfUsers[id] !== undefined)
        name = listOfUsers[id].firstName + " " + listOfUsers[id].lastName;
    if(window.demo){
      dispatch(success(id, customerId));
      return
    }
    dispatch(request(id, customerId));
    userService.deleteUser(id)
      .then(
        board => {
          // if (type === 'admin') {
          //   TrackEvent("f_board_admin.deleted", {
          //     user_id: auth.userId,
          //     person_id: auth.personId,
          //     company_id: customerId,
          //     alias: auth.alias,
          //     admin_id: id,
          //   })
          // } else if (type === 'user') {
          //   TrackEvent("f_board_appuser.deleted", {
          //     user_id: auth.userId,
          //     person_id: auth.personId,
          //     company_id: customerId,
          //     alias: auth.alias,
          //     appuser_id: id,
          //   })
          // }
          
          dispatch(success(id, customerId));
          // dispatch(populateListofUsers(customerId));
          setTimeout(()=>{
            dispatch(companyActions.getCompanyLimits(customerId));
          },1000)
        },
        error => {
          if(error.code === "404"){
            return;
          }
          error = CheckInternet(error.msg, dispatch);
          dispatch(failure(error.msg, customerId))
          dispatch(alertActions.errorDiagnosticData(new CodeError("deleteMultiUser",'788', '', {
              error: error,
              name: name,
          }, 'unable to delete user')))
        }
      );
  };

  function request(id, customerId) { return { type: userConstants.DELETE_REQUEST, id, customerId } }
  function success(id, customerId) { return { type: userConstants.DELETE_SUCCESS, id, customerId } }
  function failure( error, customerId) { return { type: userConstants.DELETE_FAILURE, error, customerId } }
}

function deleteMultiUser(userIds, customerId, type){
  return (dispatch, getState) => {
    const auth = getState().authentication;
    if(window.demo){
      dispatch(success(userIds, customerId));
      return
    }
    dispatch(request(userIds, customerId));
    userService.deleteMultiUser(userIds)
      .then(
        () => {
          // if (type === 'admin') {
          //   TrackEvent("f_board_bulk-admins.deleted", {
          //     user_id: auth.userId,
          //     person_id: auth.personId,
          //     company_id: auth.customerId,
          //     alias: auth.alias,
          //     admin_ids: userIds,
          //     deleted_admin_count: userIds.length
          //   })
          // } else if (type === 'user') {
          //   TrackEvent("f_board_bulk-appusers.deleted", {
          //     user_id: auth.userId,
          //     person_id: auth.personId,
          //     company_id: auth.customerId,
          //     alias: auth.alias,
          //     appuser_ids: userIds,
          //     deleted_appuser_count: userIds.length
          //   })
          // }

          dispatch(success(userIds, customerId));
        },
        error => {
          if(error.code === "404"){
            return;
          }
          error = CheckInternet(error.msg, dispatch);
          dispatch(failure(error.msg, customerId))
          dispatch(alertActions.errorDiagnosticData(new CodeError("deleteMultiUser",error.code, '', {
              error: error,
              item: userIds,
          }, error.msg)))
        }
      );
  };

  function request(userIds, customerId) { return { type: userConstants.DELETE_MULTI_REQUEST, userIds, customerId } }
  function success(userIds, customerId) { return { type: userConstants.DELETE_MULTI_SUCCESS, userIds, customerId } }
  function failure( error, customerId) { return { type: userConstants.DELETE_MULTI_FAILURE, error, customerId } }
}

function getAllUsersWithMembershipsToBoard(boardId, filterOutDeleted = false, showRegisteredOnly = false, forceRefresh = false) {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      var savedCombinedMembershipsUserIdsValue = getState().keyValuePair && getState().keyValuePair[`newCombinedUserIdMembershipList-${boardId}`];
      if (savedCombinedMembershipsUserIdsValue && !forceRefresh) {
        if (savedCombinedMembershipsUserIdsValue.updatedDate && savedCombinedMembershipsUserIdsValue.savedValue) {
          if (moment(savedCombinedMembershipsUserIdsValue.updatedDate).isValid() && moment(savedCombinedMembershipsUserIdsValue.updatedDate).diff(moment(), 'minutes') < 5) {
            // console.log('returning saved memberships');
            resolve(savedCombinedMembershipsUserIdsValue.savedValue);
            return;
          }
        }
      }

      var currentBoardMembershipsUserIds = [];
      var users = getState().users ? getState().users.data : {};
      var currentBoard = getState().board.boards[boardId];
      var currentCustomer = getState().company && getState().authentication && getState().authentication.customerId && getState().company[getState().authentication.customerId] ? getState().company[getState().authentication.customerId] : null;

      var membershipPromises = [];
      
      if (!currentBoard || !currentBoard.memberIds) {
        membershipPromises.push(dispatch(boardActions.getMembership(boardId)));
      }
      if (currentCustomer && currentCustomer.restrictedAdminAccessEnabled) {
        membershipPromises.push(dispatch(adminPermissionsActions.getPermissions(boardId)));
      }

      await Promise.allSettled(membershipPromises);

      try { currentBoardMembershipsUserIds = getState().board.boards[boardId].memberIds.filter(m => !m.isGuest).map(m => m.userId); } catch { }

      var restrictedAdminAccessEnabled = true;
      try { restrictedAdminAccessEnabled = currentCustomer.restrictedAdminAccessEnabled; } catch { }

      var currentBoardAdminUserIds = [];
      try {
        if (restrictedAdminAccessEnabled) {
          currentBoardAdminUserIds = getState().adminPermissions[boardId].filter(a => a.isSaved).map(a => a.userId);
        } else {
          currentBoardAdminUserIds = currentCustomer.adminUserIds;
        }
      } catch { }

      if (filterOutDeleted) {
        currentBoardAdminUserIds = currentBoardAdminUserIds.filter(cbau => { return users[cbau] && !users[cbau].isDeleted })
        currentBoardMembershipsUserIds = currentBoardMembershipsUserIds.filter(cbau => { return users[cbau] && !users[cbau].isDeleted })
      }

      if (showRegisteredOnly) {
        currentBoardAdminUserIds = currentBoardAdminUserIds.filter(cbau => { return users[cbau] && users[cbau].hasRegistered })
        currentBoardMembershipsUserIds = currentBoardMembershipsUserIds.filter(cbau => { return users[cbau] && users[cbau].hasRegistered })
      }

      var newCombinedUserIdMembershipList = _.uniq([...currentBoardAdminUserIds, ...currentBoardMembershipsUserIds]);
      
      // save into keystore
      dispatch(kvpActions.set_kvp({
        key: `newCombinedUserIdMembershipList-${boardId}`,
        value: {
          updatedDate: moment().format(),
          savedValue: newCombinedUserIdMembershipList
        }
      }));

      resolve(newCombinedUserIdMembershipList);
    });
  }
}

function doesUserHaveMembershipToBoard(userId, boardId = "") {
  return (dispatch, getState) => {
    // return new Promise((resolve, reject) => {
    var companies = getState().company;
    var allBoards = getState().board ? getState().board.allBoards : null;
    var boards = getState().board ? getState().board.boards : null;
    var currentBoard = boardId && boards ? boards[boardId] : getState().board && getState().board.currentBoard ? getState().board.currentBoard : null;
    var customerId = currentBoard ? currentBoard.customerId : null;
    var adminPermissions = getState().adminPermissions;
    var users = getState().users ? getState().users.data : {};

    // Remove admins without access if customer has restrictedAdminAccessEnabled = true
    const restrictedAdminAccessEnabled = companies && companies[customerId] ? companies[customerId].restrictedAdminAccessEnabled : false;
    if (restrictedAdminAccessEnabled) {
      if (users && users[userId] && users[userId].type == UserTypeEnum.Publish) {
        // Admin has admin membership to the board
        if (adminPermissions && adminPermissions[boardId]) {
          if (adminPermissions[boardId].find(p => p.userId == userId)) { return true; }
        } else {
          try { dispatch(adminPermissionsActions.getAdminPermissionsForUser(userId)); } catch { }
        }

        // Admin has user membership to the board
        if (currentBoard && currentBoard.memberIds && currentBoard.memberIds.find(m => m.userId == userId)) { return true; }

        // Otherwise they have no membership to the board
        return false;
      } else {
        // If not an admin just check user membership
        return Boolean(currentBoard && currentBoard.memberIds && currentBoard.memberIds.find(m => m.userId == userId));
      }
    }

    return Boolean((users && users[userId] && users[userId].type == UserTypeEnum.Publish) || (currentBoard && currentBoard.memberIds && currentBoard.memberIds.find(m => m.userId == userId)));
    // })
  }
}

function simpleUserUpdate(userId, properties = {}, callback = () => { }) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const auth = getState().authentication;
      userService.updateUser({ id: userId, ...properties })
        .then(() => {
          dispatch({ type: userConstants.UPDATE_SUCCESS, userItem: { id: userId, ...properties }, customerId: auth.customerId });
          callback();
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}

function updateUser(userItem, customerId){
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const auth = getState().authentication
      if (window.demo) {
        if (userItem.board !== undefined)
          userItem.board.forEach(boardId => {
            dispatch(boardActions.addMembership(boardId, userItem.id))
          })

        if (userItem.image !== undefined) {
          const id = uuidv4();
          userItem.imageId = id
          dispatch(fileActions.demoGetImage(id, CrytpoLib.arrayBufferToBase64String(userItem.image.data)))
        }
        dispatch(demoSuccess(userItem));
        setTimeout(() => {
          history.push(RoutesConstants.people);
        }, 1000)
        return
      }

      const companies = getState().company
      const userItems = getState().users.data

      dispatch(request(userItem.id, customerId));
      userService.updateUser(userItem)
        .then(
          ([board, imageId]) => {
            dispatch(requestTask());
            userService.getAllTask()
              .then(
                tasklist => {
                  dispatch(successTask(tasklist))

                  if (board.length) {
                    var taskIds = [], memberIds = [];
                    tasklist.forEach((e) => {
                      if (e.userId !== userItem.id) return;
                      if (e.dateCompleted !== "" && e.dateCompleted !== undefined) return;
                      if (e.type !== UserAccountTask.BoardRegistration) return;
                      var found = board.find((m) => {
                        return (e.dataId === m.membershipId);
                      });
                      if (found) {
                        memberIds.push(e.dataId)
                        dispatch(updateTask({ id: e.id, start: true }));
                        dispatch(lockTask(e.id));
                        taskIds.push(e);
                      }
                    });
                    dispatch(requestBoards(board));

                    let userData = [], adminData = []
                    const company = companies[customerId]
                    if (company !== undefined && company.adminUserIds !== null && company.userIds !== null) {
                      company.adminUserIds.forEach(userId => {
                        adminData.push(userItems[userId])
                      })

                      company.userIds.forEach(userId => {
                        userData.push(userItems[userId])
                      })
                    }
                    //we got added to board so do file update
                    var item = {
                      customerId: userItem.customerId,
                      binders: userItem.binders,
                      boardIds: userItem.board,
                      board: board,
                      userId: userItem.id,
                      pUserGenSec: userItem.pUserGenSec,
                      kUser: userItem.kUser,
                      meIds: userItem.meIds,
                      taskIds: taskIds,

                      memberIds: memberIds,
                      ptype: "bg",
                      userData,
                      adminData
                    }

                    //dispatch(binderActions.workerBinderFileUpdate(item));
                    dispatch(binderActions.runAdminBgTask([item]))
                  }

                  TrackEvent("f_board_appuser.updated", {
                    user_id: auth.userId,
                    person_id: auth.personId,
                    company_id: auth.customerId,
                    alias: auth.alias,
                    appuser_id: userItem.id,
                    //boardaccess
                    appuser_boardaccess_count: userItem.boardCount !== undefined ? userItem.boardCount : 0,
                    is_userlogo_changed: (userItem.image !== undefined || userItem.image === undefined && userItem.imageId === BLANK_GUID) ? true : false,
                    is_usersettings_changed: userItem.settingsChanged,
                    is_welcome_email_sent: userItem.sendEmail,
                    is_user_names_updated: userItem.nameChanged,
                    is_mobile_number_changed: userItem.mobileChanged,
                    has_mobile_number: userItem.mobile.length > 4,
                    // setting_changed_fields: userItem.settingsDifference,
                  })

                  if (imageId !== '') {
                    userItem.imageId = imageId
                  }
                  history.push(RoutesConstants.people);
                  dispatch(success(userItem, customerId));
                  dispatch(populateListofUsers(customerId));

                  //simple timeout to refresh if remove from board and
                  //board reg is set todo as API too slow to update
                  setTimeout(() => { dispatch(getAllTask()); }, 1000);
                },
                error => {
                  dispatch(failureTask(error));
                }
            );
          },
          error => {
            if (error && error.httpcode == 403 && error.code == 303) {
              var emailDomain = "";
              try { emailDomain = userItem.email.split("@")[1].trim(); } catch { }
              dispatch(popoverAction.showError({
                title: 'Email domain is not allowed',
                body: <div>The email domain {emailDomain ? <b>{emailDomain}</b> : ''} is not allowed.</div>
              }));
              reject();
              dispatch(success(userItem, customerId));
              return;
            } else if (error && error.httpcode == 400 && error.code == 123) {
              dispatch(popoverAction.showError({
                title: 'Incorrect email domain',
                body: <div>The valid email domains are {(error.detail || "").replaceAll(",", ", ")}.</div>
              }));
              reject();
              dispatch(success(userItem, customerId));
              return;
            }

            error = CheckInternet(error, dispatch);
            dispatch(failure(userItem.id, error, customerId))
          }
        );
    })
  };

  function demoSuccess(item) { return { type: userConstants.DEMO_ADDUSER, item } }
  function requestTask() { return { type: userConstants.LIST_ALL_TASK_REQUEST } }
  function successTask(list) { return { type: userConstants.LIST_ALL_TASK_SUCCESS, list } }
  function failureTask(error) { return { type: userConstants.LIST_ALL_TASK_FAILURE, error } }
  function requestBoards(payload) { return { type: boardConstants.SETGROUPMEMBERSHIP_REQUEST, payload } }
  function request(userId, customerId) { return { type: userConstants.UPDATE_REQUEST, userId, customerId } }
  function success(item, customerId) { return { type: userConstants.UPDATE_SUCCESS, item, customerId } }
  function failure(userId, error, customerId) { return { type: userConstants.UPDATE_FAILURE, userId, error, customerId } }
}

function updateAdminUser(userItem, customerId){
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const auth = getState().authentication
      if (window.demo) {
        dispatch(success(userItem, customerId));
        setTimeout(() => {
          history.push(RoutesConstants.people);
        }, 1000)
        return
      }
      dispatch(request(userItem.id, customerId));
      userService.updateUser(userItem)
        .then(
          ([data, imageId]) => {
            TrackEvent("f_board_admin-user.updated", {
              user_id: auth.userId,
              person_id: auth.personId,
              company_id: auth.customerId,
              alias: auth.alias,
              admin_id: userItem.id,
              is_user_names_updated: userItem.nameChanged,
              is_mobile_number_changed: userItem.mobileChanged,
              is_userlogo_changed: userItem.imageChanged,
              is_usersettings_changed: userItem.settingsChanged,
              is_welcome_email_sent: userItem.sendEmail,
              has_mobile_number: userItem.mobile.length > 4,
              // setting_changed_fields: userItem.settingsDifference
            })
            if (imageId !== '') {
              userItem.imageId = imageId
            }
            dispatch(success(userItem, customerId));
            dispatch(populateListofUsers(customerId));
            history.push(RoutesConstants.people);
          },
          error => {
            if (error && error.httpcode == 403 && error.code == 303) {
              var emailDomain = "";
              try { emailDomain = userItem.email.split("@")[1].trim(); } catch { }
              dispatch(popoverAction.showError({
                title: 'Email domain is not allowed',
                body: <div>The email domain {emailDomain ? <b>{emailDomain}</b> : ''} is not allowed.</div>
              }));
              error = `The email domain ${emailDomain || ''} is not allowed.`;
              reject();
              dispatch(success(userItem, customerId));
              return;
            } else if (error && error.httpcode == 400 && error.code == 123) {
              dispatch(popoverAction.showError({
                title: 'Incorrect email domain',
                body: <div>The valid email domains are {(error.detail || "").replaceAll(",", ", ")}.</div>
              }));
              reject();
              dispatch(success(userItem, customerId));
              return;
            }
            error = CheckInternet(error, dispatch);
            dispatch(failure(error, customerId))
          }
        );
    })
  };

  function request(userId, customerId) { return { type: userConstants.UPDATE_REQUEST, userId, customerId } }
  function success(item, customerId) { return { type: userConstants.UPDATE_SUCCESS, item, customerId } }
  function failure( error, customerId) { return { type: userConstants.UPDATE_FAILURE, error, customerId } }
}

function importNewUser(list, customerId){
  return (dispatch, getState) => {

    const company = getState().company[customerId]
    let createUsersWithAlias = false
    if(company !== undefined && company.customerProperties !== null && company.customerProperties !== true && company.customerProperties.createUsersWithAlias === true){
      createUsersWithAlias = true
    }

    dispatch(request(customerId))
    var promiseArray = []
    list.forEach((item)=>{
      promiseArray.push(
        new Promise((resolve, reject) => {
          item.createUsersWithAlias = createUsersWithAlias
          userService.importNewUser(item)
            .then(
              payload => {
                dispatch(addUser(payload, customerId))
                resolve(payload)
              },
              (error) => {
                reject(error)
              }
            )
        })
      )
    })
    Promise.all(promiseArray)
    .then((payload)=>{
      dispatch(success(payload, customerId))
      dispatch(populateListofUsers(customerId))
    })
    .catch((error)=>{
      dispatch(failure(error, customerId))
    })
  };

  function request(customerId) { return { type: userConstants.IMPORT_USER_REQUEST, customerId } }
  function success(payload, customerId) { return { type: userConstants.IMPORT_USER_SUCCESS, payload, customerId } }
  function addUser(payload, customerId) { return { type: userConstants.IMPORT_ADD_SUCCESS, payload, customerId } }
  function failure( error, customerId ) { return { type: userConstants.IMPORT_USER_FAILURE, error, customerId } }
}

function getAuthOptions(state){
  const authentication = state.authentication
  var opt = {
    passwordPolicy: undefined,
    passwordPolicyRegex: undefined,
    requiresRecoveryCard: undefined,
  }
  if(authentication.customerOpt !== undefined)
    if(authentication.customerOpt[authentication.customerId] !== undefined){
      if(authentication.customerOpt[authentication.customerId].passwordPolicyRegex !== undefined)
        opt.passwordPolicyRegex = authentication.customerOpt[authentication.customerId].passwordPolicyRegex
      if(authentication.customerOpt[authentication.customerId].passwordPolicy !== undefined)
        opt.passwordPolicy = authentication.customerOpt[authentication.customerId].passwordPolicy
      if(authentication.customerOpt[authentication.customerId].requiresRecoveryCard !== undefined)
        opt.requiresRecoveryCard = authentication.customerOpt[authentication.customerId].requiresRecoveryCard
    }
  if(authentication.passwordPolicyRegex !== undefined)
    opt.passwordPolicyRegex = authentication.passwordPolicyRegex
  if(authentication.passwordPolicy !== undefined)
    opt.passwordPolicy = authentication.passwordPolicy
  if(authentication.requiresRecoveryCard !== undefined)
    opt.requiresRecoveryCard = authentication.requiresRecoveryCard

  return opt;
}

function confirmCheckInvite(userId){
  return (dispatch) => {
    dispatch(request(userId))
  }

  function request(userId) { return { type: userConstants.CONFIRM_USEREXISTS_REQUEST, userId } }
}

function clearCheckInvite(userId){
  return (dispatch) => {
    dispatch(request(userId))
  }

  function request(userId) { return { type: userConstants.CLEAR_USEREXISTS_REQUEST, userId } }
}

function checkEmailDomainMatchesCompanyDomains(email = "", showError = false) {
  return (dispatch, getState) => {
    try {
      var customerId = getState().authentication.customerId;
      var company = getState().company[customerId];
      if (company.usersMustUseOwnedDomains && company.ownedDomains && company.ownedDomains.length) {
        var userDomain = email.split("@")[1];
        if (!company.ownedDomains.find(od => od.trim() == userDomain.trim())) {
          if (showError) {
            dispatch(popoverAction.showError({
              title: 'Please use one of the mandated email domains:',
              body: <div>
                {company.ownedDomains.map(d => <div>{d}</div>)}
              </div>
            }));
          }
          return false;
        }
      }
    } catch { }
    return true;
  }
}

function checkNewUser(userItem, skipReduxAndPopups = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      userItem.verifyUser = false
      userItem.loading = true
      if (!skipReduxAndPopups) {
        dispatch(request(userItem))
      }

      userService.checkNewUser(userItem)
        .then(
          checkUser => {
            userItem.checkUser = checkUser
            userItem.verifyUser = true
            userItem.loading = false
            resolve(userItem);
            if (!skipReduxAndPopups) {
              dispatch(success(userItem))
            }
          },
          (error) => {
            if (skipReduxAndPopups) { reject(error); return; }
            if (error && error.httpcode == 403 && error.code == 303) {
              var emailDomain = "";
              try { emailDomain = userItem.email.split("@")[1].trim(); } catch { }
              dispatch(popoverAction.showError({
                title: 'Email domain is not allowed',
                body: <div>The email domain {emailDomain ? <b>{emailDomain}</b> : ''} is not allowed.</div>
              }));
            } else if (error && error.HttpCode == 400 && error.Code == 123) {
              dispatch(popoverAction.showError({
                title: 'Incorrect email domain',
                body: <div>The valid email domains are {error.Detail.replaceAll(",", ", ")}.</div>
              }));
            } else {
              userItem.error = error
            }
            dispatch(failure(userItem))
            reject(error);
          }
        );
    }).catch((e) => { })
  }

  function request(payload) { return { type: userConstants.CHECK_USEREXISTS_REQUEST, payload } }
  function success(payload) { return { type: userConstants.CHECK_USEREXISTS_SUCCESS, payload } }
  function failure(payload) { return { type: userConstants.CHECK_USEREXISTS_FAILURE, payload } }
}

function registerNewUserAsAdmin(userItem, customerId, isAdmin = false) {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // console.log(userItem);

      var userDto = await baseUserService.getUserById(userItem.id).catch(() => { return null; });

      // // Register so that auth0 account is created
      // const requestOptions = {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json', 'AthenaAppID': window.athenaAppID, 'AthenaAppVersion': getVersion(), },
      //   body: JSON.stringify({
      //     username: userDto.username,
      //     deviceId: userItem.deviceId,
      //     skipTwoFactorAuth: true,
      //     customerId: customerId
      //   })
      // };
      // var registerUserDevice = await fetch(GetURL() + 'Register/UserDevice', requestOptions)
      //   .then(response => {
      //     if (!response.ok) { if (response.status === 401) { return Promise.reject('H401'); } return Promise.reject(response.statusText); }
      //     return response.json();
      //   })
      //   .catch((e) => { console.log(e); return null; });

      // console.log(registerUserDevice);

      // if (!registerUserDevice) { console.log('Failed to register'); reject("Failed to registe"); }

      // Can get info after that
      var registerInfo = await userService.getUserRegistrationInfo(userItem.id).catch(() => { return null; });
      if (!registerInfo) { reject("Failed to get register info"); return; }

      // var oneShotKey = await fetch(GetURL() + 'OneShotKey', { method: 'GET', headers: authHeader() })
      //   .then(response => {
      //     if (!response.ok) { if (response.status === 401) { return Promise.reject('H401'); } return reject('H400'); }
      //     return response.json();
      //   }).catch((e) => { return null; });

      // if (!oneShotKey) { reject("Oneshot key error.") }
      
      
      
      
      //todo cognito
      var customerName = userItem.companyName || "";
      try { if (!customerName) { customerName = getState().company[userDto.customerId].name; } } catch { }
      var KBDF2 = await CredentialsHash(userDto.username, registerInfo.profileId);
      var regReqest = {
        alias: userItem.alias,
        username: userDto.username,
        customerName: customerName, //TODO REMOVE
        password: userDto.registrationCode,
        newpassword: KBDF2,
        auth0Password: CrytpoLib.arrayBufferToBase64String(CrytpoLib.GenerateRandom(32)),
        // cardSerial: [],
        deviceId: userItem.deviceId,
        deviceHash: userItem.deviceHash,
        // authCode: authCode,
        checkTermsAndConditions: null,
        id: registerInfo.profileId,
        mode: 2,
      }

      var userRegistrationPayload = await appUserService.registerNewUser(regReqest, false, false).catch(() => { return null; });
      if (!userRegistrationPayload) { reject("Failed to create registration payload"); return; }

      // var password = CrytpoLib.arrayBufferToBase64String(CrytpoLib.GenerateRandom(32));
      await userService.registerUserAsAdmin(userItem.id, {
        deviceId: userRegistrationPayload.deviceId,
        deviceHash: userItem.deviceHash,
        kUserTransport: userRegistrationPayload.kUserTransport,
        kUserTransportSignature: userRegistrationPayload.kUserTransportSignature,
        kUserPrivate: userRegistrationPayload.kUserPrivate,
        kUserPrivateSignature: userRegistrationPayload.kUserPrivateSignature,
        kUserPublic: userRegistrationPayload.kUserPublic,
        kUserPublicSignature: userRegistrationPayload.kUserPublicSignature,
        kUserSensitive: userRegistrationPayload.kUserSensitive,
        kUserSensitiveSignature: userRegistrationPayload.kUserSensitiveSignature,
        kGenSecWrappedKUser: userRegistrationPayload.kGenSecWrappedKUser,
        kGenSecWrap: userRegistrationPayload.kGenSecWrap,

        auth0PasswordOneShotKeyId: registerInfo.requiresPassword ? regReqest.auth0PasswordOneShotKeyId : undefined,
        auth0Password: registerInfo.requiresPassword ? userRegistrationPayload.auth0Password : undefined,
        salt: userRegistrationPayload.salt,
        // auth0Password:  CrytpoLib.arrayBufferToBase64String(await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, await CrytpoLib.importPublicKey(oneShotKey.publicKey, CrytpoLib.defaultRSAAlgorithmMethod), password)),
      }).catch(() => { reject("Failed to register"); return null; });
      
      resolve();
    });
  }
}

function newUser(userItem, customerId, shouldAutoRegister = false, shouldRunPopulate = true) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const auth = getState().authentication
      const customerId = getState().authentication.customerId
      const { passwordPolicy, passwordPolicyRegex, requiresRecoveryCard } = getAuthOptions(getState())
      var cust = getState().authentication.customers.find(o => o.id === userItem.customerId)
      if (cust !== undefined && cust.branding !== undefined)
        userItem.branding = cust.branding

      if (window.demo) {
        if (userItem.board !== undefined)
          userItem.board.forEach(boardId => {
            dispatch(boardActions.addMembership(boardId, userItem.id))
          })

        if (userItem.image !== undefined) {
          const id = uuidv4();
          userItem.imageId = id
          dispatch(fileActions.demoGetImage(id, CrytpoLib.arrayBufferToBase64String(userItem.image.data)))
        }
        dispatch(demoSuccess(userItem));
        return
      }

      if (shouldAutoRegister) {
        userItem.sendEmail = false;
      }

      dispatch(request(userItem.id, customerId));

      userService.newUser(userItem)
        .then(async payload => {
          if (userItem.type === "Publish") {
            if (userItem.boardMemberships) {
              userItem.boardMemberships.forEach(boardItem => {
                if (boardItem.type == AdminMembershipType.User) {
                  dispatch(boardActions.addMembership(boardItem.id, payload.userId, false, '', true));
                } else {
                  adminPermissionsService.savePermission({
                    id: uuidv4(),
                    type: boardItem.type,
                    boardId: boardItem.id,
                    userId: userItem.id
                  })
                    .then((result) => {
                      // dispatch(updatePermissionByUserId(p.boardId, p.userId, { isSaved: true, shouldUpdate: false, shouldDelete: false }));
                    })
                    .catch((e) => { reject(p); })
                }
              });
            }
            TrackEvent("f_board_new-admin-user.added", {
              user_id: auth.userId,
              person_id: auth.personId,
              company_id: auth.customerId,
              alias: auth.alias,
              admin_id: userItem.id,
              is_invited_user: false,
              // is_mobile_number_added: userItem.mobile !== "",
              is_userlogo_added: userItem.image !== undefined ? true : false,
              is_usersettings_changed: userItem.settingsChanged,
              is_welcome_email_sent: userItem.sendEmail,
              // setting_changed_fields: userItem.settingsDifference,
              has_mobile_number: userItem.mobile !== "",
            })
          } else {
            TrackEvent("f_board_new-app-user.added", {
              user_id: auth.userId,
              person_id: auth.personId,
              company_id: auth.customerId,
              alias: auth.alias,
              appuser_id: userItem.id,
              appuser_boardaccess_count: userItem.boardCount !== undefined ? userItem.boardCount : 0,
              is_invited_user: false,
              // is_mobile_number_added: userItem.mobile !== "",
              is_userlogo_added: userItem.image !== undefined ? true : false,
              is_usersettings_changed: userItem.settingsChanged,
              is_welcome_email_sent: userItem.sendEmail,
              // setting_changed_fields: userItem.settingsDifference,
              has_mobile_number: userItem.mobile !== "",
            })
          }

          if (shouldAutoRegister) {
            try {
              await dispatch(registerNewUserAsAdmin({
                ...userItem,
                deviceId: auth.deviceId,
                deviceHash: auth.deviceHash,
                customerKeys: auth.keys,
                customers: auth.customers,
              }, customerId));
            } catch { }
          }
          
          try { await dispatch(adminPermissionsActions.checkAndRunBoardRegistrationTasks()); } catch { }

          //userItem.id = payload.userId;
          let username = userItem.alias
          if (userItem.username !== undefined)
            username = userItem.username

          if (payload.board.length)
            dispatch(requestBoards(payload.board));

          dispatch(success(userItem.id, customerId));

          if (shouldRunPopulate) {
            dispatch(populateListofUsers(customerId));
          } 

          dispatch(requestAlias(userItem.id, username, payload.registrationCode, userItem.sendEmail, customerId));

          if (userItem.sendEmail === undefined || userItem.sendEmail === true) {
            dispatch(sendWelcomeEmail(userItem));
          }

          resolve(userItem);
        },
          (error, code) => {
            if (error && error.httpcode == 403 && error.code == 303) {
              var emailDomain = "";
              try { emailDomain = userItem.alias.split("@")[1].trim(); } catch { }
              dispatch(popoverAction.showError({
                title: 'Email domain is not allowed',
                body: <div>The email domain {emailDomain ? <b>{emailDomain}</b> : ''} is not allowed.</div>
              }));
              reject();
              dispatch(success(userItem));
              return;
            } else if (error && error.httpcode == 400 && error.code == 123) {
              dispatch(popoverAction.showError({
                title: 'Incorrect email domain',
                body: <div>The valid email domains are {(error.detail || "").replaceAll(",", ", ")}.</div>
              }));
              reject();
              dispatch(success(userItem));
              return;
            }
            if (error && typeof error == 'string' && error.includes("limit exceeded")) {
              dispatch(popoverAction.showError({
                id: 'new-user-limit-reached-error-popover',
                title: `Error creating ${userItem.type === "Publish" ? 'admin' : 'user'}`,
                body: <div>{userItem.type === "Publish" ? 'Admin' : 'User'} limit has been reached. Please contact support if you would like to increase the limit.</div>
              }));
              reject({ error: { limitExceeded: true } });
            }
            dispatch(failure(userItem.id, error, code, customerId));
            reject();
          }
        );
    });
  };

  function request(userId, customerId) { return { type: userConstants.NEW_USER_REQUEST, userId, customerId } }
  function requestBoards(payload) { return { type: boardConstants.SETGROUPMEMBERSHIP_REQUEST, payload } }
  function demoSuccess(item) { return { type: userConstants.DEMO_ADDUSER, item } }
  //function requestTemplate() { return { type: userConstants.UPDATE_USER_INITALTEMP_REQUEST } }
  //function requestGenerate() { return { type: userConstants.UPDATE_USER_INITALGEN_REQUEST } }
  function requestAlias(userId, alias, registrationCode, sendEmail, customerId) { return { type: userConstants.UPDATE_USER_INITALALIAS_REQUEST, userId, alias, registrationCode, sendEmail, customerId } }
  //function successPdf(pdf, id) { return { type: userConstants.UPDATE_USER_INITALPASS_SUCCESS, pdf, id } }
  function success(userId, customerId) { return { type: userConstants.NEW_USER_SUCCESS, userId, customerId } }
  function failure(userId, error, code, customerId ) { return { type: userConstants.NEW_USER_FAILURE, userId, error, code, customerId } }
}

function inviteUser(userItem, customerId, shouldRunPopulate = true){
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const auth = getState().authentication
      const { passwordPolicy, passwordPolicyRegex, requiresRecoveryCard } = getAuthOptions(getState())
      devlog("inviteUser", userItem)
      dispatch(request(userItem.id, customerId));

      function doInvite() {
        if (userItem.function === undefined || userItem.function === 'User')
          userService.inviteUser(userItem)
            .then(
              payload => {
                userService.getUserDetails(payload.userId)
                  .then(
                    userDetails => {
                      TrackEvent("f_board_new-app-user.added", {
                        user_id: auth.userId,
                        person_id: auth.personId,
                        company_id: auth.customerId,
                        alias: auth.alias,
                        appuser_id: userItem.id,
                        appuser_boardaccess_count: userItem.boardCount !== undefined ? userItem.boardCount : 0,
                        is_invited_user: true,
                        // is_mobile_number_added: userItem.mobile !== "",
                        is_userlogo_added: userItem.image !== undefined ? true : false,
                        is_usersettings_changed: userItem.settingsChanged,
                        is_welcome_email_sent: userItem.sendEmail,
                        // setting_changed_fields: userItem.settingsDifference,
                        has_mobile_number: userItem.mobile !== "",
                      })
                      resolve(userDetails);
                      dispatch(successUser(userDetails));
                      if (shouldRunPopulate) {
                        dispatch(populateListofUsers(customerId));
                      }

                      dispatch(success(payload.userId, true, customerId));
                      if (userItem.sendEmail === undefined || userItem.sendEmail === true)
                        dispatch(sendWelcomeEmail(userItem));
                    },
                    (error, code) => {
                      dispatch(failure(userItem.id, error, code));
                      reject(error, code);
                    }
                  );
              },
              (error, code) => {
                dispatch(failure(userItem.id, error, code));
                reject(error, code);
              }
            );
        else
          userService.inviteAdminUser(userItem)
            .then(
              payload => {
                const userId = payload[0]

                userService.getUserDetails(userId)
                  .then(
                    userDetails => {
                      TrackEvent("f_board_new-admin-user.added", {
                        user_id: auth.userId,
                        person_id: auth.personId,
                        company_id: auth.customerId,
                        alias: auth.alias,
                        admin_id: userItem.id,
                        is_invited_user: true,
                        // is_mobile_number_added: userItem.mobile !== "",
                        is_userlogo_added: userItem.image !== undefined ? true : false,
                        is_usersettings_changed: userItem.settingsChanged,
                        is_welcome_email_sent: userItem.sendEmail,
                        // setting_changed_fields: userItem.settingsDifference,
                        has_mobile_number: userItem.mobile !== "",
                      })
                      resolve(userDetails);
                      dispatch(successUser(userDetails));
                      if (shouldRunPopulate) {
                        dispatch(populateListofUsers(customerId));
                      }

                      dispatch(success(userId, true, customerId));
                      if (userItem.sendEmail === undefined || userItem.sendEmail === true)
                        dispatch(sendWelcomeEmail(userItem));
                    },
                    (error, code) => {
                      dispatch(failure(userItem.id, error, code))
                      reject(error, code);
                    }
                  );
              },
              (error, code) => {
                dispatch(failure(userItem.id, error, code))
                reject(error, code);
              }
            );
      }

      if (userItem.alias !== undefined) {
        userService.hasAlias(userItem.alias)
          .then(
            aliasInfo => {
              if (userItem.username === undefined) {
                if (aliasInfo.people === undefined || aliasInfo.people.length === 0) {
                  dispatch(failure(userItem.id, "Unable to find Username in system", code, customerId))
                  return
                }

                if (aliasInfo.people.length > 1) {
                  dispatch(failure(userItem.id, "Unable to find Username in system", code, customerId))
                  return
                }

                userItem.username = aliasInfo.people[0].username
              }
              doInvite()
            },
            (error, code) => {
              dispatch(failure(userItem.id, error, code, customerId))
              reject(error, code);
            }
          )
      } else {
        doInvite()
      }
    });
  };

  function successUser(item) { return { type: userConstants.GETUSER_SUCCESS, item } }
  function request(userId, customerId) { return { type: userConstants.INVITE_USER_REQUEST, userId, customerId } }
  function success(userId, supportsDirectInvite, customerId) { return { type: userConstants.INVITE_USER_SUCCESS, userId, supportsDirectInvite, customerId } }
  function failure(userId, error, code, customerId ) { return { type: userConstants.INVITE_USER_FAILURE, userId, error, code, customerId } }
}

function inviteMultiUser(items, customerId){
  return (dispatch, getState) => {
    const {passwordPolicy, passwordPolicyRegex, requiresRecoveryCard} = getAuthOptions(getState())

    dispatch(request());

    var promiseArray = []
    items.forEach((userItem)=>{
      promiseArray.push(
        new Promise(function(resolve, reject) {
          dispatch(request());
          userService.inviteUser(userItem)
            .then(
              payload => {
                userService.getUserDetails(payload.userId)
                .then(
                  userDetails => {
                    userDetails.supportsDirectInvite = true
                    dispatch(successUser(userDetails));
                    dispatch(request());
                    resolve()
                  },
                  (error, code) => {
                    dispatch(request());
                    reject(error, code)
                  }
                );
              },
              (error, code) => {
                dispatch(request());
                reject(error, code)
              }
            );
        })
      )
    })

    Promise.all(promiseArray)
    .then((dataChunks)=>{
      dispatch(populateListofUsers(customerId));
      dispatch(success());
    })
    .catch((error, code)=>{
      dispatch(failure(error, code));
    })
  };

  function requestInvite(userId) { return { type: userConstants.INVITE_USER_REQUEST, userId } }
  function successInvite(userId) { return { type: userConstants.INVITE_USER_SUCCESS, userId } }
  function failureInvite(userId, error, code ) { return { type: userConstants.INVITE_USER_FAILURE, userId, error, code } }

  function successUser(item) { return { type: userConstants.GETUSER_SUCCESS, item } }
  function request() { return { type: userConstants.INVITE_USERS_REQUEST } }
  function success() { return { type: userConstants.INVITE_USERS_SUCCESS } }
  function failure(error, code ) { return { type: userConstants.INVITE_USERS_FAILURE, error, code } }
}

function approveNewAdminMaster(userItem){
devlog("approveNewAdminMaster",userItem)
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(customerId));
    userItem.taskId.forEach(task => {
      dispatch(updateTask({id: task.id, start: true}));
    })

    userService.lockTask(userItem.taskId[0].id)
      .then(
        lock => {
          var promiseArray = []
          //loop for all hasRegistered customer
          userItem.registeredCustomerIds.forEach(o => {
            promiseArray.push(
              new Promise((resolve, reject) => {
                var u = Object.assign({}, userItem)
                u.customerId = o
                u.kUser = userItem.keys[o].kUser
                u.kUserSigned = userItem.keys[o].kUserSigned
                u.kUserGenSec = userItem.keys[o].kUserGenSec
                u.userType = UserTypeEnum.Master
                userService.approveNewAdminUser(u)
                  .then(
                    item => {
                      resolve()
                    },
                    error => {
                      reject(error)
                    }
                  )
              })
            )
          })

          Promise.all(promiseArray)
          .then(()=>{
            if(userItem.invitedCustomerIds.length > 0){
              var u = Object.assign({}, userItem)
              u.customerIds = userItem.invitedCustomerIds

              //Do pending invites for rest
              userService.inviteAdminUser(u)
              .then(()=>{
                dispatch(success(userItem.userId, customerId));
                userItem.taskId.forEach(task => {
                  dispatch(completedTask(task.id));
                })
              })
              .catch((error)=>{
                dispatch(failure(userItem.taskId[0].id, error, customerId))
              })
            }else{
              dispatch(success(userItem.userId, customerId));
              userItem.taskId.forEach(task => {
                dispatch(completedTask(task.id));
              })
            }
          })
          .catch((e)=>{
            dispatch(failure(userItem.taskId[0].id, e, customerId));
          })
        },
        error => {
          dispatch(failure(userItem.taskId[0].id, error, customerId));
        }
      );
  };

  function request(customerId) { return { type: userConstants.APPROVE_ADMINUSER_REQUEST, customerId } }
  function success(id, customerId) { return { type: userConstants.APPROVE_ADMINUSER_SUCCESS, id, customerId } }
  function failure(id, error, customerId) { return { type: userConstants.APPROVE_ADMINUSER_FAILURE, id, error, customerId } }
}

function sendWelcomeEmail(userItem){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(userItem, customerId));
    userService.sendWelcomeEmail(userItem)
      .then(
        payload => {
          dispatch(success(userItem, customerId));
        },
        error => dispatch(failure(userItem, error, customerId))
      );
  };

  function request(userItem, customerId) { return { type: userConstants.SEND_WELCOMEEMAIL_REQUEST, userItem, customerId } }
  function success(userItem, customerId) { return { type: userConstants.SEND_WELCOMEEMAIL_SUCCESS, userItem, customerId } }
  function failure(userItem, error, customerId) { return { type: userConstants.SEND_WELCOMEEMAIL_FAILURE, userItem, error, customerId } }
}

function regenerateWelMsg(userdetails){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId

    dispatch(request(customerId));
    userService.regenerateWelMsg(userdetails)
      .then(
        payload => {
          let username = userdetails.alias
          if(userdetails.username !== undefined)
            username = userdetails.username
          dispatch(requestAlias(userdetails.id, username, payload.registrationCode, userdetails.sendEmail, customerId));

          if(userdetails.sendEmail === undefined || userdetails.sendEmail === true)
            dispatch(sendWelcomeEmail(userdetails));
        },
        error => dispatch(failure(error, customerId))
      );
  };

  function request(customerId) { return { type: userConstants.UPDATE_USER_INITALPASS_REQUEST, customerId } }
  function requestAlias(userId, alias, registrationCode, sendEmail, customerId) { return { type: userConstants.UPDATE_USER_INITALALIAS_REQUEST, userId, alias, registrationCode, sendEmail, customerId } }
  function failure( error, customerId) { return { type: userConstants.UPDATE_USER_INITALPASS_FAILURE, error, customerId } }
}

function regeneratePdf(userdetails){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    const {passwordPolicy, passwordPolicyRegex, requiresRecoveryCard} = getAuthOptions(getState())

    dispatch(requestGenerate(customerId));
    userdetails.expireDate = moment(new Date()).add(14,'days').utc().format('Do MMMM YYYY');
    userdetails.passwordPolicy = passwordPolicy;
    userdetails.passwordPolicyRegex = passwordPolicyRegex;
    userdetails.requiresRecoveryCard = requiresRecoveryCard;

    fileService.generateWelcomePdf(userdetails)
      .then(
        pdf => {
          if(userdetails.sendEmail === undefined || userdetails.sendEmail === true)
            dispatch(sendWelcomeEmail(userdetails, pdf));
          dispatch(success(pdf, customerId));
        }
      )
      .catch((error) => {
        dispatch(failure('Failed to generate pdf', customerId));
      })
  };

//  function requestTemplate() { return { type: userConstants.UPDATE_USER_INITALTEMP_REQUEST } }
  function requestGenerate(customerId) { return { type: userConstants.UPDATE_USER_INITALGEN_REQUEST, customerId } }
  function success(pdf, customerId) { return { type: userConstants.UPDATE_USER_INITALPASS_SUCCESS, pdf, customerId } }
  function failure( error, customerId) { return { type: userConstants.UPDATE_USER_INITALPASS_FAILURE, error, customerId } }
}

function regenerateClear(userId = ""){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(userId, customerId));
  };

  function request(userId, customerId) { return { type: userConstants.UPDATE_USER_INITALCLEAR_REQUEST, userId, customerId } }
}

function getUserDetails(userId){
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if(checkDemo('getUserDetails'+userId))return
      if(window.demo){
        const users = getState().users.data
        if(users[userId] !== undefined) return
      }
    dispatch(request(userId));
    userService.getUserDetails(userId)
      .then(
        item => {
          dispatch(success(item))
          resolve(item);
        },
        error =>{
          error = CheckInternet(error, dispatch);
          dispatch(failure(userId, error));
          reject(error);
          //dispatch(alertActions.error(error));
        }
      );
    })
  };

  function request(userId) { return { type: userConstants.GETUSER_REQUEST, userId } }
  function success(item) { return { type: userConstants.GETUSER_SUCCESS, item } }
  function failure(userId, error) { return { type: userConstants.GETUSER_FAILURE, userId, error } }
}

function getUserPublicKey(userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request(userId));
      userService.getUserPublicKey(userId)
        .then(
          item => {
            dispatch(success(userId, item))
            resolve(item);
          },
          error => {
            error = CheckInternet(error, dispatch);
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            reject(error);
          }
        );
    })
  };

  function request(userId) { return { type: userConstants.GETUSER_PUBLICKEY_REQUEST, userId } }
  function success(userId, item) { return { type: userConstants.GETUSER_PUBLICKEY_SUCCESS, userId, item } }
  function failure(userId, error) { return { type: userConstants.GETUSER_PUBLICKEY_FAILURE, userId, error } }
}

// function forgotAskForLogin(){
//   return dispatch => {
//     dispatch(alertActions.clear());
//     dispatch(request());
//   };
//   function request() { return { type: userConstants.FORGOT_NOCODE_LOGIN_REQUEST } }
// }

function forgotNC(userId){
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(alertActions.clear());
      dispatch(request());
      
      userService.forgotNC(userId)
      .then(
        mfaId => { dispatch(success(mfaId)); resolve(); },
        error => {
          dispatch(failure(error));
          reject();
        }
      ).catch(() => { reject(); });
    });
  };

  function request() { return { type: userConstants.FORGOT_NOCODE_NOTFY_REQUEST } }
  function success(mfaId) { return { type: userConstants.FORGOT_NOCODE_NOTFY_SUCCESS, mfaId } }
  function failure(error) { return { type: userConstants.FORGOT_NOCODE_NOTFY_FAILURE, error } }
}

function forgotNCCode(item){
  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      userService.forgotNCCode(item)
        .then(
          item => {
            dispatch(success(item));
            resolve(item);
          },
          error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            reject(error);
          }
        );
    })
  };

  function request() { return { type: userConstants.FORGOT_NOCODE_CODE_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_NOCODE_CODE_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_NOCODE_CODE_FAILURE, error } }
}

function forgotNewPass(newitem){
  return (dispatch, getState) => {
    dispatch(request());

    const mode = getState().authentication.mode

    if(mode === 2 || mode === 5){
      newitem.mode = mode
      newitem.universalLogin = getState().authentication.universalLogin
      newitem.universalRedirect = getState().authentication.universalRedirect
      newitem.alias = getState().authentication.alias
      newitem.newPassword = newitem.password
      var resetApi = null;
      switch (mode) {
        case 2: resetApi = userService.changePasswordAuth0; break;
        case 5: resetApi = userService.changePasswordCognito; break;
      }
      if (!resetApi) {
        dispatch(failure('Error resetting password'));
        dispatch(alertActions.error('Error resetting password'));
        return;
      }
      resetApi(newitem)
        .then(
          item => dispatch(success(item)),
          error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        );
      return
    }


    userService.forgotNewPass(newitem)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_NOCODE_NEWPASS_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_NOCODE_NEWPASS_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_NOCODE_NEWPASS_FAILURE, error } }
}

function forgotWCard(newitem){
  return dispatch => {
    dispatch(request());
    userService.forgotWCard(newitem)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_CARD_NOTFY_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_CARD_NOTFY_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_CARD_NOTFY_FAILURE, error } }
}

function forgotWCardNewPass(newitem){
  return dispatch => {
    dispatch(request());
    userService.forgotWCardNewPass(newitem)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_CARD_NEWPASS_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_CARD_NEWPASS_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_CARD_NEWPASS_FAILURE, error } }
}

function changePassword(loginReqest){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    const mode = getState().authentication.mode

    if(mode === 2 || mode === 5){
      loginReqest.mode = mode
      loginReqest.universalLogin = getState().authentication.universalLogin
      loginReqest.universalRedirect = getState().authentication.universalRedirect
      loginReqest.alias = getState().authentication.alias
    }
    dispatch(request(customerId));
    userService.hasDevice(loginReqest.username)
      .then(
        DeviceInfo => {
          if(!DeviceInfo.hasDevice){
            return;
          }
          userService.login(loginReqest)
            .then(
              payload => {
                payload = Object.assign(payload, RecordHasDevice(DeviceInfo))

                if(payload.keys !== undefined)
                  loginReqest.keys = payload.keys;

                loginReqest.customerIds = payload.customers.map(o => o.id)

                if (mode === 2 || mode == 5) {
                  var resetApi = null;
                  switch (mode) {
                    case 2: resetApi = userService.changePasswordAuth0; break;
                    case 5: resetApi = userService.changePasswordCognito; break;
                  }
                  if (!resetApi) {
                    dispatch(failure('Error resetting password'));
                    dispatch(alertActions.error('Error resetting password'));
                    return;
                  }
                  resetApi(loginReqest)
                    .then(
                      item => {
                        //new sessionToken update
                        dispatch(success(customerId))
                        for(var key in payload.keys){
                          delete payload.keys[key].kUserSensitive
                          delete payload.keys[key].kUserPem
                        }
                        dispatch(successLogin(payload));
                        if(payload.customerIds.length > 1){
                          dispatch(lastCustomer(payload, "changePassword", false))
                        }else{
                          dispatch(loadCustomer(payload.customerIds[0]))
                          trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "changePassword"))
                        }
                      },
                      error => {
                        dispatch(failure(error, customerId));
                      }
                    );
                }else{
                  userService.changePassword(loginReqest)
                    .then(
                      item => {
                        //new sessionToken update
                        dispatch(success(customerId))
                        for(var key in payload.keys){
                          delete payload.keys[key].kUserSensitive
                          delete payload.keys[key].kUserPem
                        }
                        dispatch(successLogin(payload));
                        if(payload.customerIds.length > 1){
                          dispatch(lastCustomer(payload, "changePassword", false))
                        }else{
                          dispatch(loadCustomer(payload.customerIds[0]))
                          trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "changePassword"))
                        }
                      },
                      error => {
                        dispatch(failure(error, customerId));
                      }
                    );
                }
              },
              error => {
                dispatch(failure(error, customerId));
                //dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          //No has device, which should always work, so logout and refresh the page
          dispatch(failure(error), customerId);
        }
      );
  };

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function request(customerId) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, customerId } }
  function success(customerId) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, customerId } }
  function failure(error, customerId) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error, customerId } }
}

function clearPasswordLock(){
  return dispatch => {
    dispatch(success());
  };

  function success() { return { type: userConstants.CHANGE_PASSWORD_CLEAR } }
}

function initialisePassChange(){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(success(customerId));
  };

  function success(customerId) { return { type: userConstants.CHANGE_PASSWORD_INTIALISE, customerId } }
}

function approveUserNewPass(resetitem){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(resetitem.userId, customerId));
    userService.getAllTask()
      .then(
        task => {
          userService.getPersonUsers(resetitem.personId)
            .then(
              users => {
                var promiseArray = []
                users.forEach((u)=>{
                  dispatch(successUser(u));
                  promiseArray.push(
                    new Promise(function(resolve, reject) {
                      var f = task.find(o => o.userId === u.id && (o.type === UserAccountTask.PasswordReset || o.type === UserAccountTask.TemporaryPasswordRegeneration) && o.dateCompleted === undefined)
                      if(f === undefined) reject('unable to find task')
                      userService.approveUserNewPass({
                        userId: u.id,
                        dataId: f.dataId,
                        customerId: u.customerId,

                        meIds: resetitem.meIds,
                        key: resetitem.key,
                      })
                        .then(
                          item => {
                            resolve()
                          },
                          error => {
                            reject(error)
                          }
                        );
                    })
                  )
                })

                Promise.all(promiseArray)
                .then((dataChunks)=>{
                  dispatch(success(resetitem.userId, customerId));
                  dispatch(getListofPendingKey(resetitem.customerId));
                })
                .catch((error)=>{
                  dispatch(failure(error, resetitem.userId, customerId));
                })
              },
              error => {
                dispatch(failure(error, resetitem.userId, customerId));
              }
            );
          },
        error => {
          dispatch(failure(error, resetitem.userId, customerId));
        }
      );
  };
/*
  dispatch(()=>{
    return { type: userConstants.INTERNET_LOST }
  });
*/
  function successUser(item) { return { type: userConstants.GETUSER_SUCCESS, item } }

  function request(id, customerId) { return { type: userConstants.APPROVE_USER_NEWPASS_REQUEST, id, customerId } }
  function success(id, customerId) { return { type: userConstants.APPROVE_USER_NEWPASS_SUCCESS, id, customerId } }
  function failure(error, id, customerId) { return { type: userConstants.APPROVE_USER_NEWPASS_FAILURE, error, id, customerId } }
}

function approveUserNewPassGroup(items){
  return (dispatch, getState) => {
devlog('approveUserNewPassGroup',items)
    var sessionToken = getState().authentication.sessionToken

    var pool = new WorkerPool()

    pool.onmessage = (event) =>{
//console.log("!",event.data)
      if(event.data.task !== undefined)
        dispatch(taskStep(event.data.task))
      else if(event.data.populate !== undefined){
        if(event.data.populate.type === "successUser")
          dispatch(successUser(event.data.populate.data));
      }
    }

    var promiseArray = []
    // offload a function to a worker
    items.forEach((item)=>{
      dispatch(request());
      promiseArray.push(
        new Promise(function(resolve, reject) {
          dispatch(start({
            id: item.id,
            start: true
          }));
          item.ptype = "approveUser"
          item.url = GetURL()
          item.sessionToken = sessionToken
          pool.queueJob(bgAdminTask,item)
            .then((result) => {
              dispatch(success(item.id));
              resolve()
            })
            .catch((error) => {
              (error, item, items)

              var d = JSON.parse(JSON.stringify(item))
              if(d.key !== undefined && d.key[d.customerId] !== undefined){
                var k = d.key[d.customerId]
                if(k.kUserPem !== undefined && k.kUserPem !== null && k.kUserPem !== "")
                  k.kUserPem = "clear"
                if(k.kUser !== undefined && k.kUser !== null && k.kUser !== "")
                  k.kUser = "clear"
                if(k.kUserGenSec !== undefined && k.kUserGenSec !== null && k.kUserGenSec !== "")
                  k.kUserGenSec = "clear"
                if(k.kUserSensitive !== undefined && k.kUserSensitive !== null && k.kUserSensitive !== "")
                  k.kUserSensitive = "clear"
                if(k.kUserSigned !== undefined && k.kUserSigned !== null && k.kUserSigned !== "")
                  k.kUserSigned = "clear"
                if(k.pUserGenSec !== undefined && k.pUserGenSec !== null && k.pUserGenSec !== "")
                  k.pUserGenSec = "clear"
              }

              var l = items.slice(0)
              l.forEach(i => {i.key = null})

              reject({msg: error, item: d, items: l, customerId: item.customerId})
            });
        })
      )
    })

    Promise.all(promiseArray)
    .then((dataChunks)=>{
      dispatch(getListofPendingKey(items[0].customerId));
      dispatch(getAllTask());
    })
    .catch((error)=>{
      log("Error",error)
      var d = "unknown message"
      try{
        if(error.msg !== undefined){
          d = JSON.stringify(error)
        }else{
          d = error.toString()
        }
      }catch(e){

      }

      dispatch(alertActions.recordDiagnosticData('approveUserNewPassGroup', {
        error: JSON.stringify(error),
      }))
      dispatch(failure("Failed to approve password reset", error.item.id));
    })
  };

  function successUser(item) { return { type: userConstants.GETUSER_SUCCESS, item } }
  function taskStep(item) { return { type: userConstants.STEP_TASK_REQUEST, item } }

  function start(taskItem) { return { type: userConstants.UPDATE_TASK_REQUEST, taskItem } }
  function request(id) { return { type: userConstants.RESET_TASK_REQUEST, id } }
  function success(id) { return { type: userConstants.COMPLETE_TASK_SUCCESS, id } }
  function failure(error, taskId) { return { type: userConstants.ERROR_TASK_FAILURE, error, taskId } }
}

function clearAuthCode(){
  return dispatch => {
    dispatch(request());
  };

  function request() { return { type: userConstants.CLEAR_AUTH_CODE_REQUEST } }
}

function clearErrorMsg(id = 0){
  return (dispatch, getState) => {
    var customerId = getState().authentication.customerId
    dispatch(request(id, customerId));
    dispatch(alertActions.clear());
  };

  function request(id, customerId) { return { type: userConstants.CLEAR_ERROR_MSG, id, customerId } }
}

function getListofPendingKey(customerId){
  return dispatch => {
    dispatch(request(customerId));
    userService.getListofPendingKey(customerId)
      .then(
        item => dispatch(success(item, customerId)),
        error => {
          dispatch(failure(error, customerId));
        }
      );
  };

  function request(customerId) { return { type: userConstants.GET_LIST_PENDING_PASSWORD_REQUEST, customerId } }
  function success(item, customerId) { return { type: userConstants.GET_LIST_PENDING_PASSWORD_SUCCESS, item, customerId } }
  function failure(error, customerId) { return { type: userConstants.GET_LIST_PENDING_PASSWORD_FAILURE, error, customerId } }
}

function approveNewUser(userItem){
  return dispatch => {
    devlog('approveNewUser',userItem);
    dispatch(request());
    dispatch(updateTask({id: userItem.taskIds[0].id, start: true}));
    boardService.addUserToBoard(userItem)
      .then(
        good => {
          userService.lockTask(userItem.taskIds[0].id)
            .then(
              lock => {
                //process all members files
                dispatch(binderActions.workerBinderFileUpdate(userItem));
                //get new list of task
                dispatch(success(userItem.userId));
              },
              error => {
                dispatch(failure(error));
              }
            );
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: userConstants.APPROVE_NORMUSER_REQUEST } }
  function success(id) { return { type: userConstants.APPROVE_NORMUSER_SUCCESS, id } }
  function failure() { return { type: userConstants.APPROVE_ADMINUSER_REQUEST } }
}

function approveNewAdminUser(userItem, skipBinders = false) {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      if (!userItem.userpemkey) {
        userItem.userpemkey = await dispatch(userActions.getUserPublicKey(userItem.userId)).catch(() => { return null });
        if (userItem.userpemkey && userItem.userpemkey.kUser) {
          userItem.userpemkey = userItem.userpemkey.kUser;
        }
      }
      devlog('approveNewAdminUser', userItem);
      const customerId = getState().authentication.customerId
      dispatch(request(customerId));
      dispatch(updateTask({ id: userItem.taskId[0].id, start: true }));
      userService.lockTask(userItem.taskId[0].id)
        .then(
          lock => {
            userService.approveNewAdminUser(userItem)
              .then(
                item => {
                  const userList = getState().users.data;

                  userItem.userList = userList;

                  if (!skipBinders) {
                    //process all members binders <- Do binder first as to populate the todo list take longer
                    dispatch(binderActions.workerAllBinderFileUpdate(userItem));
                  }
                  //process all members files
                  //dispatch(fileActions.workerFileUpdate(userItem, sessionToken, false, true));

                  //get new list of task
                  //dispatch(getAllTask());

                  dispatch(success(userItem.userId, customerId));
                  resolve();
                },
                error => {
                  dispatch(failure(userItem.taskId[0].id, error, customerId));
                  reject();
                }
              )
          },
          error => {
            dispatch(failure(userItem.taskId[0].id, error, customerId));
            reject();
          }
        );
    })
  };

  function request(customerId) { return { type: userConstants.APPROVE_ADMINUSER_REQUEST, customerId } }
  function success(id, customerId) { return { type: userConstants.APPROVE_ADMINUSER_SUCCESS, id, customerId } }
  function failure(id, error, customerId) { return { type: userConstants.APPROVE_ADMINUSER_FAILURE, id, error, customerId } }
}

function clearNewTask(){
  return dispatch => {
    dispatch(request())
  }
  function request() { return { type: userConstants.CLEAR_NEW_TASK_REQUEST } }
}

function getAllTask(){
  return dispatch => {
    dispatch(request());
    userService.getAllTask()
      .then(
        list => dispatch(success(list)),
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: userConstants.LIST_ALL_TASK_REQUEST } }
  function success(list) { return { type: userConstants.LIST_ALL_TASK_SUCCESS, list } }
  function failure(error) { return { type: userConstants.LIST_ALL_TASK_FAILURE, error } }
}

function getTask(id){
  return dispatch => {
    dispatch(request());
    userService.getTask(id)
      .then(
        item => dispatch(success(item)),
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: userConstants.GET_TASK_REQUEST } }
  function success(item) { return { type: userConstants.GET_TASK_SUCCESS, item } }
  function failure(error) { return { type: userConstants.GET_TASK_FAILURE, error } }
}

function updateTask(taskItem){
  return dispatch => {
    dispatch(request(taskItem));
  };

  function request(taskItem) { return { type: userConstants.UPDATE_TASK_REQUEST, taskItem } }
}

function completedTaskGroup(items){
  return (dispatch, getState) => {
    var sessionToken = getState().authentication.sessionToken
    //var workerpool = require('workerpool')
    var pool = new WorkerPool()

    /*function closetask(data) {
console.log("item",data)
      return new Promise(function (resolve, reject) {
        const requestOptions = {
          method: 'POST',
          headers: data.header
        };

        fetch(data.url + 'Tasks/Complete/'+data.id, requestOptions)
        .then((response)=>{
          if (!response.ok) {
            reject(data.id, 'failed')
            return
          }
          resolve(data.id)
        })
        .catch((e)=>{ reject(data.id, e) });
      });
    }*/

    // offload a function to a worker
    var promiseArray = []
    items.forEach((item)=>{
      promiseArray.push(
        new Promise(function(resolve, reject) {
          item.ptype = "complete"
          item.url = GetURL()
          item.sessionToken = sessionToken
          pool.queueJob(bgAdminTask,item)
            .then(function (result) {
              dispatch(success(result));
              resolve()
            })
            .catch(function (id, error) {
              (id, error)
              dispatch(failure(error));
              reject(error)
            })
        })
      )
    })

    Promise.all(promiseArray)
    .then((dataChunks)=>{
      dispatch(getAllTask());
    })
    .catch((error)=>{
      log("completedTaskGroup",error)
      dispatch(alertActions.recordDiagnosticData('completedTaskGroup', {
        error: JSON.stringify(error),
      }))
    })
  };

  function request() { return { type: userConstants.COMPLETE_TASK_REQUEST } }
  function success(taskId) { return { type: userConstants.COMPLETE_TASK_SUCCESS, taskId } }
  function failure(error) { return { type: userConstants.COMPLETE_TASK_FAILURE, error } }
}

function completedTask(id){
  return (dispatch, getState) => {
    var tasks = getState().users.taskList
    dispatch(request());
    if(tasks[id] !== undefined && tasks[id].type === "internalBinders"){
      dispatch(success(id));
      return
    }
    userService.completedTask(id)
      .then(
        item => {
          dispatch(success(id));
          dispatch(getAllTask());
        },
        error => {
          dispatch(success(id));
          //dispatch(failure(error));
        }
      );
  };

  function request() { return { type: userConstants.COMPLETE_TASK_REQUEST } }
  function success(taskId) { return { type: userConstants.COMPLETE_TASK_SUCCESS, taskId } }
  function failure(error) { return { type: userConstants.COMPLETE_TASK_FAILURE, error } }
}

function lockTask(id){
  return (dispatch, getState) => {
    var userId = getState().authentication.userId
    dispatch(request(id, userId));
    userService.lockTask(id)
      .then(
        item => dispatch(success(id, userId)),
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(taskId, userId) { return { type: userConstants.LOCK_TASK_REQUEST, taskId, userId } }
  function success(taskId, userId) { return { type: userConstants.LOCK_TASK_SUCCESS, taskId, userId } }
  function failure(error) { return { type: userConstants.LOCK_TASK_FAILURE, error } }
}

function getAvailableNames(data){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if(window.demo) return
    dispatch(request(customerId));
    userService.getAvailableNames(data)
      .then(
        payload => dispatch(success(payload, customerId)),
        error => {
          dispatch(failure(error, customerId));
        }
      );
  };

  function request(customerId) { return { type: userConstants.GET_AVAILABLENAMES_REQUEST, customerId } }
  function success(payload, customerId) { return { type: userConstants.GET_AVAILABLENAMES_SUCCESS, payload, customerId } }
  function failure(error, customerId) { return { type: userConstants.GET_AVAILABLENAMES_FAILURE, error, customerId } }
}

function checkAliasName(data, userId = ""){
  return dispatch => {
    if(window.demo){
      dispatch(success(true, userId))
      return
    }
    dispatch(request(userId));
    userService.checkAliasName(data)
      .then(
        payload => {
          dispatch(success(payload, userId))
        },
        error => {
      //    dispatch(failure(error, userID));
        }
      );
  };

  function request(userId) { return { type: userConstants.CHECK_ALIASNAME_REQUEST, userId } }
  function success(payload, userId) { return { type: userConstants.CHECK_ALIASNAME_SUCCESS, payload, userId } }
  function failure(error, userId) { return { type: userConstants.CHECK_ALIASNAME_FAILURE, error, userId } }
}

function registerRecoveryCard(itemCard){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(itemCard.id, customerId));
    userService.login(itemCard)
      .then(
        logindetails => {
          itemCard.customerIds = logindetails.customerIds
          itemCard.keys = logindetails.keys

          userService.registerRecoveryCard(itemCard)
            .then(
              payload => {
                dispatch(success(itemCard.id, customerId))
                dispatch(getCustomerCards(itemCard.customerId))
                for(var key in logindetails.keys){
                  delete logindetails.keys[key].kUserSensitive
                  delete logindetails.keys[key].kUserPem
                }
                dispatch(successLogin(logindetails));
                if(logindetails.customerIds.length > 1){
                  dispatch(lastCustomer(logindetails, "registerRecoveryCard", false))
                }else{
                  dispatch(loadCustomer(logindetails.customerIds[0]))
                  trackRecord(getDeviceDetails(logindetails.customerIds[0], logindetails.userIds[0], "registerRecoveryCard"))
                }
              },
              error => {
                dispatch(failure(itemCard.id, error, customerId));
              }
            );
        },
        error => {
          dispatch(failure(itemCard.id, error, customerId));
        }
    )
  }

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function request(id, customerId) { return { type: userConstants.REGISTER_RECOVERYCARD_REQUEST, id, customerId } }
  function success(id, customerId) { return { type: userConstants.REGISTER_RECOVERYCARD_SUCCESS, id, customerId } }
  function failure(id, error, customerId) { return { type: userConstants.REGISTER_RECOVERYCARD_FAILURE, id, error, customerId } }
}

function getUserSerialCards(userId){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if(window.demo) return
    dispatch(request(userId, customerId));
    userService.getUserSerialCards(userId)
      .then(
        payload => dispatch(success(userId, payload), customerId),
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(userId, error, customerId));
        }
      );
  };

  function request(userId, customerId) { return { type: userConstants.GET_USER_SERIALCARD_REQUEST, userId, customerId } }
  function success(userId, payload, customerId) { return { type: userConstants.GET_USER_SERIALCARD_SUCCESS, userId, payload, customerId } }
  function failure(userId, error, customerId) { return { type: userConstants.GET_USER_SERIALCARD_FAILURE, userId, error, customerId } }
}

function getUserDevices(userId){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    if(window.demo){
      dispatch(success(userId, [], customerId))
      return
    }
    dispatch(request(userId, customerId));
    userService.getUserDevices(userId)
      .then(
        payload => dispatch(success(userId, payload, customerId)),
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(userId, error, customerId));
        }
      );
  };

  function request(userId, customerId) { return { type: userConstants.GET_USER_DEVICES_REQUEST, userId, customerId } }
  function success(userId, payload, customerId) { return { type: userConstants.GET_USER_DEVICES_SUCCESS, userId, payload, customerId } }
  function failure(userId, error, customerId) { return { type: userConstants.GET_USER_DEVICES_FAILURE, userId, error, customerId } }
}

function getCustomerCards(customerId){
  return dispatch => {
    if(customerId === undefined) return
    dispatch(request(customerId));
    userService.getCustomerCards(customerId)
      .then(
        payload => dispatch(success(payload, customerId)),
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(customerId, error));
        }
      );
  };

  function request(customerId) { return { type: userConstants.GET_CUSTOMER_SERIALCARD_REQUEST, customerId } }
  function success(payload, customerId) { return { type: userConstants.GET_CUSTOMER_SERIALCARD_SUCCESS, payload, customerId } }
  function failure(customerId, error) { return { type: userConstants.GET_CUSTOMER_SERIALCARD_FAILURE, customerId, error } }
}

function removeCustomerSerialCard(customerId, cardSerial){
  return dispatch => {
    userService.removeSerialCard(cardSerial)
      .then(
        payload => {
          dispatch(getCustomerCards(customerId));
        },
        error => {
        }
      );
  };
}

function removeUserSerialCard(userId, cardSerial){
  return dispatch => {
    userService.removeSerialCard(cardSerial)
      .then(
        payload => {
          dispatch(getUserSerialCards(userId));
        },
        error => {
        }
      );
  };
}

function keepAlive(){
  return dispatch => {
    dispatch(request())
    dispatch(getAllTask())
    checkForUpdate()
    .then((updateAvailable)=>{
      if(updateAvailable){
        dispatch(requestUpdate(updateAvailable));
      }
    })
  };

  function request() { return { type: userConstants.KEEP_SESSION_ALIVE } }
  function requestUpdate(status) { return { type: userConstants.WEBSITE_UPDATE_AVAILABLE, status } }
}

function lockScreen(){
  return dispatch => {
    dispatch(request());
  };

  function request() { return { type: userConstants.KILL_SESSION_ALIVE } }
}

function regUserSignCert(userId){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(userId, customerId));
    userService.regUserSignCert(userId)
      .then(
        payload => dispatch(success(userId, customerId)),
        error => {
          dispatch(failure(userId, error, customerId));
        }
      );
  };

  function request(userId, customerId) { return { type: userConstants.REG_SIGNCERT_REQUEST, userId, customerId } }
  function success(userId, customerId) { return { type: userConstants.REG_SIGNCERT_SUCCESS, userId, customerId } }
  function failure(userId, error, customerId) { return { type: userConstants.REG_SIGNCERT_FAILURE, userId, error, customerId } }
}

function sendFeedBack(data){
  return dispatch => {
    userService.sendFeedBack(data)
      .then(
        payload => {},
        error => {
        }
      );
  };
}

function sendBugCrash(data, refresh = true){
  return dispatch => {
    userService.sendFeedBack(data)
      .then(
        payload => {
          if(refresh) window.location.href='/';
        },
        error => {
        }
      );
  };
}

var eTimeout = null;
function sendErrorBack(msg){
  return (dispatch, getState) => {
    (window.errorLayer = window.errorLayer || []).push(msg)

    clearTimeout(eTimeout)
    eTimeout = setTimeout(() => {
      var items = (window.errorLayer = window.errorLayer || []).slice(0)
      window.errorLayer = []

      var tack = window.simpleLayer.slice(0)

      var customerId = getState().authentication.customerId
      userService.sendFeedBack({
        customerId: customerId,
        feedbackTitle: "Error upload",
        feedBackType: 'Bug',
        feedback: items.join("\n\n")+"\n\n\nTracking: \n"+tack.join("\n\n"),
      })
        .then(
          payload => {},
          error => {
          }
        );
    }, 2000)
  };
}

function getGenKey(customerId){
  return dispatch => {
    if(customerId === undefined) return
    dispatch(request());

    return new Promise((resolve, reject) => {
      userService.getGenKey(customerId)
        .then(
          payload => {
            payload.customerId = customerId;
            dispatch(success(payload))
            resolve(payload);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
    })
  };

  function request() { return { type: userConstants.GET_GENERIC_KEY_REQUEST } }
  function success(payload) { return { type: userConstants.GET_GENERIC_KEY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.GET_GENERIC_KEY_FAILURE, error } }
}

function getGenPrivKey(customerId){
  return (dispatch, getState) => {
    if(customerId === undefined) return
    if(window.demo){
      dispatch(success({
        customerId,
        kUser: {}
      }))
      return
    }
    var keys = getState().authentication.keys;
    var kUser = null;
    if(keys !== undefined){
      if(keys[customerId] !== undefined){
        if(keys[customerId].kUser !== undefined){
          kUser = keys[customerId].kUser;
        }
      }
    }
    if(kUser === null) return
    dispatch(request({customerId: customerId, kUser: null}));
    
    return new Promise((resolve, reject) => {
      userService.getGenPrivKey(customerId, kUser)
        .then(
          payload => {
            dispatch(success(payload))
            resolve(payload);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
    });
  };

  function request(payload) { return { type: userConstants.GET_PGENERIC_KEY_REQUEST, payload } }
  function success(payload) { return { type: userConstants.GET_PGENERIC_KEY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.GET_PGENERIC_KEY_FAILURE, error } }
}

function groupAdd(items){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(customerId));
    userService.groupAdd(items)
      .then(
        payload => {
          items.id = payload.id;
          dispatch(success(items, customerId))
        },
        error => {
          dispatch(failure(error, customerId));
        }
      );
  };

  function request(customerId) { return { type: userConstants.GROUP_ADD_REQUEST, customerId } }
  function success(payload, customerId) { return { type: userConstants.GROUP_ADD_SUCCESS, payload, customerId } }
  function failure(error, customerId) { return { type: userConstants.GROUP_ADD_FAILURE, error, customerId } }
}

function groupDelete(id){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(id, customerId));
    userService.groupDelete(id)
      .then(
        payload => dispatch(success(id, customerId)),
        error => {
          dispatch(failure(error, customerId));
        }
      );
  };

  function request(id, customerId) { return { type: userConstants.GROUP_DELETE_REQUEST, id, customerId } }
  function success(id, customerId) { return { type: userConstants.GROUP_DELETE_SUCCESS, id, customerId } }
  function failure(error, customerId) { return { type: userConstants.GROUP_DELETE_FAILURE, error, customerId } }
}

function groupGet(id){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(id, customerId));
    userService.groupGet(id)
      .then(
        payload => dispatch(success(id, customerId)),
        error => {
          dispatch(failure(error, customerId));
        }
      );
  };

  function request(id, customerId) { return { type: userConstants.GROUP_GET_REQUEST, id, customerId } }
  function success(id, customerId) { return { type: userConstants.GROUP_GET_SUCCESS, id, customerId } }
  function failure(error, customerId) { return { type: userConstants.GROUP_GET_FAILURE, error, customerId } }
}

var existingGetAllGroup;
function groupGetAll() {
  return (dispatch, getState) => {
    if (existingGetAllGroup) { return existingGetAllGroup; }

    existingGetAllGroup = new Promise((resolve, reject) => {
      const customerId = getState().authentication.customerId
      if (checkDemo('groupGetAll')) return
      dispatch(request(customerId));
      userService.groupGetAll()
        .then(
          payload => {
            existingGetAllGroup = undefined;
            dispatch(success(payload, customerId))
          },
          error => {
            existingGetAllGroup = undefined;
            dispatch(failure(error, customerId));
          }
        );
    })
    return existingGetAllGroup;
  };

  function request(customerId) { return { type: userConstants.GROUP_GETALL_REQUEST, customerId } }
  function success(payload, customerId) { return { type: userConstants.GROUP_GETALL_SUCCESS, payload, customerId } }
  function failure(error, customerId) { return { type: userConstants.GROUP_GETALL_FAILURE, error, customerId } }
}

function groupUpdate(item){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(item, customerId));
    userService.groupUpdate(item)
      .then(
        payload => dispatch(success(item, customerId)),
        error => {
          dispatch(failure(error, customerId));
        }
      );
  };

  function request(payload, customerId) { return { type: userConstants.GROUP_UPDATE_REQUEST, payload, customerId } }
  function success(payload, customerId) { return { type: userConstants.GROUP_UPDATE_SUCCESS, payload, customerId } }
  function failure(error, customerId) { return { type: userConstants.GROUP_UPDATE_FAILURE, error, customerId } }
}

function deleteUserSettings(userId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      userService.deleteUserSettings(userId)
        .then(() => {
          dispatch({
            type: userConstants.DELETE_USER_SETTINGS,
            payload: { userId }
          })
          resolve();
        })
        .catch(() => {
          reject();
        })
    });
  }
}
function updateUserSettings(userId, newUserSettings) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      userService.updateUserSettings(userId, newUserSettings)
        .then(() => {
          dispatch({
            type: userConstants.UPDATE_USER_SETTINGS,
            payload: { userId, newUserSettings }
          })
          resolve();
        })
        .catch(() => {
          reject();
        })
    });
  }
}

function updateDisplaySettings(key, value){
  return dispatch => {
    dispatch(update(key, value));
  };

  function update(key, value) { return { type: userConstants.USER_DISPLAYSETTINGS_SUCESS, key, value } }
}

function getMyUsers(){
  return dispatch => {
    userService.getMyUsers()
      .then(
        payload => dispatch(success(payload)),
        error => {

        }
      );
  };

  function success(items) { return { type: userConstants.GETLOGIN_SUCCESS, items } }
}

function autoInvite(userItem, loginReqest){
  return (dispatch, getState) => {
    log("Auto invite 1");
    const customerId = getState().authentication.customerId
    //userItems.keys = payload.keys
    //userItems.username = getState().authentication.username;
    var initial = {
      password: loginReqest.password,
      invites: [],
      username: userItem.username,
      keys: userItem.keys,
      ignoreTask: false,
    }
    userItem.pendingInvites.forEach(o => {
      if(o.autoAccept){
        o.result = true;
        //o.userType = 'Master' //TODO remove and replace once Dan does the update
        initial.invites.push(o)
      }
    })
// console.log("AAAAAA",userItem, loginReqest,initial)
// return
    if(initial.invites.length === 0) return
    dispatch(request(customerId))
    userService.getMyRecoveryCards()
    .then(
      recoveryCards => {
        dispatch(update(1, customerId))
        initial.serialKeys = recoveryCards
        userService.processInvite(initial)
          .then(
            payload => {
              dispatch(update(2, customerId))
              //We need to confirm the password is correct
              userService.login(loginReqest)
                .then(
                  logindetails => {
                    dispatch(update(3, customerId))
                    dispatch(successLogin(logindetails));
                    userService.doInviteImpersonation(initial, payload)
                      .then(
                        payload2 => {
                          dispatch(update(4, customerId))
                          userService.login(loginReqest)
                            .then(
                              logindetails => {
                                dispatch(successLogin(logindetails));
                                if(logindetails.userType[Object.keys(logindetails.userType)[0]] === UserTypeEnum.Master){
                                  dispatch(companyActions.getCompanyList())
                                }
                                dispatch(success(customerId))
                                if (payload.customerIds) {
                                  if (payload.customerIds.length > 1) {
                                    dispatch(lastCustomer(logindetails, "autoInvite", false))
                                  } else {
                                    dispatch(loadCustomer(logindetails.customerIds[0]))
                                    trackRecord(getDeviceDetails(logindetails.customerIds[0], logindetails.userIds[0], "autoInvite"))
                                  }
                                }
                                //Update any board files documents for the new customer
                                if(logindetails.userType[Object.keys(logindetails.userType)[0]] !== UserTypeEnum.Master){
                                  initial.invites.forEach(invite => {
                                    dispatch(boardActions.populateBoardData(invite.customerId))
                                  })
                                }
                              },
                              error => {
                                log(error);
                                dispatch(failure(error, customerId))
                              }
                            )
                        },
                        error => {
                          log(error);
                          dispatch(failure(error, customerId))
                        }
                      )
                  },
                  error => {
                    log(error);
                    dispatch(failure(error, customerId))
                  }
                );
            },
            error => {
              log(error);
              dispatch(failure(error, customerId))
            }
          );
      },
      error => {
        log(error);
        dispatch(failure(error, customerId))
      }
    );
  }

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function request(customerId) { return { type: userConstants.AUTO_INVITE_REQUEST, customerId } }
  function update(value, customerId) { return { type: userConstants.AUTO_INVITE_UPDATE, value } }
  function success(customerId) { return { type: userConstants.AUTO_INVITE_SUCCESS, customerId } }
  function failure(error, customerId) { return { type: userConstants.AUTO_INVITE_FAILURE, error, customerId } }
}

function clearInvite(){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(success(customerId))
  };

  function success(customerId) { return { type: userConstants.CLEAR_CONFIRM_INVITE_SUCESS, customerId } }
}

function processInvite(userItems) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      userItems.keys = getState().authentication.keys;
      userItems.username = getState().authentication.username;
      const customerId = getState().authentication.customerId
      function runProcess(ut) {
        userService.processInvite(ut)
          .then(
            payload => {
              //We need to confirm the password is correct
              if (ut.mode === 2) {
                ut.passwordAuth0 = ut.password
                ut.password = ut.auth0Password
              }
              userService.login(ut)
                .then(
                  logindetails => {
                    dispatch(successLogin(logindetails));
                    userService.doInviteImpersonation(ut, payload)
                      .then(
                        payload2 => {
                          userService.login(ut)
                            .then(
                              logindetails => {
                                dispatch(successLogin(logindetails))
                                dispatch(success(customerId))
                                if (logindetails.customerIds.length > 1) {
                                  dispatch(lastCustomer(logindetails, "processInvite", false))
                                } else {
                                  dispatch(loadCustomer(logindetails.customerIds[0]))
                                  trackRecord(getDeviceDetails(logindetails.customerIds[0], logindetails.userIds[0], "processInvite"))
                                }
                                //Update any board files documents for the new customer
                                ut.invites.forEach((invite, index) => {
                                  dispatch(boardActions.populateBoardData(invite.customerId))
                                  dispatch(popoverAction.showDialog({
                                    dialogId: `${index}-complete-invite-popup`,
                                    width: 'sm',
                                    title: `Data for ${invite.companyName} is loading in the background.`,
                                    content: `Check in a few minutes for ${invite.companyName} in the board selection dropdown.`,
                                    dialogActions: <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove(`${index}-complete-invite-popup`)) }}> Ok</MuiButton >,
                                  }));
                                })
                                resolve();
                              },
                              error => {
                                reject(error);
                                dispatch(failure(error, customerId))
                              }
                            );
                        },
                        error => {
                          if (ut && ut.invites && ut.invites.length) {
                            ut.invites.forEach((invite, index) => {
                              if (invite.willBeFinalised) {
                                dispatch(popoverAction.showDialog({
                                  dialogId: `${index}-finalise-invite-popup`,
                                  width: 'sm', 
                                  title: `Access to ${invite.companyName} will be finalised`,
                                  content : 'Please wait for the confirmation email and re-log in to be able to access the new customer.', 
                                  dialogActions: <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove(`${index}-finalise-invite-popup`)) }}> Ok</MuiButton >, 
                                }));
                              }
                            })
                          }
                          reject(error);
                          dispatch(failure(error, customerId))
                        }
                      )
                  },
                  error => {
                    reject(error);
                    dispatch(failure(error, customerId))
                  }
                );
            },
            error => {
              reject(error);
              dispatch(failure(error, customerId))
            }
          );
      }

      dispatch(request(customerId))
      userService.getMyRecoveryCards()
        .then(
          recoveryCards => {
            userItems.serialKeys = recoveryCards
            //Are we Alias Company if so Get the profile id
            if (userItems.mode === 2) {
              userService.Auth0Login(userItems)
                .then(
                  async (auth0) => {
                    var KBDF2 = await CredentialsHash(userItems.username, auth0.profileId)

                    userItems.auth0Password = userItems.password
                    userItems.password = KBDF2

                    runProcess(userItems);
                  },
                  error => {
                    reject(error);
                    dispatch(failure(error, customerId))
                  }
                );
            } else {
              runProcess(userItems)
            }
          },
          error => {
            reject(error);
            dispatch(failure(error, customerId))
          }
        );
    })
  };

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function request(customerId) { return { type: userConstants.MANUAL_CONFIRM_INVITE_REQUEST, customerId } }
  function success(customerId) { return { type: userConstants.MANUAL_CONFIRM_INVITE_SUCCESS, customerId } }
  function failure(error, customerId) { return { type: userConstants.MANUAL_CONFIRM_INVITE_FAILURE, error, customerId } }
}

/*function autoGenSecUpgrade(payload, loginReqest){
  return (dispatch, getState) => {
    var customerIds = payload.genSecSetupsRequired.slice(0)
    var sessionToken = payload.sessionToken
    //1. get task
    userService.getAllTask()
    .then(
      tasks => {

        var pool = new WorkerPool()

        pool.onmessage = (event) =>{
        }

        var promiseArray = []
        //check customerId matches dataId in task list
        customerIds.forEach(customerId => {
          var f = tasks.find(o => o.customerId === customerId && o.type === "AdminGenSecImpersonationsSetup")
          var c = payload.customers.find(o => o.id === customerId)
          var k = payload.keys[customerId]
          if(f === undefined || c === undefined || k === undefined) return
          if(payload.userType === undefined || payload.userType[customerId] === undefined) return
          var item = {
            ptype: "genSecSetup",
            url: GetURL(),
            sessionToken: sessionToken,
            customerId: customerId,
            task: f,
            myId: c.userId,
            username: payload.username,
            unregisteredUserType: payload.userType[customerId], //<- master, publish for current customer
            kUser: k.kUser,
          }
          promiseArray.push(
            new Promise(function(resolve, reject) {
              pool.queueJob(bgAdminTask,item)
              .then((result) => {
                resolve()
              })
              .catch((error) => {
                console.log(customerId, error)
                var d = JSON.parse(JSON.stringify(item))
                reject({msg: error, item: d, customerId: item.customerId})
              });
            })
          )
        })

        Promise.all(promiseArray)
        .then((dataChunks)=>{
        })
        .catch((error)=>{
          console.log("Failed autoGenSecUpgrade",error)
          dispatch(userActions.sendFeedBack({
            customerId: getState().authentication.customerId,
            feedbackTitle: "Error upload",
            feedBackType: 'Bug',
            feedback: JSON.stringify(error),
          }))
        })
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  }
}*/

function autoGenSecRegister(loginReqest){
  return (dispatch, getState) => {
    loginReqest.retrivePem = true
    if (loginReqest.mode === 2) loginReqest.passwordHash = true;
    //1. get task
    userService.login(loginReqest)
    .then(
      payload => {
        var customerIds = payload.genSecImpersonationsRequired.slice(0)
        var sessionToken = payload.sessionToken

        var pool = new WorkerPool()

        pool.onmessage = (event) =>{
    //console.log("!",event.data)

        }

        dispatch(success(payload))
        if(payload.customerIds.length > 1){
          dispatch(lastCustomer(payload, "autoGenSecRegister", false))
        }else{
          dispatch(loadCustomer(payload.customerIds[0]))
          trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "autoGenSecRegister"))
        }

        var promiseArray = []
        //check customerId matches dataId in task list
        customerIds.forEach(customerId => {
          var c = payload.customers.find(o => o.id === customerId)
          var k = payload.keys[customerId]
          if(c === undefined || k === undefined) return

          var item = {
            ptype: "genSecAssign",
            url: GetURL(),
            sessionToken: sessionToken,
            customerId: customerId,
            myId: c.userId,
            kUser: k.kUserPem,
            unregisteredUserType: payload.userType[customerId], //<- master, publish for current customer
          }
          promiseArray.push(
            new Promise(function(resolve, reject) {
              pool.queueJob(bgAdminTask,item)
              .then((result) => {
                resolve()
              })
              .catch((error) => {
                (customerId, error)
                var d = JSON.parse(JSON.stringify(item))
                d.kUser = "removed"
                reject({msg: error, item: d, customerId: item.customerId})
              });
            })
          )
        })

        Promise.all(promiseArray)
        .then((dataChunks)=>{
        })
        .catch((error)=>{
          log("Failed autoGenSecUpgrade",error)
          dispatch(userActions.sendFeedBack({
            customerId: getState().authentication.customerId,
            feedbackTitle: "Error upload",
            feedBackType: 'Bug',
            feedback: JSON.stringify(error),
          }))
        })
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  }

  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
}

/*function autoGenSecPopulate(payload, loginReqest){
  return (dispatch, getState) => {
    var customerIds = payload.genSecDataRequired.slice(0)
    var sessionToken = payload.sessionToken
    //1. get task
    userService.getAllTask()
    .then(
      tasks => {
        var pool = new WorkerPool()

        pool.onmessage = (event) =>{
          if(event.data.populate !== undefined){
            if(event.data.populate.type === "successUser")
              dispatch(successUsers(event.data.populate.data1, event.data.populate.data2))
            else if(event.data.populate.type === "successMembers")
              dispatch(successMembers(event.data.populate.data1, event.data.populate.data2))
            else if(event.data.populate.type === "successBoardFiles")
              dispatch(successBoardFiles(event.data.populate.data))
            else if(event.data.populate.type === "successFileDetails")
              dispatch(successFileDetails(event.data.populate.data))
            //else if(event.data.populate.type === "failureFile")
            //  dispatch(failureFile(event.data.populate.data))
            else if(event.data.populate.type === "completedTask")
              dispatch(completedTask(event.data.populate.data));
            else if(event.data.populate.type === "successBindersPreview")
              dispatch(successBindersPreview(event.data.populate.data));
            else if(event.data.populate.type === "successBinders")
              dispatch(successBinders(event.data.populate.data));
            else if(event.data.populate.type === "successPopTemplate")
              dispatch(successPopTemplate(event.data.populate.data));
            else if(event.data.populate.type === "failure"){
              var c = event.data.populate.data1
              c.error = event.data.populate.data2
              console.log("SEND",JSON.stringify(c))
              // dispatch(userActions.sendFeedBack({
              //   customerId: getState().authentication.customerId,
              //   feedbackTitle: "Error upload",
              //   feedBackType: 'Bug',
              //   feedback: JSON.stringify(event.data.populate.data2),
              // }))
            }
          }
        }

        var promiseArray = []
        //check customerId matches dataId in task list
        customerIds.forEach(customerId => {
          var f = tasks.find(o => o.customerId === customerId && o.type === "AdminGenSecDataSetup" && o.dateCompleted === undefined)
          var c = payload.customers.find(o => o.id === customerId)
          var k = payload.keys[customerId]

          if(f === undefined || c === undefined || k === undefined) return
          if(payload.userType === undefined || payload.userType[customerId] === undefined ||
            payload.userType[customerId] === 'Master' || payload.userType[customerId] === 'User') return

          if(f.metadata !== undefined){
            try{
              var node = JSON.parse(f.metadata);
              if(node.canActionUserIds !== undefined && !node.canActionUserIds.find(c.userId))
                return
            }catch(e){

            }
          }

          var item = {
            ptype: "genSecPop",
            url: GetURL(),
            sessionToken: sessionToken,
            customerId: customerId,
            task: f,
            taskIds: [f],
            myId: c.userId,
            kUser: k.kUser,
            kUserGenSec: k.kUserGenSec,
            //kUserSigned: k.kUserSigned,
            //pUserGenSec: k.pUserGenSec,
            meIds: payload.userIds,
            userType: payload.userType[customerId],
          }
          promiseArray.push(
            new Promise(function(resolve, reject) {
              pool.queueJob(bgAdminTask,item)
              .then((result) => {
                resolve()
              })
              .catch((error) => {
                console.log(customerId, error)
                var d = JSON.parse(JSON.stringify(item))
                reject({msg: error, item: d, customerId: item.customerId})
              });
            })
          )
        })

        Promise.all(promiseArray)
        .then((dataChunks)=>{
        })
        .catch((error)=>{
          console.log("Failed autoGenSecUpgrade",error)
          dispatch(userActions.sendFeedBack({
            customerId: getState().authentication.customerId,
            feedbackTitle: "Error upload",
            feedBackType: 'Bug',
            feedback: JSON.stringify(error),
          }))
        })
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  }

  function successUsers(items, customerId) { return { type: userConstants.POPULATEALL_USERS_SUCCESS, items, customerId } }
  function successMembers(boardId, membership) { return { type: boardConstants.GETMEMBERSHIP_SUCCESS, boardId, membership } }
  function successBoardFiles(items) { return { type: boardConstants.BOARD_FILELIST_SUCCESS, items } }
  function successFileDetails(filestat) { return { type: fileConstants.GET_FILESTATS_SUCCESS, filestat } }
  function successBinders(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function successBindersPreview(data) { return { type: boardConstants.POPULATE_BINDERS_SUCCESS, data } }
  function successPopTemplate(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }
}*/

function autoGenSecComplete(payload, loginReqest){
  return (dispatch, getState) => {
    var customerIds = payload.completedInvites.slice(0)

    /*var initial = [
      {
        userId: regitem.userId,
        genSecUserId: regitem.genSecUserId,
        decryptedData: regitem.kGenSecUser,
        userpemkey: regitem.kUserPublic,
      }
    ]

    userService.doInviteImpersonation(payload, initial)
      .then(
        payload2 => {
          userService.login(loginReqest)
            .then(
              logindetails => {
                dispatch(successLogin(logindetails));
                if(logindetails.userType[Object.keys(logindetails.userType)[0]] === "Master"){
                  dispatch(companyActions.getCompanyList())
                }
              },
              error => {
                dispatch(failure(error))
              }
            )
        },
        error => {
          dispatch(failure(error))
        }
      )*/
  }

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
}

/*function autoAdminKeySetup(payload, loginReqest){
  return (dispatch, getState) => {
    var customerIds = payload.athenaAdminKeySetupRequiredCustomerIds.slice(0)
    var sessionToken = payload.sessionToken
    //1. get task
    userService.getAllTask()
    .then(
      tasks => {
        var pool = new WorkerPool()

        pool.onmessage = (event) =>{

        }

        var promiseArray = []
        //check customerId matches dataId in task list
        customerIds.forEach(customerId => {
          var f = tasks.find(o => o.customerId === customerId && o.type === "AthenaAdminKeySetup" && o.dateCompleted === undefined)
          var c = payload.customers.find(o => o.id === customerId)
          var k = payload.keys[customerId]

          if(f === undefined || c === undefined || k === undefined) return
          if(payload.userType === undefined || payload.userType[customerId] === undefined || payload.userType[customerId] === 'User') return

          var item = {
            ptype: "adminKeySetup",
            url: GetURL(),
            sessionToken: sessionToken,
            customerId: customerId,
            task: f,
            taskIds: [f],
            myId: c.userId,
            kUser: k.kUser,
            kUserGenSec: k.kUserGenSec,
            //kUserSigned: k.kUserSigned,
            //pUserGenSec: k.pUserGenSec,
            meIds: payload.userIds,
            userType: payload.userType[customerId],
          }
          promiseArray.push(
            new Promise(function(resolve, reject) {
              pool.queueJob(bgAdminTask,item)
              .then((result) => {
                resolve()
              })
              .catch((error) => {
                console.log(customerId, error)
                var d = JSON.parse(JSON.stringify(item))
                reject({msg: error, item: d, customerId: item.customerId})
              });
            })
          )
        })

        Promise.all(promiseArray)
        .then((dataChunks)=>{
        })
        .catch((error)=>{
          console.log("Failed autoAdminKeySetup",error)
          try{
            var e = JSON.parse(error.msg)

            dispatch(alertActions.errorDiagnosticData(new CodeError("autoAdminKeySetup",e.code, '', {
                error: e,
                item: error.item,
            }, error.msg)))
          }catch(e){
            dispatch(userActions.sendFeedBack({
              customerId: getState().authentication.customerId,
              feedbackTitle: "Error upload",
              feedBackType: 'Bug',
              feedback: JSON.stringify(error),
            }))
          }
        })
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  }
}*/

/*function autoFixImpersonation(loginReqest){
  return (dispatch, getState) => {
    loginReqest.retrivePem = true
    //1. get task
    userService.login(loginReqest)
    .then(
      payload => {
        var customerIds = payload.customerIds.slice(0)
        var sessionToken = payload.sessionToken
        //1. get task
        userService.getAllTask()
        .then(
          tasks => {
            var pool = new WorkerPool()

            pool.onmessage = (event) =>{

            }

            dispatch(success(payload))
            if(payload.customerIds.length > 1){
              dispatch(lastCustomer(payload, "autoGenSecRegister", false))
            }else{
              dispatch(loadCustomer(payload.customerIds[0]))
              trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "autoGenSecRegister"))
            }

            var promiseArray = []
            //check customerId matches dataId in task list
            customerIds.forEach(customerId => {
              var f = tasks.find(o => o.customerId === customerId && o.type === "FixImpersonationByGenSec" && o.dateCompleted === undefined)
              var c = payload.customers.find(o => o.id === customerId)
              var k = payload.keys[customerId]

              if(f === undefined || c === undefined || k === undefined) return
              if(payload.userType === undefined || payload.userType[customerId] === undefined || payload.userType[customerId] === 'User') return

              var item = {
                ptype: "adminFixImpersonation",
                url: GetURL(),
                sessionToken: sessionToken,
                customerId: customerId,
                task: f,
                taskIds: [f],
                myId: c.userId,
                kUser: k.kUser,
                kUserGenSec: k.kUserGenSec,
                kUserPrivate: k.kUserPem,
                //kUserSigned: k.kUserSigned,
                //pUserGenSec: k.pUserGenSec,
                meIds: payload.userIds,
                userType: payload.userType[customerId],
              }
              promiseArray.push(
                new Promise(function(resolve, reject) {
                  pool.queueJob(bgAdminTask,item)
                  .then((result) => {
                    resolve()
                  })
                  .catch((error) => {
                    console.log(customerId, error)
                    var d = JSON.parse(JSON.stringify(item))
                    reject({msg: error, item: d, customerId: item.customerId})
                  });
                })
              )
            })

            Promise.all(promiseArray)
            .then((dataChunks)=>{
            })
            .catch((error)=>{
              console.log("Failed adminFixImpersonation",error)
              try{
                var e = JSON.parse(error.msg)

                dispatch(alertActions.errorDiagnosticData(new CodeError("adminFixImpersonation",e.code, '', {
                    error: e,
                    item: error.item,
                }, error.msg)))
              }catch(e){
                dispatch(userActions.sendFeedBack({
                  customerId: getState().authentication.customerId,
                  feedbackTitle: "Error upload",
                  feedBackType: 'Bug',
                  feedback: JSON.stringify(error),
                }))
              }
            })
          },
          error => {
          //  dispatch(failure(error))
          }
        );
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  }

  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
}*/

function autoFixTask(loginReqest, type){
  return (dispatch, getState) => {
    loginReqest.retrivePem = true
    loginReqest.passwordHash = true
    //1. get task
    userService.login(loginReqest)
    .then(
      payload => {
        var customerIds = payload.customerIds.slice(0)
        if(type === "AdminGenSecImpersonationsSetup") customerIds = payload.genSecSetupsRequired.slice(0)
        else if(type === "AdminGenSecDataSetup") customerIds = payload.genSecDataRequired.slice(0)
        else if(type === "AthenaAdminKeySetup") customerIds = payload.athenaAdminKeySetupRequiredCustomerIds.slice(0)
        else if(type === "FixLockPassImpersonation") customerIds = payload.fixLockPassImpersonationCustomerIds.slice(0)
        var sessionToken = payload.sessionToken
        //1. get task
        userService.getAllTask()
        .then(
          tasks => {
            var pool = new WorkerPool()

            pool.onmessage = (event) =>{
              if(event.data.populate !== undefined){
                if(event.data.populate.type === "successUser")
                  dispatch(successUsers(event.data.populate.data1, event.data.populate.data2))
                else if(event.data.populate.type === "successMembers")
                  dispatch(successMembers(event.data.populate.data1, event.data.populate.data2))
                else if(event.data.populate.type === "successBoardFiles")
                  dispatch(successBoardFiles(event.data.populate.data))
                else if(event.data.populate.type === "successFileDetails")
                  dispatch(successFileDetails(event.data.populate.data))
                //else if(event.data.populate.type === "failureFile")
                //  dispatch(failureFile(event.data.populate.data))
                else if(event.data.populate.type === "completedTask")
                  dispatch(completedTask(event.data.populate.data));
                else if(event.data.populate.type === "successBindersPreview")
                  dispatch(successBindersPreview(event.data.populate.data));
                else if(event.data.populate.type === "successBinders")
                  dispatch(successBinders(event.data.populate.data));
                else if(event.data.populate.type === "successPopTemplate")
                  dispatch(successPopTemplate(event.data.populate.data));
                else if(event.data.populate.type === "failure"){
                  var c = event.data.populate.data1
                  c.error = event.data.populate.data2
                  devlog("SEND",JSON.stringify(c))
                  // dispatch(userActions.sendFeedBack({
                  //   customerId: getState().authentication.customerId,
                  //   feedbackTitle: "Error upload",
                  //   feedBackType: 'Bug',
                  //   feedback: JSON.stringify(event.data.populate.data2),
                  // }))
                }
              }
            }

            dispatch(success(payload))
            if(payload.customerIds.length > 1){
              dispatch(lastCustomer(payload, type, false))
            }else{
              dispatch(loadCustomer(payload.customerIds[0]))
              trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], type))
            }

            var promiseArray = []
            //check customerId matches dataId in task list
            customerIds.forEach(customerId => {
              var f = tasks.find(o => o.customerId === customerId && o.type === type && o.dateCompleted === undefined)
              var c = payload.customers.find(o => o.id === customerId)
              var k = payload.keys[customerId]

              if(f === undefined || c === undefined || k === undefined) return
              if(payload.userType === undefined || payload.userType[customerId] === undefined || payload.userType[customerId] === 'User') return

              if(f.metadata !== undefined && type === "AdminGenSecDataSetup"){
                try{
                  var node = JSON.parse(f.metadata);
                  if(node.canActionUserIds !== undefined && !node.canActionUserIds.find(c.userId))
                    return
                }catch(e){

                }
              }

              var item = {
                ptype: type,
                url: GetURL(),
                sessionToken: sessionToken,
                customerId: customerId,
                task: f,
                taskIds: [f],
                tasks: tasks,
                myId: c.userId,
                kUser: k.kUser,
                kUserGenSec: k.kUserGenSec,
                kUserPrivate: k.kUserPem,
                //kUserSigned: k.kUserSigned,
                //pUserGenSec: k.pUserGenSec,
                meIds: payload.userIds,
                userType: payload.userType[customerId],
                username: loginReqest.username,
              }
              if(type === "AdminGenSecImpersonationsSetup"){
                item.unregisteredUserType = payload.userType[customerId] //<- master, publish for current customer
              }
              promiseArray.push(
                new Promise(function(resolve, reject) {
                  pool.queueJob(bgAdminTask,item)
                  .then((result) => {
                    resolve()
                  })
                  .catch((error) => {
                    (customerId, error)
                    var d = JSON.parse(JSON.stringify(item))
                    reject({msg: error, item: d, customerId: item.customerId})
                  });
                })
              )
            })

            Promise.all(promiseArray)
            .then((dataChunks)=>{
            })
            .catch((error)=>{
              log("autoFixTask",type,error)
              try{
                var e = JSON.parse(error.msg)

                dispatch(alertActions.errorDiagnosticData(new CodeError(type,e.code, '', {
                    error: e,
                    item: error.item,
                }, error.msg)))
              }catch(e){
                dispatch(userActions.sendFeedBack({
                  customerId: getState().authentication.customerId,
                  feedbackTitle: "Error upload",
                  feedBackType: 'Bug',
                  feedback: JSON.stringify(error),
                }))
              }
            })
          },
          error => {
          //  dispatch(failure(error))
          }
        );
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  }

  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function successUsers(items, customerId) { return { type: userConstants.POPULATEALL_USERS_SUCCESS, items, customerId } }
  function successMembers(boardId, membership) { return { type: boardConstants.GETMEMBERSHIP_SUCCESS, boardId, membership } }
  function successBoardFiles(items) { return { type: boardConstants.BOARD_FILELIST_SUCCESS, items } }
  function successFileDetails(filestat) { return { type: fileConstants.GET_FILESTATS_SUCCESS, filestat } }
  function successBinders(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function successBindersPreview(data) { return { type: boardConstants.POPULATE_BINDERS_SUCCESS, data } }
  function successPopTemplate(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }
}

function getMFACode(inviteIds){
  return dispatch => {
    userService.getMFACode(inviteIds)
    .then(
      mfaId => {
        dispatch(success(mfaId))
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  };

  function success(mfaId) { return { type: userConstants.AUTHCODE_CONFIRM_INVITE_SUCCESS, mfaId } }
}

function sendMFA(mfa, type){
  return dispatch => {
    userService.sendMFA(mfa, type)
    .then(
      code => {
      },
      error => {
      //  dispatch(failure(error))
      }
    );
  };
}

function sendResetLink(alias){
  return dispatch => {
    dispatch(request(alias));
    userService.sendResetLink(alias)
    .then(
      () => {
        dispatch(success(alias))
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(alias) { return { type: userConstants.AUTHZERO_RESET_REQUEST, alias } }
  function success(alias) { return { type: userConstants.AUTHZERO_RESET_SUCCESS, alias } }
  function failure(error) { return { type: userConstants.AUTHZERO_RESET_FAILURE, error } }
}

function hasLockPass(){
  return (dispatch, getState) => {
    var userId = getState().authentication.userId
    dispatch(request());
    userService.hasLockPass()
      .then(
        payload => {
          dispatch(success(userId, payload))
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() { return { type: userConstants.LOCAKPASS_HASACCOUNT_REQUEST } }
  function success(userId, payload) { return { type: userConstants.LOCAKPASS_HASACCOUNT_SUCCESS, userId, payload } }
  function failure(error) { return { type: userConstants.LOCAKPASS_HASACCOUNT_FAILURE, error } }
}

function pairLockPass(item){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(item.id, customerId));
    userService.login(item)
      .then(
        logindetails => {
          item.customerIds = logindetails.customerIds
          item.keys = logindetails.keys
          item.customers = logindetails.customers
          userService.pairLockPass(item)
            .then(
              payload => {
                dispatch(success(item.id, payload, customerId))
                dispatch(successLogin(logindetails));

                if(logindetails.customerIds.length > 1){
                  dispatch(lastCustomer(payload, "pairLockPass", false))
                }else{
                  dispatch(loadCustomer(payload.customerIds[0]))
                  trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "pairLockPass"))
                }
              },
              error => {
                dispatch(failure(item.id, error, customerId));
              }
            );
          },
        error => {
          dispatch(failure(item.id, error, customerId));
        }
      )
  };

  function successLogin(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }

  function request(id, customerId) { return { type: userConstants.LOCAKPASS_PAIRDEVICE_REQUEST, id, customerId } }
  function success(id, payload, customerId) { return { type: userConstants.LOCAKPASS_PAIRDEVICE_SUCCESS, id, payload, customerId } }
  function failure(id, error, customerId) { return { type: userConstants.LOCAKPASS_PAIRDEVICE_FAILURE, id, error, customerId } }
}

function getTermsAndCondition(){
  return dispatch => {
    dispatch(request());
    userService.getTermsAndCondition()
      .then(
        file => dispatch(success(file)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: userConstants.TERMS_CONDITION_REQUEST } }
  function success(data) { return { type: userConstants.TERMS_CONDITION_SUCCESS, data } }
  function failure(error) { return { type: userConstants.TERMS_CONDITION_FAILURE, error } }
}

function setKeys(keys){
  return dispatch => {
    dispatch(success(keys));
  };

  function success(keys) { return { type: userConstants.SET_KEYS, keys } }
}

function getUserAnalytics(userId){
  return dispatch => {
    dispatch(request(userId));
    userService.getUserAnalytics(userId)
    .then(
      payload=> {
        dispatch(success(userId, payload))
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(userId) { return { type: userConstants.USER_ANALYTICS_REQUEST, userId } }
  function success(userId, payload) { return { type: userConstants.USER_ANALYTICS_SUCCESS, userId, payload } }
  function failure(userId, error) { return { type: userConstants.USER_ANALYTICS_FAILURE, userId, error } }
}

function changeRoleType(userItem) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      userService.changeUserRole(userItem)
        .then(async response => {
          let state = getState();
          await Promise.all([
            await dispatch(populateUsers(userItem.customerId)),
            await dispatch(populateListofUsers(userItem.customerId)),
            await dispatch(companyActions.getCompanyUserLimits())
          ]);

          // state.company[key] for each add/remove from adminIds, adminCount, userCount, totalUserCount, totalAdminCount
          // state.users.customer[key] for each listAdminIds, listofUser
          // state.users.data[key] find user and change type
          // state.users.person[key] [{userId: ''}] for each and change type

          history.push(RoutesConstants.people);
          resolve();
        }, err => {
          reject();
        })
        .catch(() => {
          reject();
        })
    });
  }
}